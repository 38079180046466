var e,
  t = "undefined" != typeof window ? window : void 0,
  i = "undefined" != typeof globalThis ? globalThis : t,
  r = Array.prototype,
  s = r.forEach,
  n = r.indexOf,
  o = null == i ? void 0 : i.navigator,
  a = null == i ? void 0 : i.document,
  l = null == i ? void 0 : i.location,
  u = null == i ? void 0 : i.fetch,
  c = null != i && i.XMLHttpRequest && "withCredentials" in new i.XMLHttpRequest() ? i.XMLHttpRequest : void 0,
  d = null == i ? void 0 : i.AbortController,
  h = null == o ? void 0 : o.userAgent,
  _ = null != t ? t : {},
  p = {
    DEBUG: !1,
    LIB_VERSION: "1.232.6"
  },
  v = "$copy_autocapture",
  g = ["$snapshot", "$pageview", "$pageleave", "$set", "survey dismissed", "survey sent", "survey shown", "$identify", "$groupidentify", "$create_alias", "$$client_ingestion_warning", "$web_experiment_applied", "$feature_enrollment_update", "$feature_flag_called"];
!function (e) {
  e.GZipJS = "gzip-js", e.Base64 = "base64";
}(e || (e = {}));
var f = ["fatal", "error", "warning", "log", "info", "debug"];
function m(e, t) {
  return -1 !== e.indexOf(t);
}
var y = function (e) {
    return e.trim();
  },
  b = function (e) {
    return e.replace(/^\$/, "");
  };
var w = Array.isArray,
  S = Object.prototype,
  k = S.hasOwnProperty,
  E = S.toString,
  x = w || function (e) {
    return "[object Array]" === E.call(e);
  },
  I = e => "function" == typeof e,
  P = e => e === Object(e) && !x(e),
  C = e => {
    if (P(e)) {
      for (var t in e) if (k.call(e, t)) return !1;
      return !0;
    }
    return !1;
  },
  F = e => void 0 === e,
  T = e => "[object String]" == E.call(e),
  R = e => T(e) && 0 === e.trim().length,
  $ = e => null === e,
  O = e => F(e) || $(e),
  A = e => "[object Number]" == E.call(e),
  M = e => "[object Boolean]" === E.call(e),
  L = e => e instanceof FormData,
  D = e => e instanceof Error,
  q = e => m(g, e),
  N = e => {
    var i = {
      _log: function (i) {
        if (t && (p.DEBUG || _.POSTHOG_DEBUG) && !F(t.console) && t.console) {
          for (var r = ("__rrweb_original__" in t.console[i]) ? t.console[i].__rrweb_original__ : t.console[i], s = arguments.length, n = new Array(s > 1 ? s - 1 : 0), o = 1; o < s; o++) n[o - 1] = arguments[o];
          r(e, ...n);
        }
      },
      info: function () {
        for (var e = arguments.length, t = new Array(e), r = 0; r < e; r++) t[r] = arguments[r];
        i._log("log", ...t);
      },
      warn: function () {
        for (var e = arguments.length, t = new Array(e), r = 0; r < e; r++) t[r] = arguments[r];
        i._log("warn", ...t);
      },
      error: function () {
        for (var e = arguments.length, t = new Array(e), r = 0; r < e; r++) t[r] = arguments[r];
        i._log("error", ...t);
      },
      critical: function () {
        for (var t = arguments.length, i = new Array(t), r = 0; r < t; r++) i[r] = arguments[r];
        console.error(e, ...i);
      },
      uninitializedWarning: e => {
        i.error("You must initialize PostHog before calling ".concat(e));
      },
      createLogger: t => N("".concat(e, " ").concat(t))
    };
    return i;
  },
  B = N("[PostHog.js]"),
  H = B.createLogger,
  U = H("[ExternalScriptsLoader]"),
  z = (e, t, i) => {
    if (e.config.disable_external_dependency_loading) return U.warn("".concat(t, " was requested but loading of external scripts is disabled.")), i("Loading of external scripts is disabled");
    var r = null == a ? void 0 : a.querySelectorAll("script");
    if (r) for (var s = 0; s < r.length; s++) if (r[s].src === t) return i();
    var n = () => {
      if (!a) return i("document not found");
      var r = a.createElement("script");
      if (r.type = "text/javascript", r.crossOrigin = "anonymous", r.src = t, r.onload = e => i(void 0, e), r.onerror = e => i(e), e.config.prepare_external_dependency_script && (r = e.config.prepare_external_dependency_script(r)), !r) return i("prepare_external_dependency_script returned null");
      var s,
        n = a.querySelectorAll("body > script");
      n.length > 0 ? null === (s = n[0].parentNode) || void 0 === s || s.insertBefore(r, n[0]) : a.body.appendChild(r);
    };
    null != a && a.body ? n() : null == a || a.addEventListener("DOMContentLoaded", n);
  };
function j(e, t) {
  var i = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var r = Object.getOwnPropertySymbols(e);
    t && (r = r.filter(function (t) {
      return Object.getOwnPropertyDescriptor(e, t).enumerable;
    })), i.push.apply(i, r);
  }
  return i;
}
function W(e) {
  for (var t = 1; t < arguments.length; t++) {
    var i = null != arguments[t] ? arguments[t] : {};
    t % 2 ? j(Object(i), !0).forEach(function (t) {
      V(e, t, i[t]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(i)) : j(Object(i)).forEach(function (t) {
      Object.defineProperty(e, t, Object.getOwnPropertyDescriptor(i, t));
    });
  }
  return e;
}
function V(e, t, i) {
  return t in e ? Object.defineProperty(e, t, {
    value: i,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[t] = i, e;
}
function G(e, t) {
  if (null == e) return {};
  var i,
    r,
    s = function (e, t) {
      if (null == e) return {};
      var i,
        r,
        s = {},
        n = Object.keys(e);
      for (r = 0; r < n.length; r++) i = n[r], t.indexOf(i) >= 0 || (s[i] = e[i]);
      return s;
    }(e, t);
  if (Object.getOwnPropertySymbols) {
    var n = Object.getOwnPropertySymbols(e);
    for (r = 0; r < n.length; r++) i = n[r], t.indexOf(i) >= 0 || Object.prototype.propertyIsEnumerable.call(e, i) && (s[i] = e[i]);
  }
  return s;
}
_.__PosthogExtensions__ = _.__PosthogExtensions__ || {}, _.__PosthogExtensions__.loadExternalDependency = (e, t, i) => {
  var r = "/static/".concat(t, ".js") + "?v=".concat(e.version);
  if ("remote-config" === t && (r = "/array/".concat(e.config.token, "/config.js")), "toolbar" === t) {
    var s = 3e5,
      n = Math.floor(Date.now() / s) * s;
    r = "".concat(r, "&t=").concat(n);
  }
  var o = e.requestRouter.endpointFor("assets", r);
  z(e, o, i);
}, _.__PosthogExtensions__.loadSiteApp = (e, t, i) => {
  var r = e.requestRouter.endpointFor("api", t);
  z(e, r, i);
};
var J = {};
function Y(e, t, i) {
  if (x(e)) if (s && e.forEach === s) e.forEach(t, i);else if ("length" in e && e.length === +e.length) for (var r = 0, n = e.length; r < n; r++) if (r in e && t.call(i, e[r], r) === J) return;
}
function K(e, t, i) {
  if (!O(e)) {
    if (x(e)) return Y(e, t, i);
    if (L(e)) {
      for (var r of e.entries()) if (t.call(i, r[1], r[0]) === J) return;
    } else for (var s in e) if (k.call(e, s) && t.call(i, e[s], s) === J) return;
  }
}
var X = function (e) {
    for (var t = arguments.length, i = new Array(t > 1 ? t - 1 : 0), r = 1; r < t; r++) i[r - 1] = arguments[r];
    return Y(i, function (t) {
      for (var i in t) void 0 !== t[i] && (e[i] = t[i]);
    }), e;
  },
  Q = function (e) {
    for (var t = arguments.length, i = new Array(t > 1 ? t - 1 : 0), r = 1; r < t; r++) i[r - 1] = arguments[r];
    return Y(i, function (t) {
      Y(t, function (t) {
        e.push(t);
      });
    }), e;
  };
function Z(e) {
  for (var t = Object.keys(e), i = t.length, r = new Array(i); i--;) r[i] = [t[i], e[t[i]]];
  return r;
}
var ee = function (e) {
    try {
      return e();
    } catch (e) {
      return;
    }
  },
  te = function (e) {
    return function () {
      try {
        for (var t = arguments.length, i = new Array(t), r = 0; r < t; r++) i[r] = arguments[r];
        return e.apply(this, i);
      } catch (e) {
        B.critical("Implementation error. Please turn on debug mode and open a ticket on https://app.posthog.com/home#panel=support%3Asupport%3A."), B.critical(e);
      }
    };
  },
  ie = function (e) {
    var t = {};
    return K(e, function (e, i) {
      T(e) && e.length > 0 && (t[i] = e);
    }), t;
  };
function re(e, t) {
  return i = e, r = e => T(e) && !$(t) ? e.slice(0, t) : e, s = new Set(), function e(t, i) {
    return t !== Object(t) ? r ? r(t, i) : t : s.has(t) ? void 0 : (s.add(t), x(t) ? (n = [], Y(t, t => {
      n.push(e(t));
    })) : (n = {}, K(t, (t, i) => {
      s.has(t) || (n[i] = e(t, i));
    })), n);
    var n;
  }(i);
  var i, r, s;
}
var se = ["herokuapp.com", "vercel.app", "netlify.app"];
function ne(e) {
  var t = null == e ? void 0 : e.hostname;
  if (!T(t)) return !1;
  var i = t.split(".").slice(-2).join(".");
  for (var r of se) if (i === r) return !1;
  return !0;
}
function oe(e, t) {
  for (var i = 0; i < e.length; i++) if (t(e[i])) return e[i];
}
function ae(e, t, i, r) {
  var {
    capture: s = !1,
    passive: n = !0
  } = null != r ? r : {};
  null == e || e.addEventListener(t, i, {
    capture: s,
    passive: n
  });
}
var le = "$people_distinct_id",
  ue = "__alias",
  ce = "__timers",
  de = "$autocapture_disabled_server_side",
  he = "$heatmaps_enabled_server_side",
  _e = "$exception_capture_enabled_server_side",
  pe = "$web_vitals_enabled_server_side",
  ve = "$dead_clicks_enabled_server_side",
  ge = "$web_vitals_allowed_metrics",
  fe = "$session_recording_enabled_server_side",
  me = "$console_log_recording_enabled_server_side",
  ye = "$session_recording_network_payload_capture",
  be = "$session_recording_masking",
  we = "$session_recording_canvas_recording",
  Se = "$replay_sample_rate",
  ke = "$replay_minimum_duration",
  Ee = "$replay_script_config",
  xe = "$sesid",
  Ie = "$session_is_sampled",
  Pe = "$session_recording_url_trigger_activated_session",
  Ce = "$session_recording_event_trigger_activated_session",
  Fe = "$enabled_feature_flags",
  Te = "$early_access_features",
  Re = "$feature_flag_details",
  $e = "$stored_person_properties",
  Oe = "$stored_group_properties",
  Ae = "$surveys",
  Me = "$surveys_activated",
  Le = "$flag_call_reported",
  De = "$user_state",
  qe = "$client_session_props",
  Ne = "$capture_rate_limit",
  Be = "$initial_campaign_params",
  He = "$initial_referrer_info",
  Ue = "$initial_person_info",
  ze = "$epp",
  je = "__POSTHOG_TOOLBAR__",
  We = "$posthog_cookieless",
  Ve = [le, ue, "__cmpns", ce, fe, he, xe, Fe, De, Te, Re, Oe, $e, Ae, Le, qe, Ne, Be, He, ze];
function Ge(e) {
  var t;
  return e instanceof Element && (e.id === je || !(null === (t = e.closest) || void 0 === t || !t.call(e, ".toolbar-global-fade-container")));
}
function Je(e) {
  return !!e && 1 === e.nodeType;
}
function Ye(e, t) {
  return !!e && !!e.tagName && e.tagName.toLowerCase() === t.toLowerCase();
}
function Ke(e) {
  return !!e && 3 === e.nodeType;
}
function Xe(e) {
  return !!e && 11 === e.nodeType;
}
function Qe(e) {
  return e ? y(e).split(/\s+/) : [];
}
function Ze(e) {
  var i = null == t ? void 0 : t.location.href;
  return !!(i && e && e.some(e => i.match(e)));
}
function et(e) {
  var t = "";
  switch (typeof e.className) {
    case "string":
      t = e.className;
      break;
    case "object":
      t = (e.className && "baseVal" in e.className ? e.className.baseVal : null) || e.getAttribute("class") || "";
      break;
    default:
      t = "";
  }
  return Qe(t);
}
function tt(e) {
  return O(e) ? null : y(e).split(/(\s+)/).filter(e => vt(e)).join("").replace(/[\r\n]/g, " ").replace(/[ ]+/g, " ").substring(0, 255);
}
function it(e) {
  var t = "";
  return at(e) && !lt(e) && e.childNodes && e.childNodes.length && K(e.childNodes, function (e) {
    var i;
    Ke(e) && e.textContent && (t += null !== (i = tt(e.textContent)) && void 0 !== i ? i : "");
  }), y(t);
}
function rt(e) {
  return F(e.target) ? e.srcElement || null : null !== (t = e.target) && void 0 !== t && t.shadowRoot ? e.composedPath()[0] || null : e.target || null;
  var t;
}
var st = ["a", "button", "form", "input", "select", "textarea", "label"];
function nt(e) {
  var t = e.parentNode;
  return !(!t || !Je(t)) && t;
}
function ot(e, i) {
  var r = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : void 0,
    s = arguments.length > 3 ? arguments[3] : void 0,
    n = arguments.length > 4 ? arguments[4] : void 0;
  if (!t || !e || Ye(e, "html") || !Je(e)) return !1;
  if (null != r && r.url_allowlist && !Ze(r.url_allowlist)) return !1;
  if (null != r && r.url_ignorelist && Ze(r.url_ignorelist)) return !1;
  if (null != r && r.dom_event_allowlist) {
    var o = r.dom_event_allowlist;
    if (o && !o.some(e => i.type === e)) return !1;
  }
  for (var a = !1, l = [e], u = !0, c = e; c.parentNode && !Ye(c, "body");) if (Xe(c.parentNode)) l.push(c.parentNode.host), c = c.parentNode.host;else {
    if (!(u = nt(c))) break;
    if (s || st.indexOf(u.tagName.toLowerCase()) > -1) a = !0;else {
      var d = t.getComputedStyle(u);
      d && "pointer" === d.getPropertyValue("cursor") && (a = !0);
    }
    l.push(u), c = u;
  }
  if (!function (e, t) {
    var i = null == t ? void 0 : t.element_allowlist;
    if (F(i)) return !0;
    var r = function (e) {
      if (i.some(t => e.tagName.toLowerCase() === t)) return {
        v: !0
      };
    };
    for (var s of e) {
      var n = r(s);
      if ("object" == typeof n) return n.v;
    }
    return !1;
  }(l, r)) return !1;
  if (!function (e, t) {
    var i = null == t ? void 0 : t.css_selector_allowlist;
    if (F(i)) return !0;
    var r = function (e) {
      if (i.some(t => e.matches(t))) return {
        v: !0
      };
    };
    for (var s of e) {
      var n = r(s);
      if ("object" == typeof n) return n.v;
    }
    return !1;
  }(l, r)) return !1;
  var h = t.getComputedStyle(e);
  if (h && "pointer" === h.getPropertyValue("cursor") && "click" === i.type) return !0;
  var _ = e.tagName.toLowerCase();
  switch (_) {
    case "html":
      return !1;
    case "form":
      return (n || ["submit"]).indexOf(i.type) >= 0;
    case "input":
    case "select":
    case "textarea":
      return (n || ["change", "click"]).indexOf(i.type) >= 0;
    default:
      return a ? (n || ["click"]).indexOf(i.type) >= 0 : (n || ["click"]).indexOf(i.type) >= 0 && (st.indexOf(_) > -1 || "true" === e.getAttribute("contenteditable"));
  }
}
function at(e) {
  for (var t = e; t.parentNode && !Ye(t, "body"); t = t.parentNode) {
    var i = et(t);
    if (m(i, "ph-sensitive") || m(i, "ph-no-capture")) return !1;
  }
  if (m(et(e), "ph-include")) return !0;
  var r = e.type || "";
  if (T(r)) switch (r.toLowerCase()) {
    case "hidden":
    case "password":
      return !1;
  }
  var s = e.name || e.id || "";
  if (T(s)) {
    if (/^cc|cardnum|ccnum|creditcard|csc|cvc|cvv|exp|pass|pwd|routing|seccode|securitycode|securitynum|socialsec|socsec|ssn/i.test(s.replace(/[^a-zA-Z0-9]/g, ""))) return !1;
  }
  return !0;
}
function lt(e) {
  return !!(Ye(e, "input") && !["button", "checkbox", "submit", "reset"].includes(e.type) || Ye(e, "select") || Ye(e, "textarea") || "true" === e.getAttribute("contenteditable"));
}
var ut = "(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11})",
  ct = new RegExp("^(?:".concat(ut, ")$")),
  dt = new RegExp(ut),
  ht = "\\d{3}-?\\d{2}-?\\d{4}",
  _t = new RegExp("^(".concat(ht, ")$")),
  pt = new RegExp("(".concat(ht, ")"));
function vt(e) {
  var t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1];
  if (O(e)) return !1;
  if (T(e)) {
    if (e = y(e), (t ? ct : dt).test((e || "").replace(/[- ]/g, ""))) return !1;
    if ((t ? _t : pt).test(e)) return !1;
  }
  return !0;
}
function gt(e) {
  var t = it(e);
  return vt(t = "".concat(t, " ").concat(ft(e)).trim()) ? t : "";
}
function ft(e) {
  var t = "";
  return e && e.childNodes && e.childNodes.length && K(e.childNodes, function (e) {
    var i;
    if (e && "span" === (null === (i = e.tagName) || void 0 === i ? void 0 : i.toLowerCase())) try {
      var r = it(e);
      t = "".concat(t, " ").concat(r).trim(), e.childNodes && e.childNodes.length && (t = "".concat(t, " ").concat(ft(e)).trim());
    } catch (e) {
      B.error("[AutoCapture]", e);
    }
  }), t;
}
function mt(e) {
  return function (e) {
    var t = e.map(e => {
      var t,
        i,
        r = "";
      if (e.tag_name && (r += e.tag_name), e.attr_class) for (var s of (e.attr_class.sort(), e.attr_class)) r += ".".concat(s.replace(/"/g, ""));
      var n = W(W(W(W({}, e.text ? {
          text: e.text
        } : {}), {}, {
          "nth-child": null !== (t = e.nth_child) && void 0 !== t ? t : 0,
          "nth-of-type": null !== (i = e.nth_of_type) && void 0 !== i ? i : 0
        }, e.href ? {
          href: e.href
        } : {}), e.attr_id ? {
          attr_id: e.attr_id
        } : {}), e.attributes),
        o = {};
      return Z(n).sort((e, t) => {
        var [i] = e,
          [r] = t;
        return i.localeCompare(r);
      }).forEach(e => {
        var [t, i] = e;
        return o[yt(t.toString())] = yt(i.toString());
      }), r += ":", r += Z(n).map(e => {
        var [t, i] = e;
        return "".concat(t, '="').concat(i, '"');
      }).join("");
    });
    return t.join(";");
  }(function (e) {
    return e.map(e => {
      var t,
        i,
        r = {
          text: null === (t = e.$el_text) || void 0 === t ? void 0 : t.slice(0, 400),
          tag_name: e.tag_name,
          href: null === (i = e.attr__href) || void 0 === i ? void 0 : i.slice(0, 2048),
          attr_class: bt(e),
          attr_id: e.attr__id,
          nth_child: e.nth_child,
          nth_of_type: e.nth_of_type,
          attributes: {}
        };
      return Z(e).filter(e => {
        var [t] = e;
        return 0 === t.indexOf("attr__");
      }).forEach(e => {
        var [t, i] = e;
        return r.attributes[t] = i;
      }), r;
    });
  }(e));
}
function yt(e) {
  return e.replace(/"|\\"/g, '\\"');
}
function bt(e) {
  var t = e.attr__class;
  return t ? x(t) ? t : Qe(t) : void 0;
}
class wt {
  constructor() {
    this.clicks = [];
  }
  isRageClick(e, t, i) {
    var r = this.clicks[this.clicks.length - 1];
    if (r && Math.abs(e - r.x) + Math.abs(t - r.y) < 30 && i - r.timestamp < 1e3) {
      if (this.clicks.push({
        x: e,
        y: t,
        timestamp: i
      }), 3 === this.clicks.length) return !0;
    } else this.clicks = [{
      x: e,
      y: t,
      timestamp: i
    }];
    return !1;
  }
}
var St = ["localhost", "127.0.0.1"],
  kt = e => {
    var t = null == a ? void 0 : a.createElement("a");
    return F(t) ? null : (t.href = e, t);
  },
  Et = function (e) {
    var t,
      i,
      r = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "&",
      s = [];
    return K(e, function (e, r) {
      F(e) || F(r) || "undefined" === r || (t = encodeURIComponent((e => e instanceof File)(e) ? e.name : e.toString()), i = encodeURIComponent(r), s[s.length] = i + "=" + t);
    }), s.join(r);
  },
  xt = function (e, t) {
    for (var i, r = ((e.split("#")[0] || "").split(/\?(.*)/)[1] || "").replace(/^\?+/g, "").split("&"), s = 0; s < r.length; s++) {
      var n = r[s].split("=");
      if (n[0] === t) {
        i = n;
        break;
      }
    }
    if (!x(i) || i.length < 2) return "";
    var o = i[1];
    try {
      o = decodeURIComponent(o);
    } catch (e) {
      B.error("Skipping decoding for malformed query param: " + o);
    }
    return o.replace(/\+/g, " ");
  },
  It = function (e, t, i) {
    if (!e || !t || !t.length) return e;
    for (var r = e.split("#"), s = r[0] || "", n = r[1], o = s.split("?"), a = o[1], l = o[0], u = (a || "").split("&"), c = [], d = 0; d < u.length; d++) {
      var h = u[d].split("=");
      x(h) && (t.includes(h[0]) ? c.push(h[0] + "=" + i) : c.push(u[d]));
    }
    var _ = l;
    return null != a && (_ += "?" + c.join("&")), null != n && (_ += "#" + n), _;
  },
  Pt = function (e, t) {
    var i = e.match(new RegExp(t + "=([^&]*)"));
    return i ? i[1] : null;
  },
  Ct = H("[AutoCapture]");
function Ft(e, t) {
  return t.length > e ? t.slice(0, e) + "..." : t;
}
function Tt(e) {
  if (e.previousElementSibling) return e.previousElementSibling;
  var t = e;
  do {
    t = t.previousSibling;
  } while (t && !Je(t));
  return t;
}
function Rt(e, t, i, r) {
  var s = e.tagName.toLowerCase(),
    n = {
      tag_name: s
    };
  st.indexOf(s) > -1 && !i && ("a" === s.toLowerCase() || "button" === s.toLowerCase() ? n.$el_text = Ft(1024, gt(e)) : n.$el_text = Ft(1024, it(e)));
  var o = et(e);
  o.length > 0 && (n.classes = o.filter(function (e) {
    return "" !== e;
  })), K(e.attributes, function (i) {
    var s;
    if ((!lt(e) || -1 !== ["name", "id", "class", "aria-label"].indexOf(i.name)) && (null == r || !r.includes(i.name)) && !t && vt(i.value) && (s = i.name, !T(s) || "_ngcontent" !== s.substring(0, 10) && "_nghost" !== s.substring(0, 7))) {
      var o = i.value;
      "class" === i.name && (o = Qe(o).join(" ")), n["attr__" + i.name] = Ft(1024, o);
    }
  });
  for (var a = 1, l = 1, u = e; u = Tt(u);) a++, u.tagName === e.tagName && l++;
  return n.nth_child = a, n.nth_of_type = l, n;
}
function $t(e, i) {
  for (var r, s, {
      e: n,
      maskAllElementAttributes: o,
      maskAllText: a,
      elementAttributeIgnoreList: l,
      elementsChainAsString: u
    } = i, c = [e], d = e; d.parentNode && !Ye(d, "body");) Xe(d.parentNode) ? (c.push(d.parentNode.host), d = d.parentNode.host) : (c.push(d.parentNode), d = d.parentNode);
  var h,
    _ = [],
    p = {},
    v = !1,
    g = !1;
  if (K(c, e => {
    var t = at(e);
    "a" === e.tagName.toLowerCase() && (v = e.getAttribute("href"), v = t && v && vt(v) && v), m(et(e), "ph-no-capture") && (g = !0), _.push(Rt(e, o, a, l));
    var i = function (e) {
      if (!at(e)) return {};
      var t = {};
      return K(e.attributes, function (e) {
        if (e.name && 0 === e.name.indexOf("data-ph-capture-attribute")) {
          var i = e.name.replace("data-ph-capture-attribute-", ""),
            r = e.value;
          i && r && vt(r) && (t[i] = r);
        }
      }), t;
    }(e);
    X(p, i);
  }), g) return {
    props: {},
    explicitNoCapture: g
  };
  if (a || ("a" === e.tagName.toLowerCase() || "button" === e.tagName.toLowerCase() ? _[0].$el_text = gt(e) : _[0].$el_text = it(e)), v) {
    var f, y;
    _[0].attr__href = v;
    var b = null === (f = kt(v)) || void 0 === f ? void 0 : f.host,
      w = null == t || null === (y = t.location) || void 0 === y ? void 0 : y.host;
    b && w && b !== w && (h = v);
  }
  return {
    props: X({
      $event_type: n.type,
      $ce_version: 1
    }, u ? {} : {
      $elements: _
    }, {
      $elements_chain: mt(_)
    }, null !== (r = _[0]) && void 0 !== r && r.$el_text ? {
      $el_text: null === (s = _[0]) || void 0 === s ? void 0 : s.$el_text
    } : {}, h && "click" === n.type ? {
      $external_click_url: h
    } : {}, p)
  };
}
class Ot {
  constructor(e) {
    V(this, "_initialized", !1), V(this, "_isDisabledServerSide", null), V(this, "rageclicks", new wt()), V(this, "_elementsChainAsString", !1), this.instance = e, this._elementSelectors = null;
  }
  get config() {
    var e,
      t,
      i = P(this.instance.config.autocapture) ? this.instance.config.autocapture : {};
    return i.url_allowlist = null === (e = i.url_allowlist) || void 0 === e ? void 0 : e.map(e => new RegExp(e)), i.url_ignorelist = null === (t = i.url_ignorelist) || void 0 === t ? void 0 : t.map(e => new RegExp(e)), i;
  }
  _addDomEventHandlers() {
    if (this.isBrowserSupported()) {
      if (t && a) {
        var e = e => {
          e = e || (null == t ? void 0 : t.event);
          try {
            this._captureEvent(e);
          } catch (e) {
            Ct.error("Failed to capture event", e);
          }
        };
        if (ae(a, "submit", e, {
          capture: !0
        }), ae(a, "change", e, {
          capture: !0
        }), ae(a, "click", e, {
          capture: !0
        }), this.config.capture_copied_text) {
          var i = e => {
            e = e || (null == t ? void 0 : t.event), this._captureEvent(e, v);
          };
          ae(a, "copy", i, {
            capture: !0
          }), ae(a, "cut", i, {
            capture: !0
          });
        }
      }
    } else Ct.info("Disabling Automatic Event Collection because this browser is not supported");
  }
  startIfEnabled() {
    this.isEnabled && !this._initialized && (this._addDomEventHandlers(), this._initialized = !0);
  }
  onRemoteConfig(e) {
    e.elementsChainAsString && (this._elementsChainAsString = e.elementsChainAsString), this.instance.persistence && this.instance.persistence.register({
      [de]: !!e.autocapture_opt_out
    }), this._isDisabledServerSide = !!e.autocapture_opt_out, this.startIfEnabled();
  }
  setElementSelectors(e) {
    this._elementSelectors = e;
  }
  getElementSelectors(e) {
    var t,
      i = [];
    return null === (t = this._elementSelectors) || void 0 === t || t.forEach(t => {
      var r = null == a ? void 0 : a.querySelectorAll(t);
      null == r || r.forEach(r => {
        e === r && i.push(t);
      });
    }), i;
  }
  get isEnabled() {
    var e,
      t,
      i = null === (e = this.instance.persistence) || void 0 === e ? void 0 : e.props[de],
      r = this._isDisabledServerSide;
    if ($(r) && !M(i) && !this.instance.config.advanced_disable_decide) return !1;
    var s = null !== (t = this._isDisabledServerSide) && void 0 !== t ? t : !!i;
    return !!this.instance.config.autocapture && !s;
  }
  _captureEvent(e) {
    var i = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "$autocapture";
    if (this.isEnabled) {
      var r,
        s = rt(e);
      if (Ke(s) && (s = s.parentNode || null), "$autocapture" === i && "click" === e.type && e instanceof MouseEvent) this.instance.config.rageclick && null !== (r = this.rageclicks) && void 0 !== r && r.isRageClick(e.clientX, e.clientY, new Date().getTime()) && this._captureEvent(e, "$rageclick");
      var n = i === v;
      if (s && ot(s, e, this.config, n, n ? ["copy", "cut"] : void 0)) {
        var {
          props: o,
          explicitNoCapture: a
        } = $t(s, {
          e: e,
          maskAllElementAttributes: this.instance.config.mask_all_element_attributes,
          maskAllText: this.instance.config.mask_all_text,
          elementAttributeIgnoreList: this.config.element_attribute_ignorelist,
          elementsChainAsString: this._elementsChainAsString
        });
        if (a) return !1;
        var l = this.getElementSelectors(s);
        if (l && l.length > 0 && (o.$element_selectors = l), i === v) {
          var u,
            c = tt(null == t || null === (u = t.getSelection()) || void 0 === u ? void 0 : u.toString()),
            d = e.type || "clipboard";
          if (!c) return !1;
          o.$selected_content = c, o.$copy_type = d;
        }
        return this.instance.capture(i, o), !0;
      }
    }
  }
  isBrowserSupported() {
    return I(null == a ? void 0 : a.querySelectorAll);
  }
}
Math.trunc || (Math.trunc = function (e) {
  return e < 0 ? Math.ceil(e) : Math.floor(e);
}), Number.isInteger || (Number.isInteger = function (e) {
  return A(e) && isFinite(e) && Math.floor(e) === e;
});
var At = "0123456789abcdef";
class Mt {
  constructor(e) {
    if (this.bytes = e, 16 !== e.length) throw new TypeError("not 128-bit length");
  }
  static fromFieldsV7(e, t, i, r) {
    if (!Number.isInteger(e) || !Number.isInteger(t) || !Number.isInteger(i) || !Number.isInteger(r) || e < 0 || t < 0 || i < 0 || r < 0 || e > 0xffffffffffff || t > 4095 || i > 1073741823 || r > 4294967295) throw new RangeError("invalid field value");
    var s = new Uint8Array(16);
    return s[0] = e / Math.pow(2, 40), s[1] = e / Math.pow(2, 32), s[2] = e / Math.pow(2, 24), s[3] = e / Math.pow(2, 16), s[4] = e / Math.pow(2, 8), s[5] = e, s[6] = 112 | t >>> 8, s[7] = t, s[8] = 128 | i >>> 24, s[9] = i >>> 16, s[10] = i >>> 8, s[11] = i, s[12] = r >>> 24, s[13] = r >>> 16, s[14] = r >>> 8, s[15] = r, new Mt(s);
  }
  toString() {
    for (var e = "", t = 0; t < this.bytes.length; t++) e = e + At.charAt(this.bytes[t] >>> 4) + At.charAt(15 & this.bytes[t]), 3 !== t && 5 !== t && 7 !== t && 9 !== t || (e += "-");
    if (36 !== e.length) throw new Error("Invalid UUIDv7 was generated");
    return e;
  }
  clone() {
    return new Mt(this.bytes.slice(0));
  }
  equals(e) {
    return 0 === this.compareTo(e);
  }
  compareTo(e) {
    for (var t = 0; t < 16; t++) {
      var i = this.bytes[t] - e.bytes[t];
      if (0 !== i) return Math.sign(i);
    }
    return 0;
  }
}
class Lt {
  constructor() {
    V(this, "timestamp", 0), V(this, "counter", 0), V(this, "random", new Nt());
  }
  generate() {
    var e = this.generateOrAbort();
    if (F(e)) {
      this.timestamp = 0;
      var t = this.generateOrAbort();
      if (F(t)) throw new Error("Could not generate UUID after timestamp reset");
      return t;
    }
    return e;
  }
  generateOrAbort() {
    var e = Date.now();
    if (e > this.timestamp) this.timestamp = e, this.resetCounter();else {
      if (!(e + 1e4 > this.timestamp)) return;
      this.counter++, this.counter > 4398046511103 && (this.timestamp++, this.resetCounter());
    }
    return Mt.fromFieldsV7(this.timestamp, Math.trunc(this.counter / Math.pow(2, 30)), this.counter & Math.pow(2, 30) - 1, this.random.nextUint32());
  }
  resetCounter() {
    this.counter = 1024 * this.random.nextUint32() + (1023 & this.random.nextUint32());
  }
}
var Dt,
  qt = e => {
    if ("undefined" != typeof UUIDV7_DENY_WEAK_RNG && UUIDV7_DENY_WEAK_RNG) throw new Error("no cryptographically strong RNG available");
    for (var t = 0; t < e.length; t++) e[t] = 65536 * Math.trunc(65536 * Math.random()) + Math.trunc(65536 * Math.random());
    return e;
  };
t && !F(t.crypto) && crypto.getRandomValues && (qt = e => crypto.getRandomValues(e));
class Nt {
  constructor() {
    V(this, "buffer", new Uint32Array(8)), V(this, "cursor", 1 / 0);
  }
  nextUint32() {
    return this.cursor >= this.buffer.length && (qt(this.buffer), this.cursor = 0), this.buffer[this.cursor++];
  }
}
var Bt = () => Ht().toString(),
  Ht = () => (Dt || (Dt = new Lt())).generate(),
  Ut = "Thu, 01 Jan 1970 00:00:00 GMT",
  zt = "";
var jt = /[a-z0-9][a-z0-9-]+\.[a-z]{2,}$/i;
function Wt(e, t) {
  if (t) {
    var i = function (e) {
      var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : a;
      if (zt) return zt;
      if (!t) return "";
      if (["localhost", "127.0.0.1"].includes(e)) return "";
      for (var i = e.split("."), r = Math.min(i.length, 8), s = "dmn_chk_" + Bt(), n = new RegExp("(^|;)\\s*" + s + "=1"); !zt && r--;) {
        var o = i.slice(r).join("."),
          l = s + "=1;domain=." + o;
        t.cookie = l, n.test(t.cookie) && (t.cookie = l + ";expires=" + Ut, zt = o);
      }
      return zt;
    }(e);
    if (!i) {
      var r = (e => {
        var t = e.match(jt);
        return t ? t[0] : "";
      })(e);
      r !== i && B.info("Warning: cookie subdomain discovery mismatch", r, i), i = r;
    }
    return i ? "; domain=." + i : "";
  }
  return "";
}
var Vt,
  Gt = {
    is_supported: () => !!a,
    error: function (e) {
      B.error("cookieStore error: " + e);
    },
    get: function (e) {
      if (a) {
        try {
          for (var t = e + "=", i = a.cookie.split(";").filter(e => e.length), r = 0; r < i.length; r++) {
            for (var s = i[r]; " " == s.charAt(0);) s = s.substring(1, s.length);
            if (0 === s.indexOf(t)) return decodeURIComponent(s.substring(t.length, s.length));
          }
        } catch (e) {}
        return null;
      }
    },
    parse: function (e) {
      var t;
      try {
        t = JSON.parse(Gt.get(e)) || {};
      } catch (e) {}
      return t;
    },
    set: function (e, t, i, r, s) {
      if (a) try {
        var n = "",
          o = "",
          l = Wt(a.location.hostname, r);
        if (i) {
          var u = new Date();
          u.setTime(u.getTime() + 24 * i * 60 * 60 * 1e3), n = "; expires=" + u.toUTCString();
        }
        s && (o = "; secure");
        var c = e + "=" + encodeURIComponent(JSON.stringify(t)) + n + "; SameSite=Lax; path=/" + l + o;
        return c.length > 3686.4 && B.warn("cookieStore warning: large cookie, len=" + c.length), a.cookie = c, c;
      } catch (e) {
        return;
      }
    },
    remove: function (e, t) {
      try {
        Gt.set(e, "", -1, t);
      } catch (e) {
        return;
      }
    }
  },
  Jt = null,
  Yt = {
    is_supported: function () {
      if (!$(Jt)) return Jt;
      var e = !0;
      if (F(t)) e = !1;else try {
        var i = "__mplssupport__";
        Yt.set(i, "xyz"), '"xyz"' !== Yt.get(i) && (e = !1), Yt.remove(i);
      } catch (t) {
        e = !1;
      }
      return e || B.error("localStorage unsupported; falling back to cookie store"), Jt = e, e;
    },
    error: function (e) {
      B.error("localStorage error: " + e);
    },
    get: function (e) {
      try {
        return null == t ? void 0 : t.localStorage.getItem(e);
      } catch (e) {
        Yt.error(e);
      }
      return null;
    },
    parse: function (e) {
      try {
        return JSON.parse(Yt.get(e)) || {};
      } catch (e) {}
      return null;
    },
    set: function (e, i) {
      try {
        null == t || t.localStorage.setItem(e, JSON.stringify(i));
      } catch (e) {
        Yt.error(e);
      }
    },
    remove: function (e) {
      try {
        null == t || t.localStorage.removeItem(e);
      } catch (e) {
        Yt.error(e);
      }
    }
  },
  Kt = ["distinct_id", xe, Ie, ze, Ue],
  Xt = W(W({}, Yt), {}, {
    parse: function (e) {
      try {
        var t = {};
        try {
          t = Gt.parse(e) || {};
        } catch (e) {}
        var i = X(t, JSON.parse(Yt.get(e) || "{}"));
        return Yt.set(e, i), i;
      } catch (e) {}
      return null;
    },
    set: function (e, t, i, r, s, n) {
      try {
        Yt.set(e, t, void 0, void 0, n);
        var o = {};
        Kt.forEach(e => {
          t[e] && (o[e] = t[e]);
        }), Object.keys(o).length && Gt.set(e, o, i, r, s, n);
      } catch (e) {
        Yt.error(e);
      }
    },
    remove: function (e, i) {
      try {
        null == t || t.localStorage.removeItem(e), Gt.remove(e, i);
      } catch (e) {
        Yt.error(e);
      }
    }
  }),
  Qt = {},
  Zt = {
    is_supported: function () {
      return !0;
    },
    error: function (e) {
      B.error("memoryStorage error: " + e);
    },
    get: function (e) {
      return Qt[e] || null;
    },
    parse: function (e) {
      return Qt[e] || null;
    },
    set: function (e, t) {
      Qt[e] = t;
    },
    remove: function (e) {
      delete Qt[e];
    }
  },
  ei = null,
  ti = {
    is_supported: function () {
      if (!$(ei)) return ei;
      if (ei = !0, F(t)) ei = !1;else try {
        var e = "__support__";
        ti.set(e, "xyz"), '"xyz"' !== ti.get(e) && (ei = !1), ti.remove(e);
      } catch (e) {
        ei = !1;
      }
      return ei;
    },
    error: function (e) {
      B.error("sessionStorage error: ", e);
    },
    get: function (e) {
      try {
        return null == t ? void 0 : t.sessionStorage.getItem(e);
      } catch (e) {
        ti.error(e);
      }
      return null;
    },
    parse: function (e) {
      try {
        return JSON.parse(ti.get(e)) || null;
      } catch (e) {}
      return null;
    },
    set: function (e, i) {
      try {
        null == t || t.sessionStorage.setItem(e, JSON.stringify(i));
      } catch (e) {
        ti.error(e);
      }
    },
    remove: function (e) {
      try {
        null == t || t.sessionStorage.removeItem(e);
      } catch (e) {
        ti.error(e);
      }
    }
  };
!function (e) {
  e[e.PENDING = -1] = "PENDING", e[e.DENIED = 0] = "DENIED", e[e.GRANTED = 1] = "GRANTED";
}(Vt || (Vt = {}));
class ii {
  constructor(e) {
    this.instance = e;
  }
  get config() {
    return this.instance.config;
  }
  get consent() {
    return this.getDnt() ? Vt.DENIED : this.storedConsent;
  }
  isOptedOut() {
    return this.consent === Vt.DENIED || this.consent === Vt.PENDING && this.config.opt_out_capturing_by_default;
  }
  isOptedIn() {
    return !this.isOptedOut();
  }
  optInOut(e) {
    this.storage.set(this.storageKey, e ? 1 : 0, this.config.cookie_expiration, this.config.cross_subdomain_cookie, this.config.secure_cookie);
  }
  reset() {
    this.storage.remove(this.storageKey, this.config.cross_subdomain_cookie);
  }
  get storageKey() {
    var {
      token: e,
      opt_out_capturing_cookie_prefix: t
    } = this.instance.config;
    return (t || "__ph_opt_in_out_") + e;
  }
  get storedConsent() {
    var e = this.storage.get(this.storageKey);
    return "1" === e ? Vt.GRANTED : "0" === e ? Vt.DENIED : Vt.PENDING;
  }
  get storage() {
    if (!this._storage) {
      var e = this.config.opt_out_capturing_persistence_type;
      this._storage = "localStorage" === e ? Yt : Gt;
      var t = "localStorage" === e ? Gt : Yt;
      t.get(this.storageKey) && (this._storage.get(this.storageKey) || this.optInOut("1" === t.get(this.storageKey)), t.remove(this.storageKey, this.config.cross_subdomain_cookie));
    }
    return this._storage;
  }
  getDnt() {
    return !!this.config.respect_dnt && !!oe([null == o ? void 0 : o.doNotTrack, null == o ? void 0 : o.msDoNotTrack, _.doNotTrack], e => m([!0, 1, "1", "yes"], e));
  }
}
var ri = H("[Dead Clicks]"),
  si = () => !0,
  ni = e => {
    var t,
      i = !(null === (t = e.instance.persistence) || void 0 === t || !t.get_property(ve)),
      r = e.instance.config.capture_dead_clicks;
    return M(r) ? r : i;
  };
class oi {
  get lazyLoadedDeadClicksAutocapture() {
    return this._lazyLoadedDeadClicksAutocapture;
  }
  constructor(e, t, i) {
    this.instance = e, this.isEnabled = t, this.onCapture = i, this.startIfEnabled();
  }
  onRemoteConfig(e) {
    this.instance.persistence && this.instance.persistence.register({
      [ve]: null == e ? void 0 : e.captureDeadClicks
    }), this.startIfEnabled();
  }
  startIfEnabled() {
    this.isEnabled(this) && this.loadScript(() => {
      this.start();
    });
  }
  loadScript(e) {
    var t, i, r;
    null !== (t = _.__PosthogExtensions__) && void 0 !== t && t.initDeadClicksAutocapture && e(), null === (i = _.__PosthogExtensions__) || void 0 === i || null === (r = i.loadExternalDependency) || void 0 === r || r.call(i, this.instance, "dead-clicks-autocapture", t => {
      t ? ri.error("failed to load script", t) : e();
    });
  }
  start() {
    var e;
    if (a) {
      if (!this._lazyLoadedDeadClicksAutocapture && null !== (e = _.__PosthogExtensions__) && void 0 !== e && e.initDeadClicksAutocapture) {
        var t = P(this.instance.config.capture_dead_clicks) ? this.instance.config.capture_dead_clicks : {};
        t.__onCapture = this.onCapture, this._lazyLoadedDeadClicksAutocapture = _.__PosthogExtensions__.initDeadClicksAutocapture(this.instance, t), this._lazyLoadedDeadClicksAutocapture.start(a), ri.info("starting...");
      }
    } else ri.error("`document` not found. Cannot start.");
  }
  stop() {
    this._lazyLoadedDeadClicksAutocapture && (this._lazyLoadedDeadClicksAutocapture.stop(), this._lazyLoadedDeadClicksAutocapture = void 0, ri.info("stopping..."));
  }
}
var ai = H("[ExceptionAutocapture]");
class li {
  constructor(e) {
    var i;
    V(this, "startCapturing", () => {
      var e, i, r, s;
      if (t && this.isEnabled && !this.hasHandlers) {
        var n = null === (e = _.__PosthogExtensions__) || void 0 === e || null === (i = e.errorWrappingFunctions) || void 0 === i ? void 0 : i.wrapOnError,
          o = null === (r = _.__PosthogExtensions__) || void 0 === r || null === (s = r.errorWrappingFunctions) || void 0 === s ? void 0 : s.wrapUnhandledRejection;
        if (n && o) try {
          this.unwrapOnError = n(this.captureException.bind(this)), this.unwrapUnhandledRejection = o(this.captureException.bind(this));
        } catch (e) {
          ai.error("failed to start", e), this.stopCapturing();
        } else ai.error("failed to load error wrapping functions - cannot start");
      }
    }), this.instance = e, this.remoteEnabled = !(null === (i = this.instance.persistence) || void 0 === i || !i.props[_e]), this.startIfEnabled();
  }
  get isEnabled() {
    var e;
    return M(this.instance.config.capture_exceptions) ? this.instance.config.capture_exceptions : null !== (e = this.remoteEnabled) && void 0 !== e && e;
  }
  get hasHandlers() {
    return !F(this.unwrapOnError);
  }
  startIfEnabled() {
    this.isEnabled && !this.hasHandlers && (ai.info("enabled, starting..."), this.loadScript(this.startCapturing));
  }
  loadScript(e) {
    var t, i;
    this.hasHandlers && e(), null === (t = _.__PosthogExtensions__) || void 0 === t || null === (i = t.loadExternalDependency) || void 0 === i || i.call(t, this.instance, "exception-autocapture", t => {
      if (t) return ai.error("failed to load script", t);
      e();
    });
  }
  stopCapturing() {
    var e, t;
    null === (e = this.unwrapOnError) || void 0 === e || e.call(this), this.unwrapOnError = void 0, null === (t = this.unwrapUnhandledRejection) || void 0 === t || t.call(this), this.unwrapUnhandledRejection = void 0;
  }
  onRemoteConfig(e) {
    var t = e.autocaptureExceptions;
    this.remoteEnabled = !!t || !1, this.instance.persistence && this.instance.persistence.register({
      [_e]: this.remoteEnabled
    }), this.startIfEnabled();
  }
  captureException(e) {
    var t = this.instance.requestRouter.endpointFor("ui");
    e.$exception_personURL = "".concat(t, "/project/").concat(this.instance.config.token, "/person/").concat(this.instance.get_distinct_id()), this.instance.exceptions.sendExceptionEvent(e);
  }
}
function ui(e) {
  var t, i;
  return (null === (t = JSON.stringify(e, (i = [], function (e, t) {
    if (P(t)) {
      for (; i.length > 0 && i[i.length - 1] !== this;) i.pop();
      return i.includes(t) ? "[Circular]" : (i.push(t), t);
    }
    return t;
  }))) || void 0 === t ? void 0 : t.length) || 0;
}
function ci(e) {
  var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 6606028.8;
  if (e.size >= t && e.data.length > 1) {
    var i = Math.floor(e.data.length / 2),
      r = e.data.slice(0, i),
      s = e.data.slice(i);
    return [ci({
      size: ui(r),
      data: r,
      sessionId: e.sessionId,
      windowId: e.windowId
    }), ci({
      size: ui(s),
      data: s,
      sessionId: e.sessionId,
      windowId: e.windowId
    })].flatMap(e => e);
  }
  return [e];
}
var di = (e => (e[e.DomContentLoaded = 0] = "DomContentLoaded", e[e.Load = 1] = "Load", e[e.FullSnapshot = 2] = "FullSnapshot", e[e.IncrementalSnapshot = 3] = "IncrementalSnapshot", e[e.Meta = 4] = "Meta", e[e.Custom = 5] = "Custom", e[e.Plugin = 6] = "Plugin", e))(di || {}),
  hi = (e => (e[e.Mutation = 0] = "Mutation", e[e.MouseMove = 1] = "MouseMove", e[e.MouseInteraction = 2] = "MouseInteraction", e[e.Scroll = 3] = "Scroll", e[e.ViewportResize = 4] = "ViewportResize", e[e.Input = 5] = "Input", e[e.TouchMove = 6] = "TouchMove", e[e.MediaInteraction = 7] = "MediaInteraction", e[e.StyleSheetRule = 8] = "StyleSheetRule", e[e.CanvasMutation = 9] = "CanvasMutation", e[e.Font = 10] = "Font", e[e.Log = 11] = "Log", e[e.Drag = 12] = "Drag", e[e.StyleDeclaration = 13] = "StyleDeclaration", e[e.Selection = 14] = "Selection", e[e.AdoptedStyleSheet = 15] = "AdoptedStyleSheet", e[e.CustomElement = 16] = "CustomElement", e))(hi || {}),
  _i = "[SessionRecording]",
  pi = "redacted",
  vi = {
    initiatorTypes: ["audio", "beacon", "body", "css", "early-hint", "embed", "fetch", "frame", "iframe", "icon", "image", "img", "input", "link", "navigation", "object", "ping", "script", "track", "video", "xmlhttprequest"],
    maskRequestFn: e => e,
    recordHeaders: !1,
    recordBody: !1,
    recordInitialRequests: !1,
    recordPerformance: !1,
    performanceEntryTypeToObserve: ["first-input", "navigation", "paint", "resource"],
    payloadSizeLimitBytes: 1e6,
    payloadHostDenyList: [".lr-ingest.io", ".ingest.sentry.io", ".clarity.ms", "analytics.google.com"]
  },
  gi = ["authorization", "x-forwarded-for", "authorization", "cookie", "set-cookie", "x-api-key", "x-real-ip", "remote-addr", "forwarded", "proxy-authorization", "x-csrf-token", "x-csrftoken", "x-xsrf-token"],
  fi = ["password", "secret", "passwd", "api_key", "apikey", "auth", "credentials", "mysql_pwd", "privatekey", "private_key", "token"],
  mi = ["/s/", "/e/", "/i/"];
function yi(e, t, i, r) {
  if (O(e)) return e;
  var s = (null == t ? void 0 : t["content-length"]) || function (e) {
    return new Blob([e]).size;
  }(e);
  return T(s) && (s = parseInt(s)), s > i ? _i + " ".concat(r, " body too large to record (").concat(s, " bytes)") : e;
}
function bi(e, t) {
  if (O(e)) return e;
  var i = e;
  return vt(i, !1) || (i = _i + " " + t + " body " + pi), K(fi, e => {
    var r, s;
    null !== (r = i) && void 0 !== r && r.length && -1 !== (null === (s = i) || void 0 === s ? void 0 : s.indexOf(e)) && (i = _i + " " + t + " body " + pi + " as might contain: " + e);
  }), i;
}
var wi = (e, t) => {
  var i,
    r,
    s,
    n = {
      payloadSizeLimitBytes: vi.payloadSizeLimitBytes,
      performanceEntryTypeToObserve: [...vi.performanceEntryTypeToObserve],
      payloadHostDenyList: [...(t.payloadHostDenyList || []), ...vi.payloadHostDenyList]
    },
    o = !1 !== e.session_recording.recordHeaders && t.recordHeaders,
    a = !1 !== e.session_recording.recordBody && t.recordBody,
    l = !1 !== e.capture_performance && t.recordPerformance,
    u = (i = n, s = Math.min(1e6, null !== (r = i.payloadSizeLimitBytes) && void 0 !== r ? r : 1e6), e => (null != e && e.requestBody && (e.requestBody = yi(e.requestBody, e.requestHeaders, s, "Request")), null != e && e.responseBody && (e.responseBody = yi(e.responseBody, e.responseHeaders, s, "Response")), e)),
    c = t => {
      return u(((e, t) => {
        var i,
          r = kt(e.name),
          s = 0 === t.indexOf("http") ? null === (i = kt(t)) || void 0 === i ? void 0 : i.pathname : t;
        "/" === s && (s = "");
        var n = null == r ? void 0 : r.pathname.replace(s || "", "");
        if (!(r && n && mi.some(e => 0 === n.indexOf(e)))) return e;
      })((r = (i = t).requestHeaders, O(r) || K(Object.keys(null != r ? r : {}), e => {
        gi.includes(e.toLowerCase()) && (r[e] = pi);
      }), i), e.api_host));
      var i, r;
    },
    d = I(e.session_recording.maskNetworkRequestFn);
  return d && I(e.session_recording.maskCapturedNetworkRequestFn) && B.warn("Both `maskNetworkRequestFn` and `maskCapturedNetworkRequestFn` are defined. `maskNetworkRequestFn` will be ignored."), d && (e.session_recording.maskCapturedNetworkRequestFn = t => {
    var i = e.session_recording.maskNetworkRequestFn({
      url: t.name
    });
    return W(W({}, t), {}, {
      name: null == i ? void 0 : i.url
    });
  }), n.maskRequestFn = I(e.session_recording.maskCapturedNetworkRequestFn) ? t => {
    var i,
      r,
      s,
      n = c(t);
    return n && null !== (i = null === (r = (s = e.session_recording).maskCapturedNetworkRequestFn) || void 0 === r ? void 0 : r.call(s, n)) && void 0 !== i ? i : void 0;
  } : e => function (e) {
    if (!F(e)) return e.requestBody = bi(e.requestBody, "Request"), e.responseBody = bi(e.responseBody, "Response"), e;
  }(c(e)), W(W(W({}, vi), n), {}, {
    recordHeaders: o,
    recordBody: a,
    recordPerformance: l,
    recordInitialRequests: l
  });
};
function Si(e, t, i, r, s) {
  return t > i && (B.warn("min cannot be greater than max."), t = i), A(e) ? e > i ? (r && B.warn(r + " cannot be  greater than max: " + i + ". Using max value instead."), i) : e < t ? (r && B.warn(r + " cannot be less than min: " + t + ". Using min value instead."), t) : e : (r && B.warn(r + " must be a number. using max or fallback. max: " + i + ", fallback: " + s), Si(s || i, t, i, r));
}
class ki {
  constructor(e) {
    var t,
      i,
      r = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    V(this, "bucketSize", 100), V(this, "refillRate", 10), V(this, "mutationBuckets", {}), V(this, "loggedTracker", {}), V(this, "refillBuckets", () => {
      Object.keys(this.mutationBuckets).forEach(e => {
        this.mutationBuckets[e] = this.mutationBuckets[e] + this.refillRate, this.mutationBuckets[e] >= this.bucketSize && delete this.mutationBuckets[e];
      });
    }), V(this, "getNodeOrRelevantParent", e => {
      var t = this.rrweb.mirror.getNode(e);
      if ("svg" !== (null == t ? void 0 : t.nodeName) && t instanceof Element) {
        var i = t.closest("svg");
        if (i) return [this.rrweb.mirror.getId(i), i];
      }
      return [e, t];
    }), V(this, "numberOfChanges", e => {
      var t, i, r, s, n, o, a, l;
      return (null !== (t = null === (i = e.removes) || void 0 === i ? void 0 : i.length) && void 0 !== t ? t : 0) + (null !== (r = null === (s = e.attributes) || void 0 === s ? void 0 : s.length) && void 0 !== r ? r : 0) + (null !== (n = null === (o = e.texts) || void 0 === o ? void 0 : o.length) && void 0 !== n ? n : 0) + (null !== (a = null === (l = e.adds) || void 0 === l ? void 0 : l.length) && void 0 !== a ? a : 0);
    }), V(this, "throttleMutations", e => {
      if (3 !== e.type || 0 !== e.data.source) return e;
      var t = e.data,
        i = this.numberOfChanges(t);
      t.attributes && (t.attributes = t.attributes.filter(e => {
        var t,
          i,
          r,
          [s, n] = this.getNodeOrRelevantParent(e.id);
        if (0 === this.mutationBuckets[s]) return !1;
        (this.mutationBuckets[s] = null !== (t = this.mutationBuckets[s]) && void 0 !== t ? t : this.bucketSize, this.mutationBuckets[s] = Math.max(this.mutationBuckets[s] - 1, 0), 0 === this.mutationBuckets[s]) && (this.loggedTracker[s] || (this.loggedTracker[s] = !0, null === (i = (r = this.options).onBlockedNode) || void 0 === i || i.call(r, s, n)));
        return e;
      }));
      var r = this.numberOfChanges(t);
      return 0 !== r || i === r ? e : void 0;
    }), this.rrweb = e, this.options = r, this.refillRate = Si(null !== (t = this.options.refillRate) && void 0 !== t ? t : this.refillRate, 0, 100, "mutation throttling refill rate"), this.bucketSize = Si(null !== (i = this.options.bucketSize) && void 0 !== i ? i : this.bucketSize, 0, 100, "mutation throttling bucket size"), setInterval(() => {
      this.refillBuckets();
    }, 1e3);
  }
}
var Ei = Uint8Array,
  xi = Uint16Array,
  Ii = Uint32Array,
  Pi = new Ei([0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 2, 2, 2, 2, 3, 3, 3, 3, 4, 4, 4, 4, 5, 5, 5, 5, 0, 0, 0, 0]),
  Ci = new Ei([0, 0, 0, 0, 1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 6, 7, 7, 8, 8, 9, 9, 10, 10, 11, 11, 12, 12, 13, 13, 0, 0]),
  Fi = new Ei([16, 17, 18, 0, 8, 7, 9, 6, 10, 5, 11, 4, 12, 3, 13, 2, 14, 1, 15]),
  Ti = function (e, t) {
    for (var i = new xi(31), r = 0; r < 31; ++r) i[r] = t += 1 << e[r - 1];
    var s = new Ii(i[30]);
    for (r = 1; r < 30; ++r) for (var n = i[r]; n < i[r + 1]; ++n) s[n] = n - i[r] << 5 | r;
    return [i, s];
  },
  Ri = Ti(Pi, 2),
  $i = Ri[0],
  Oi = Ri[1];
$i[28] = 258, Oi[258] = 28;
for (var Ai = Ti(Ci, 0)[1], Mi = new xi(32768), Li = 0; Li < 32768; ++Li) {
  var Di = (43690 & Li) >>> 1 | (21845 & Li) << 1;
  Di = (61680 & (Di = (52428 & Di) >>> 2 | (13107 & Di) << 2)) >>> 4 | (3855 & Di) << 4, Mi[Li] = ((65280 & Di) >>> 8 | (255 & Di) << 8) >>> 1;
}
var qi = function (e, t, i) {
    for (var r = e.length, s = 0, n = new xi(t); s < r; ++s) ++n[e[s] - 1];
    var o,
      a = new xi(t);
    for (s = 0; s < t; ++s) a[s] = a[s - 1] + n[s - 1] << 1;
    if (i) {
      o = new xi(1 << t);
      var l = 15 - t;
      for (s = 0; s < r; ++s) if (e[s]) for (var u = s << 4 | e[s], c = t - e[s], d = a[e[s] - 1]++ << c, h = d | (1 << c) - 1; d <= h; ++d) o[Mi[d] >>> l] = u;
    } else for (o = new xi(r), s = 0; s < r; ++s) o[s] = Mi[a[e[s] - 1]++] >>> 15 - e[s];
    return o;
  },
  Ni = new Ei(288);
for (Li = 0; Li < 144; ++Li) Ni[Li] = 8;
for (Li = 144; Li < 256; ++Li) Ni[Li] = 9;
for (Li = 256; Li < 280; ++Li) Ni[Li] = 7;
for (Li = 280; Li < 288; ++Li) Ni[Li] = 8;
var Bi = new Ei(32);
for (Li = 0; Li < 32; ++Li) Bi[Li] = 5;
var Hi = qi(Ni, 9, 0),
  Ui = qi(Bi, 5, 0),
  zi = function (e) {
    return (e / 8 >> 0) + (7 & e && 1);
  },
  ji = function (e, t, i) {
    (null == i || i > e.length) && (i = e.length);
    var r = new (e instanceof xi ? xi : e instanceof Ii ? Ii : Ei)(i - t);
    return r.set(e.subarray(t, i)), r;
  },
  Wi = function (e, t, i) {
    i <<= 7 & t;
    var r = t / 8 >> 0;
    e[r] |= i, e[r + 1] |= i >>> 8;
  },
  Vi = function (e, t, i) {
    i <<= 7 & t;
    var r = t / 8 >> 0;
    e[r] |= i, e[r + 1] |= i >>> 8, e[r + 2] |= i >>> 16;
  },
  Gi = function (e, t) {
    for (var i = [], r = 0; r < e.length; ++r) e[r] && i.push({
      s: r,
      f: e[r]
    });
    var s = i.length,
      n = i.slice();
    if (!s) return [new Ei(0), 0];
    if (1 == s) {
      var o = new Ei(i[0].s + 1);
      return o[i[0].s] = 1, [o, 1];
    }
    i.sort(function (e, t) {
      return e.f - t.f;
    }), i.push({
      s: -1,
      f: 25001
    });
    var a = i[0],
      l = i[1],
      u = 0,
      c = 1,
      d = 2;
    for (i[0] = {
      s: -1,
      f: a.f + l.f,
      l: a,
      r: l
    }; c != s - 1;) a = i[i[u].f < i[d].f ? u++ : d++], l = i[u != c && i[u].f < i[d].f ? u++ : d++], i[c++] = {
      s: -1,
      f: a.f + l.f,
      l: a,
      r: l
    };
    var h = n[0].s;
    for (r = 1; r < s; ++r) n[r].s > h && (h = n[r].s);
    var _ = new xi(h + 1),
      p = Ji(i[c - 1], _, 0);
    if (p > t) {
      r = 0;
      var v = 0,
        g = p - t,
        f = 1 << g;
      for (n.sort(function (e, t) {
        return _[t.s] - _[e.s] || e.f - t.f;
      }); r < s; ++r) {
        var m = n[r].s;
        if (!(_[m] > t)) break;
        v += f - (1 << p - _[m]), _[m] = t;
      }
      for (v >>>= g; v > 0;) {
        var y = n[r].s;
        _[y] < t ? v -= 1 << t - _[y]++ - 1 : ++r;
      }
      for (; r >= 0 && v; --r) {
        var b = n[r].s;
        _[b] == t && (--_[b], ++v);
      }
      p = t;
    }
    return [new Ei(_), p];
  },
  Ji = function (e, t, i) {
    return -1 == e.s ? Math.max(Ji(e.l, t, i + 1), Ji(e.r, t, i + 1)) : t[e.s] = i;
  },
  Yi = function (e) {
    for (var t = e.length; t && !e[--t];);
    for (var i = new xi(++t), r = 0, s = e[0], n = 1, o = function (e) {
        i[r++] = e;
      }, a = 1; a <= t; ++a) if (e[a] == s && a != t) ++n;else {
      if (!s && n > 2) {
        for (; n > 138; n -= 138) o(32754);
        n > 2 && (o(n > 10 ? n - 11 << 5 | 28690 : n - 3 << 5 | 12305), n = 0);
      } else if (n > 3) {
        for (o(s), --n; n > 6; n -= 6) o(8304);
        n > 2 && (o(n - 3 << 5 | 8208), n = 0);
      }
      for (; n--;) o(s);
      n = 1, s = e[a];
    }
    return [i.subarray(0, r), t];
  },
  Ki = function (e, t) {
    for (var i = 0, r = 0; r < t.length; ++r) i += e[r] * t[r];
    return i;
  },
  Xi = function (e, t, i) {
    var r = i.length,
      s = zi(t + 2);
    e[s] = 255 & r, e[s + 1] = r >>> 8, e[s + 2] = 255 ^ e[s], e[s + 3] = 255 ^ e[s + 1];
    for (var n = 0; n < r; ++n) e[s + n + 4] = i[n];
    return 8 * (s + 4 + r);
  },
  Qi = function (e, t, i, r, s, n, o, a, l, u, c) {
    Wi(t, c++, i), ++s[256];
    for (var d = Gi(s, 15), h = d[0], _ = d[1], p = Gi(n, 15), v = p[0], g = p[1], f = Yi(h), m = f[0], y = f[1], b = Yi(v), w = b[0], S = b[1], k = new xi(19), E = 0; E < m.length; ++E) k[31 & m[E]]++;
    for (E = 0; E < w.length; ++E) k[31 & w[E]]++;
    for (var x = Gi(k, 7), I = x[0], P = x[1], C = 19; C > 4 && !I[Fi[C - 1]]; --C);
    var F,
      T,
      R,
      $,
      O = u + 5 << 3,
      A = Ki(s, Ni) + Ki(n, Bi) + o,
      M = Ki(s, h) + Ki(n, v) + o + 14 + 3 * C + Ki(k, I) + (2 * k[16] + 3 * k[17] + 7 * k[18]);
    if (O <= A && O <= M) return Xi(t, c, e.subarray(l, l + u));
    if (Wi(t, c, 1 + (M < A)), c += 2, M < A) {
      F = qi(h, _, 0), T = h, R = qi(v, g, 0), $ = v;
      var L = qi(I, P, 0);
      Wi(t, c, y - 257), Wi(t, c + 5, S - 1), Wi(t, c + 10, C - 4), c += 14;
      for (E = 0; E < C; ++E) Wi(t, c + 3 * E, I[Fi[E]]);
      c += 3 * C;
      for (var D = [m, w], q = 0; q < 2; ++q) {
        var N = D[q];
        for (E = 0; E < N.length; ++E) {
          var B = 31 & N[E];
          Wi(t, c, L[B]), c += I[B], B > 15 && (Wi(t, c, N[E] >>> 5 & 127), c += N[E] >>> 12);
        }
      }
    } else F = Hi, T = Ni, R = Ui, $ = Bi;
    for (E = 0; E < a; ++E) if (r[E] > 255) {
      B = r[E] >>> 18 & 31;
      Vi(t, c, F[B + 257]), c += T[B + 257], B > 7 && (Wi(t, c, r[E] >>> 23 & 31), c += Pi[B]);
      var H = 31 & r[E];
      Vi(t, c, R[H]), c += $[H], H > 3 && (Vi(t, c, r[E] >>> 5 & 8191), c += Ci[H]);
    } else Vi(t, c, F[r[E]]), c += T[r[E]];
    return Vi(t, c, F[256]), c + T[256];
  },
  Zi = new Ii([65540, 131080, 131088, 131104, 262176, 1048704, 1048832, 2114560, 2117632]),
  er = function () {
    for (var e = new Ii(256), t = 0; t < 256; ++t) {
      for (var i = t, r = 9; --r;) i = (1 & i && 3988292384) ^ i >>> 1;
      e[t] = i;
    }
    return e;
  }(),
  tr = function () {
    var e = 4294967295;
    return {
      p: function (t) {
        for (var i = e, r = 0; r < t.length; ++r) i = er[255 & i ^ t[r]] ^ i >>> 8;
        e = i;
      },
      d: function () {
        return 4294967295 ^ e;
      }
    };
  },
  ir = function (e, t, i, r, s) {
    return function (e, t, i, r, s, n) {
      var o = e.length,
        a = new Ei(r + o + 5 * (1 + Math.floor(o / 7e3)) + s),
        l = a.subarray(r, a.length - s),
        u = 0;
      if (!t || o < 8) for (var c = 0; c <= o; c += 65535) {
        var d = c + 65535;
        d < o ? u = Xi(l, u, e.subarray(c, d)) : (l[c] = n, u = Xi(l, u, e.subarray(c, o)));
      } else {
        for (var h = Zi[t - 1], _ = h >>> 13, p = 8191 & h, v = (1 << i) - 1, g = new xi(32768), f = new xi(v + 1), m = Math.ceil(i / 3), y = 2 * m, b = function (t) {
            return (e[t] ^ e[t + 1] << m ^ e[t + 2] << y) & v;
          }, w = new Ii(25e3), S = new xi(288), k = new xi(32), E = 0, x = 0, I = (c = 0, 0), P = 0, C = 0; c < o; ++c) {
          var F = b(c),
            T = 32767 & c,
            R = f[F];
          if (g[T] = R, f[F] = T, P <= c) {
            var $ = o - c;
            if ((E > 7e3 || I > 24576) && $ > 423) {
              u = Qi(e, l, 0, w, S, k, x, I, C, c - C, u), I = E = x = 0, C = c;
              for (var O = 0; O < 286; ++O) S[O] = 0;
              for (O = 0; O < 30; ++O) k[O] = 0;
            }
            var A = 2,
              M = 0,
              L = p,
              D = T - R & 32767;
            if ($ > 2 && F == b(c - D)) for (var q = Math.min(_, $) - 1, N = Math.min(32767, c), B = Math.min(258, $); D <= N && --L && T != R;) {
              if (e[c + A] == e[c + A - D]) {
                for (var H = 0; H < B && e[c + H] == e[c + H - D]; ++H);
                if (H > A) {
                  if (A = H, M = D, H > q) break;
                  var U = Math.min(D, H - 2),
                    z = 0;
                  for (O = 0; O < U; ++O) {
                    var j = c - D + O + 32768 & 32767,
                      W = j - g[j] + 32768 & 32767;
                    W > z && (z = W, R = j);
                  }
                }
              }
              D += (T = R) - (R = g[T]) + 32768 & 32767;
            }
            if (M) {
              w[I++] = 268435456 | Oi[A] << 18 | Ai[M];
              var V = 31 & Oi[A],
                G = 31 & Ai[M];
              x += Pi[V] + Ci[G], ++S[257 + V], ++k[G], P = c + A, ++E;
            } else w[I++] = e[c], ++S[e[c]];
          }
        }
        u = Qi(e, l, n, w, S, k, x, I, C, c - C, u);
      }
      return ji(a, 0, r + zi(u) + s);
    }(e, null == t.level ? 6 : t.level, null == t.mem ? Math.ceil(1.5 * Math.max(8, Math.min(13, Math.log(e.length)))) : 12 + t.mem, i, r, !s);
  },
  rr = function (e, t, i) {
    for (; i; ++t) e[t] = i, i >>>= 8;
  },
  sr = function (e, t) {
    var i = t.filename;
    if (e[0] = 31, e[1] = 139, e[2] = 8, e[8] = t.level < 2 ? 4 : 9 == t.level ? 2 : 0, e[9] = 3, 0 != t.mtime && rr(e, 4, Math.floor(new Date(t.mtime || Date.now()) / 1e3)), i) {
      e[3] = 8;
      for (var r = 0; r <= i.length; ++r) e[r + 10] = i.charCodeAt(r);
    }
  },
  nr = function (e) {
    return 10 + (e.filename && e.filename.length + 1 || 0);
  };
function or(e, t) {
  void 0 === t && (t = {});
  var i = tr(),
    r = e.length;
  i.p(e);
  var s = ir(e, t, nr(t), 8),
    n = s.length;
  return sr(s, t), rr(s, n - 8, i.d()), rr(s, n - 4, r), s;
}
function ar(e, t) {
  var i = e.length;
  if ("undefined" != typeof TextEncoder) return new TextEncoder().encode(e);
  for (var r = new Ei(e.length + (e.length >>> 1)), s = 0, n = function (e) {
      r[s++] = e;
    }, o = 0; o < i; ++o) {
    if (s + 5 > r.length) {
      var a = new Ei(s + 8 + (i - o << 1));
      a.set(r), r = a;
    }
    var l = e.charCodeAt(o);
    l < 128 || t ? n(l) : l < 2048 ? (n(192 | l >>> 6), n(128 | 63 & l)) : l > 55295 && l < 57344 ? (n(240 | (l = 65536 + (1047552 & l) | 1023 & e.charCodeAt(++o)) >>> 18), n(128 | l >>> 12 & 63), n(128 | l >>> 6 & 63), n(128 | 63 & l)) : (n(224 | l >>> 12), n(128 | l >>> 6 & 63), n(128 | 63 & l));
  }
  return ji(r, 0, s);
}
function lr(e, t) {
  return function (e) {
    for (var t = 0, i = 0; i < e.length; i++) t = (t << 5) - t + e.charCodeAt(i), t |= 0;
    return Math.abs(t);
  }(e) % 100 < Si(100 * t, 0, 100);
}
var ur = "[SessionRecording]",
  cr = H(ur);
function dr() {
  var e, t;
  return null == _ || null === (e = _.__PosthogExtensions__) || void 0 === e || null === (t = e.rrweb) || void 0 === t ? void 0 : t.record;
}
var hr = 3e5,
  _r = [hi.MouseMove, hi.MouseInteraction, hi.Scroll, hi.ViewportResize, hi.Input, hi.TouchMove, hi.MediaInteraction, hi.Drag],
  pr = e => ({
    rrwebMethod: e,
    enqueuedAt: Date.now(),
    attempt: 1
  });
function vr(e) {
  return function (e, t) {
    for (var i = "", r = 0; r < e.length;) {
      var s = e[r++];
      s < 128 || t ? i += String.fromCharCode(s) : s < 224 ? i += String.fromCharCode((31 & s) << 6 | 63 & e[r++]) : s < 240 ? i += String.fromCharCode((15 & s) << 12 | (63 & e[r++]) << 6 | 63 & e[r++]) : (s = ((15 & s) << 18 | (63 & e[r++]) << 12 | (63 & e[r++]) << 6 | 63 & e[r++]) - 65536, i += String.fromCharCode(55296 | s >> 10, 56320 | 1023 & s));
    }
    return i;
  }(or(ar(JSON.stringify(e))), !0);
}
function gr(e) {
  return e.type === di.Custom && "sessionIdle" === e.data.tag;
}
function fr(e, t) {
  return t.some(t => "regex" === t.matching && new RegExp(t.url).test(e));
}
class mr {
  get sessionIdleThresholdMilliseconds() {
    return this.instance.config.session_recording.session_idle_threshold_ms || 3e5;
  }
  get started() {
    return this._captureStarted;
  }
  get sessionManager() {
    if (!this.instance.sessionManager) throw new Error(ur + " must be started with a valid sessionManager.");
    return this.instance.sessionManager;
  }
  get fullSnapshotIntervalMillis() {
    var e, t;
    return "trigger_pending" === this.triggerStatus ? 6e4 : null !== (e = null === (t = this.instance.config.session_recording) || void 0 === t ? void 0 : t.full_snapshot_interval_millis) && void 0 !== e ? e : hr;
  }
  get isSampled() {
    var e = this.instance.get_property(Ie);
    return M(e) ? e : null;
  }
  get sessionDuration() {
    var e,
      t,
      i = null === (e = this.buffer) || void 0 === e ? void 0 : e.data[(null === (t = this.buffer) || void 0 === t ? void 0 : t.data.length) - 1],
      {
        sessionStartTimestamp: r
      } = this.sessionManager.checkAndGetSessionAndWindowId(!0);
    return i ? i.timestamp - r : null;
  }
  get isRecordingEnabled() {
    var e = !!this.instance.get_property(fe),
      i = !this.instance.config.disable_session_recording;
    return t && e && i;
  }
  get isConsoleLogCaptureEnabled() {
    var e = !!this.instance.get_property(me),
      t = this.instance.config.enable_recording_console_log;
    return null != t ? t : e;
  }
  get canvasRecording() {
    var e,
      t,
      i,
      r,
      s,
      n,
      o = this.instance.config.session_recording.captureCanvas,
      a = this.instance.get_property(we),
      l = null !== (e = null !== (t = null == o ? void 0 : o.recordCanvas) && void 0 !== t ? t : null == a ? void 0 : a.enabled) && void 0 !== e && e,
      u = null !== (i = null !== (r = null == o ? void 0 : o.canvasFps) && void 0 !== r ? r : null == a ? void 0 : a.fps) && void 0 !== i ? i : 4,
      c = null !== (s = null !== (n = null == o ? void 0 : o.canvasQuality) && void 0 !== n ? n : null == a ? void 0 : a.quality) && void 0 !== s ? s : .4;
    if ("string" == typeof c) {
      var d = parseFloat(c);
      c = isNaN(d) ? .4 : d;
    }
    return {
      enabled: l,
      fps: Si(u, 0, 12, "canvas recording fps", 4),
      quality: Si(c, 0, 1, "canvas recording quality", .4)
    };
  }
  get networkPayloadCapture() {
    var e,
      t,
      i = this.instance.get_property(ye),
      r = {
        recordHeaders: null === (e = this.instance.config.session_recording) || void 0 === e ? void 0 : e.recordHeaders,
        recordBody: null === (t = this.instance.config.session_recording) || void 0 === t ? void 0 : t.recordBody
      },
      s = (null == r ? void 0 : r.recordHeaders) || (null == i ? void 0 : i.recordHeaders),
      n = (null == r ? void 0 : r.recordBody) || (null == i ? void 0 : i.recordBody),
      o = P(this.instance.config.capture_performance) ? this.instance.config.capture_performance.network_timing : this.instance.config.capture_performance,
      a = !!(M(o) ? o : null == i ? void 0 : i.capturePerformance);
    return s || n || a ? {
      recordHeaders: s,
      recordBody: n,
      recordPerformance: a
    } : void 0;
  }
  get masking() {
    var e,
      t,
      i,
      r,
      s,
      n,
      o = this.instance.get_property(be),
      a = {
        maskAllInputs: null === (e = this.instance.config.session_recording) || void 0 === e ? void 0 : e.maskAllInputs,
        maskTextSelector: null === (t = this.instance.config.session_recording) || void 0 === t ? void 0 : t.maskTextSelector,
        blockSelector: null === (i = this.instance.config.session_recording) || void 0 === i ? void 0 : i.blockSelector
      },
      l = null !== (r = null == a ? void 0 : a.maskAllInputs) && void 0 !== r ? r : null == o ? void 0 : o.maskAllInputs,
      u = null !== (s = null == a ? void 0 : a.maskTextSelector) && void 0 !== s ? s : null == o ? void 0 : o.maskTextSelector,
      c = null !== (n = null == a ? void 0 : a.blockSelector) && void 0 !== n ? n : null == o ? void 0 : o.blockSelector;
    return F(l) && F(u) && F(c) ? void 0 : {
      maskAllInputs: null == l || l,
      maskTextSelector: u,
      blockSelector: c
    };
  }
  get sampleRate() {
    var e = this.instance.get_property(Se);
    return A(e) ? e : null;
  }
  get minimumDuration() {
    var e = this.instance.get_property(ke);
    return A(e) ? e : null;
  }
  get status() {
    return this.receivedDecide ? this.isRecordingEnabled ? !1 === this.isSampled ? "disabled" : this._urlBlocked ? "paused" : O(this._linkedFlag) || this._linkedFlagSeen ? "trigger_pending" === this.triggerStatus ? "buffering" : M(this.isSampled) ? this.isSampled ? "sampled" : "disabled" : "active" : "buffering" : "disabled" : "buffering";
  }
  get urlTriggerStatus() {
    var e;
    return 0 === this._urlTriggers.length ? "trigger_disabled" : (null === (e = this.instance) || void 0 === e ? void 0 : e.get_property(Pe)) === this.sessionId ? "trigger_activated" : "trigger_pending";
  }
  get eventTriggerStatus() {
    var e;
    return 0 === this._eventTriggers.length ? "trigger_disabled" : (null === (e = this.instance) || void 0 === e ? void 0 : e.get_property(Ce)) === this.sessionId ? "trigger_activated" : "trigger_pending";
  }
  get triggerStatus() {
    var e = "trigger_activated" === this.eventTriggerStatus || "trigger_activated" === this.urlTriggerStatus,
      t = "trigger_pending" === this.eventTriggerStatus || "trigger_pending" === this.urlTriggerStatus;
    return e ? "trigger_activated" : t ? "trigger_pending" : "trigger_disabled";
  }
  constructor(e) {
    if (V(this, "queuedRRWebEvents", []), V(this, "isIdle", "unknown"), V(this, "_linkedFlagSeen", !1), V(this, "_lastActivityTimestamp", Date.now()), V(this, "_linkedFlag", null), V(this, "_removePageViewCaptureHook", void 0), V(this, "_onSessionIdListener", void 0), V(this, "_persistDecideOnSessionListener", void 0), V(this, "_samplingSessionListener", void 0), V(this, "_urlTriggers", []), V(this, "_urlBlocklist", []), V(this, "_urlBlocked", !1), V(this, "_eventTriggers", []), V(this, "_removeEventTriggerCaptureHook", void 0), V(this, "_forceAllowLocalhostNetworkCapture", !1), V(this, "_onBeforeUnload", () => {
      this._flushBuffer();
    }), V(this, "_onOffline", () => {
      this._tryAddCustomEvent("browser offline", {});
    }), V(this, "_onOnline", () => {
      this._tryAddCustomEvent("browser online", {});
    }), V(this, "_onVisibilityChange", () => {
      if (null != a && a.visibilityState) {
        var e = "window " + a.visibilityState;
        this._tryAddCustomEvent(e, {});
      }
    }), this.instance = e, this._captureStarted = !1, this._endpoint = "/s/", this.stopRrweb = void 0, this.receivedDecide = !1, !this.instance.sessionManager) throw cr.error("started without valid sessionManager"), new Error(ur + " started without valid sessionManager. This is a bug.");
    if (this.instance.config.__preview_experimental_cookieless_mode) throw new Error(ur + " cannot be used with __preview_experimental_cookieless_mode.");
    var {
      sessionId: t,
      windowId: i
    } = this.sessionManager.checkAndGetSessionAndWindowId();
    this.sessionId = t, this.windowId = i, this.buffer = this.clearBuffer(), this.sessionIdleThresholdMilliseconds >= this.sessionManager.sessionTimeoutMs && cr.warn("session_idle_threshold_ms (".concat(this.sessionIdleThresholdMilliseconds, ") is greater than the session timeout (").concat(this.sessionManager.sessionTimeoutMs, "). Session will never be detected as idle"));
  }
  startIfEnabledOrStop(e) {
    this.isRecordingEnabled ? (this._startCapture(e), ae(t, "beforeunload", this._onBeforeUnload), ae(t, "offline", this._onOffline), ae(t, "online", this._onOnline), ae(t, "visibilitychange", this._onVisibilityChange), this._setupSampling(), this._addEventTriggerListener(), O(this._removePageViewCaptureHook) && (this._removePageViewCaptureHook = this.instance.on("eventCaptured", e => {
      try {
        if ("$pageview" === e.event) {
          var t = null != e && e.properties.$current_url ? this._maskUrl(null == e ? void 0 : e.properties.$current_url) : "";
          if (!t) return;
          this._tryAddCustomEvent("$pageview", {
            href: t
          });
        }
      } catch (e) {
        cr.error("Could not add $pageview to rrweb session", e);
      }
    })), this._onSessionIdListener || (this._onSessionIdListener = this.sessionManager.onSessionId((e, t, i) => {
      var r, s, n, o;
      i && (this._tryAddCustomEvent("$session_id_change", {
        sessionId: e,
        windowId: t,
        changeReason: i
      }), null === (r = this.instance) || void 0 === r || null === (s = r.persistence) || void 0 === s || s.unregister(Ce), null === (n = this.instance) || void 0 === n || null === (o = n.persistence) || void 0 === o || o.unregister(Pe));
    }))) : this.stopRecording();
  }
  stopRecording() {
    var e, i, r, s;
    this._captureStarted && this.stopRrweb && (this.stopRrweb(), this.stopRrweb = void 0, this._captureStarted = !1, null == t || t.removeEventListener("beforeunload", this._onBeforeUnload), null == t || t.removeEventListener("offline", this._onOffline), null == t || t.removeEventListener("online", this._onOnline), null == t || t.removeEventListener("visibilitychange", this._onVisibilityChange), this.clearBuffer(), clearInterval(this._fullSnapshotTimer), null === (e = this._removePageViewCaptureHook) || void 0 === e || e.call(this), this._removePageViewCaptureHook = void 0, null === (i = this._removeEventTriggerCaptureHook) || void 0 === i || i.call(this), this._removeEventTriggerCaptureHook = void 0, null === (r = this._onSessionIdListener) || void 0 === r || r.call(this), this._onSessionIdListener = void 0, null === (s = this._samplingSessionListener) || void 0 === s || s.call(this), this._samplingSessionListener = void 0, cr.info("stopped"));
  }
  makeSamplingDecision(e) {
    var t,
      i = this.sessionId !== e,
      r = this.sampleRate;
    if (A(r)) {
      var s = this.isSampled,
        n = i || !M(s),
        o = n ? lr(e, r) : s;
      n && (o ? this._reportStarted("sampled") : cr.warn("Sample rate (".concat(r, ") has determined that this sessionId (").concat(e, ") will not be sent to the server.")), this._tryAddCustomEvent("samplingDecisionMade", {
        sampleRate: r,
        isSampled: o
      })), null === (t = this.instance.persistence) || void 0 === t || t.register({
        [Ie]: o
      });
    } else {
      var a;
      null === (a = this.instance.persistence) || void 0 === a || a.register({
        [Ie]: null
      });
    }
  }
  onRemoteConfig(e) {
    var t, i, r, s, n, o;
    (this._tryAddCustomEvent("$remote_config_received", e), this._persistRemoteConfig(e), this._linkedFlag = (null === (t = e.sessionRecording) || void 0 === t ? void 0 : t.linkedFlag) || null, null !== (i = e.sessionRecording) && void 0 !== i && i.endpoint) && (this._endpoint = null === (o = e.sessionRecording) || void 0 === o ? void 0 : o.endpoint);
    if (this._setupSampling(), !O(this._linkedFlag) && !this._linkedFlagSeen) {
      var a = T(this._linkedFlag) ? this._linkedFlag : this._linkedFlag.flag,
        l = T(this._linkedFlag) ? null : this._linkedFlag.variant;
      this.instance.onFeatureFlags((e, t) => {
        var i = P(t) && a in t,
          r = l ? t[a] === l : i;
        r && this._reportStarted("linked_flag_matched", {
          linkedFlag: a,
          linkedVariant: l
        }), this._linkedFlagSeen = r;
      });
    }
    null !== (r = e.sessionRecording) && void 0 !== r && r.urlTriggers && (this._urlTriggers = e.sessionRecording.urlTriggers), null !== (s = e.sessionRecording) && void 0 !== s && s.urlBlocklist && (this._urlBlocklist = e.sessionRecording.urlBlocklist), null !== (n = e.sessionRecording) && void 0 !== n && n.eventTriggers && (this._eventTriggers = e.sessionRecording.eventTriggers), this.receivedDecide = !0, this.startIfEnabledOrStop();
  }
  _setupSampling() {
    A(this.sampleRate) && O(this._samplingSessionListener) && (this._samplingSessionListener = this.sessionManager.onSessionId(e => {
      this.makeSamplingDecision(e);
    }));
  }
  _persistRemoteConfig(e) {
    if (this.instance.persistence) {
      var t,
        i = this.instance.persistence,
        r = () => {
          var t,
            r,
            s,
            n,
            o,
            a,
            l,
            u,
            c,
            d = null === (t = e.sessionRecording) || void 0 === t ? void 0 : t.sampleRate,
            h = O(d) ? null : parseFloat(d),
            _ = null === (r = e.sessionRecording) || void 0 === r ? void 0 : r.minimumDurationMilliseconds;
          i.register({
            [fe]: !!e.sessionRecording,
            [me]: null === (s = e.sessionRecording) || void 0 === s ? void 0 : s.consoleLogRecordingEnabled,
            [ye]: W({
              capturePerformance: e.capturePerformance
            }, null === (n = e.sessionRecording) || void 0 === n ? void 0 : n.networkPayloadCapture),
            [be]: null === (o = e.sessionRecording) || void 0 === o ? void 0 : o.masking,
            [we]: {
              enabled: null === (a = e.sessionRecording) || void 0 === a ? void 0 : a.recordCanvas,
              fps: null === (l = e.sessionRecording) || void 0 === l ? void 0 : l.canvasFps,
              quality: null === (u = e.sessionRecording) || void 0 === u ? void 0 : u.canvasQuality
            },
            [Se]: h,
            [ke]: F(_) ? null : _,
            [Ee]: null === (c = e.sessionRecording) || void 0 === c ? void 0 : c.scriptConfig
          });
        };
      r(), null === (t = this._persistDecideOnSessionListener) || void 0 === t || t.call(this), this._persistDecideOnSessionListener = this.sessionManager.onSessionId(r);
    }
  }
  log(e) {
    var t,
      i = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "log";
    null === (t = this.instance.sessionRecording) || void 0 === t || t.onRRwebEmit({
      type: 6,
      data: {
        plugin: "rrweb/console@1",
        payload: {
          level: i,
          trace: [],
          payload: [JSON.stringify(e)]
        }
      },
      timestamp: Date.now()
    });
  }
  _startCapture(e) {
    if (!F(Object.assign) && !F(Array.from) && !(this._captureStarted || this.instance.config.disable_session_recording || this.instance.consent.isOptedOut())) {
      var t, i;
      if (this._captureStarted = !0, this.sessionManager.checkAndGetSessionAndWindowId(), dr()) this._onScriptLoaded();else null === (t = _.__PosthogExtensions__) || void 0 === t || null === (i = t.loadExternalDependency) || void 0 === i || i.call(t, this.instance, this.scriptName, e => {
        if (e) return cr.error("could not load recorder", e);
        this._onScriptLoaded();
      });
      cr.info("starting"), "active" === this.status && this._reportStarted(e || "recording_initialized");
    }
  }
  get scriptName() {
    var e, t, i;
    return (null === (e = this.instance) || void 0 === e || null === (t = e.persistence) || void 0 === t || null === (i = t.get_property(Ee)) || void 0 === i ? void 0 : i.script) || "recorder";
  }
  isInteractiveEvent(e) {
    var t;
    return 3 === e.type && -1 !== _r.indexOf(null === (t = e.data) || void 0 === t ? void 0 : t.source);
  }
  _updateWindowAndSessionIds(e) {
    var t = this.isInteractiveEvent(e);
    t || this.isIdle || e.timestamp - this._lastActivityTimestamp > this.sessionIdleThresholdMilliseconds && (this.isIdle = !0, clearInterval(this._fullSnapshotTimer), this._tryAddCustomEvent("sessionIdle", {
      eventTimestamp: e.timestamp,
      lastActivityTimestamp: this._lastActivityTimestamp,
      threshold: this.sessionIdleThresholdMilliseconds,
      bufferLength: this.buffer.data.length,
      bufferSize: this.buffer.size
    }), this._flushBuffer());
    var i = !1;
    if (t && (this._lastActivityTimestamp = e.timestamp, this.isIdle)) {
      var r = "unknown" === this.isIdle;
      this.isIdle = !1, r || (this._tryAddCustomEvent("sessionNoLongerIdle", {
        reason: "user activity",
        type: e.type
      }), i = !0);
    }
    if (!this.isIdle) {
      var {
          windowId: s,
          sessionId: n
        } = this.sessionManager.checkAndGetSessionAndWindowId(!t, e.timestamp),
        o = this.sessionId !== n,
        a = this.windowId !== s;
      this.windowId = s, this.sessionId = n, o || a ? (this.stopRecording(), this.startIfEnabledOrStop("session_id_changed")) : i && this._scheduleFullSnapshot();
    }
  }
  _tryRRWebMethod(e) {
    try {
      return e.rrwebMethod(), !0;
    } catch (t) {
      return this.queuedRRWebEvents.length < 10 ? this.queuedRRWebEvents.push({
        enqueuedAt: e.enqueuedAt || Date.now(),
        attempt: e.attempt++,
        rrwebMethod: e.rrwebMethod
      }) : cr.warn("could not emit queued rrweb event.", t, e), !1;
    }
  }
  _tryAddCustomEvent(e, t) {
    return this._tryRRWebMethod(pr(() => dr().addCustomEvent(e, t)));
  }
  _tryTakeFullSnapshot() {
    return this._tryRRWebMethod(pr(() => dr().takeFullSnapshot()));
  }
  _onScriptLoaded() {
    var e,
      t,
      i,
      r,
      s = {
        blockClass: "ph-no-capture",
        blockSelector: void 0,
        ignoreClass: "ph-ignore-input",
        maskTextClass: "ph-mask",
        maskTextSelector: void 0,
        maskTextFn: void 0,
        maskAllInputs: !0,
        maskInputOptions: {
          password: !0
        },
        maskInputFn: void 0,
        slimDOMOptions: {},
        collectFonts: !1,
        inlineStylesheet: !0,
        recordCrossOriginIframes: !1
      },
      n = this.instance.config.session_recording;
    for (var [o, a] of Object.entries(n || {})) o in s && ("maskInputOptions" === o ? s.maskInputOptions = W({
      password: !0
    }, a) : s[o] = a);
    (this.canvasRecording && this.canvasRecording.enabled && (s.recordCanvas = !0, s.sampling = {
      canvas: this.canvasRecording.fps
    }, s.dataURLOptions = {
      type: "image/webp",
      quality: this.canvasRecording.quality
    }), this.masking) && (s.maskAllInputs = null === (t = this.masking.maskAllInputs) || void 0 === t || t, s.maskTextSelector = null !== (i = this.masking.maskTextSelector) && void 0 !== i ? i : void 0, s.blockSelector = null !== (r = this.masking.blockSelector) && void 0 !== r ? r : void 0);
    var l = dr();
    if (l) {
      this.mutationRateLimiter = null !== (e = this.mutationRateLimiter) && void 0 !== e ? e : new ki(l, {
        refillRate: this.instance.config.session_recording.__mutationRateLimiterRefillRate,
        bucketSize: this.instance.config.session_recording.__mutationRateLimiterBucketSize,
        onBlockedNode: (e, t) => {
          var i = "Too many mutations on node '".concat(e, "'. Rate limiting. This could be due to SVG animations or something similar");
          cr.info(i, {
            node: t
          }), this.log(ur + " " + i, "warn");
        }
      });
      var u = this._gatherRRWebPlugins();
      this.stopRrweb = l(W({
        emit: e => {
          this.onRRwebEmit(e);
        },
        plugins: u
      }, s)), this._lastActivityTimestamp = Date.now(), this.isIdle = M(this.isIdle) ? this.isIdle : "unknown", this._tryAddCustomEvent("$session_options", {
        sessionRecordingOptions: s,
        activePlugins: u.map(e => null == e ? void 0 : e.name)
      }), this._tryAddCustomEvent("$posthog_config", {
        config: this.instance.config
      });
    } else cr.error("onScriptLoaded was called but rrwebRecord is not available. This indicates something has gone wrong.");
  }
  _scheduleFullSnapshot() {
    if (this._fullSnapshotTimer && clearInterval(this._fullSnapshotTimer), !0 !== this.isIdle) {
      var e = this.fullSnapshotIntervalMillis;
      e && (this._fullSnapshotTimer = setInterval(() => {
        this._tryTakeFullSnapshot();
      }, e));
    }
  }
  _gatherRRWebPlugins() {
    var e,
      t,
      i,
      r,
      s = [],
      n = null === (e = _.__PosthogExtensions__) || void 0 === e || null === (t = e.rrwebPlugins) || void 0 === t ? void 0 : t.getRecordConsolePlugin;
    n && this.isConsoleLogCaptureEnabled && s.push(n());
    var o = null === (i = _.__PosthogExtensions__) || void 0 === i || null === (r = i.rrwebPlugins) || void 0 === r ? void 0 : r.getRecordNetworkPlugin;
    this.networkPayloadCapture && I(o) && (!St.includes(location.hostname) || this._forceAllowLocalhostNetworkCapture ? s.push(o(wi(this.instance.config, this.networkPayloadCapture))) : cr.info("NetworkCapture not started because we are on localhost."));
    return s;
  }
  onRRwebEmit(e) {
    var t;
    if (this._processQueuedEvents(), e && P(e)) {
      if (e.type === di.Meta) {
        var i = this._maskUrl(e.data.href);
        if (this._lastHref = i, !i) return;
        e.data.href = i;
      } else this._pageViewFallBack();
      if (this._checkUrlTriggerConditions(), !this._urlBlocked || function (e) {
        return e.type === di.Custom && "recording paused" === e.data.tag;
      }(e)) {
        e.type === di.FullSnapshot && this._scheduleFullSnapshot(), e.type === di.FullSnapshot && "trigger_pending" === this.triggerStatus && this.clearBuffer();
        var r = this.mutationRateLimiter ? this.mutationRateLimiter.throttleMutations(e) : e;
        if (r) {
          var s = function (e) {
            var t = e;
            if (t && P(t) && 6 === t.type && P(t.data) && "rrweb/console@1" === t.data.plugin) {
              t.data.payload.payload.length > 10 && (t.data.payload.payload = t.data.payload.payload.slice(0, 10), t.data.payload.payload.push("...[truncated]"));
              for (var i = [], r = 0; r < t.data.payload.payload.length; r++) t.data.payload.payload[r] && t.data.payload.payload[r].length > 2e3 ? i.push(t.data.payload.payload[r].slice(0, 2e3) + "...[truncated]") : i.push(t.data.payload.payload[r]);
              return t.data.payload.payload = i, e;
            }
            return e;
          }(r);
          if (this._updateWindowAndSessionIds(s), !0 !== this.isIdle || gr(s)) {
            if (gr(s)) {
              var n = s.data.payload;
              if (n) {
                var o = n.lastActivityTimestamp,
                  a = n.threshold;
                s.timestamp = o + a;
              }
            }
            var l = null === (t = this.instance.config.session_recording.compress_events) || void 0 === t || t ? function (e) {
                if (ui(e) < 1024) return e;
                try {
                  if (e.type === di.FullSnapshot) return W(W({}, e), {}, {
                    data: vr(e.data),
                    cv: "2024-10"
                  });
                  if (e.type === di.IncrementalSnapshot && e.data.source === hi.Mutation) return W(W({}, e), {}, {
                    cv: "2024-10",
                    data: W(W({}, e.data), {}, {
                      texts: vr(e.data.texts),
                      attributes: vr(e.data.attributes),
                      removes: vr(e.data.removes),
                      adds: vr(e.data.adds)
                    })
                  });
                  if (e.type === di.IncrementalSnapshot && e.data.source === hi.StyleSheetRule) return W(W({}, e), {}, {
                    cv: "2024-10",
                    data: W(W({}, e.data), {}, {
                      adds: e.data.adds ? vr(e.data.adds) : void 0,
                      removes: e.data.removes ? vr(e.data.removes) : void 0
                    })
                  });
                } catch (e) {
                  cr.error("could not compress event - will use uncompressed event", e);
                }
                return e;
              }(s) : s,
              u = {
                $snapshot_bytes: ui(l),
                $snapshot_data: l,
                $session_id: this.sessionId,
                $window_id: this.windowId
              };
            "disabled" !== this.status ? this._captureSnapshotBuffered(u) : this.clearBuffer();
          }
        }
      }
    }
  }
  _pageViewFallBack() {
    if (!this.instance.config.capture_pageview && t) {
      var e = this._maskUrl(t.location.href);
      this._lastHref !== e && (this._tryAddCustomEvent("$url_changed", {
        href: e
      }), this._lastHref = e);
    }
  }
  _processQueuedEvents() {
    if (this.queuedRRWebEvents.length) {
      var e = [...this.queuedRRWebEvents];
      this.queuedRRWebEvents = [], e.forEach(e => {
        Date.now() - e.enqueuedAt <= 2e3 && this._tryRRWebMethod(e);
      });
    }
  }
  _maskUrl(e) {
    var t = this.instance.config.session_recording;
    if (t.maskNetworkRequestFn) {
      var i,
        r = {
          url: e
        };
      return null === (i = r = t.maskNetworkRequestFn(r)) || void 0 === i ? void 0 : i.url;
    }
    return e;
  }
  clearBuffer() {
    return this.buffer = {
      size: 0,
      data: [],
      sessionId: this.sessionId,
      windowId: this.windowId
    }, this.buffer;
  }
  _flushBuffer() {
    this.flushBufferTimer && (clearTimeout(this.flushBufferTimer), this.flushBufferTimer = void 0);
    var e = this.minimumDuration,
      t = this.sessionDuration,
      i = A(t) && t >= 0,
      r = A(e) && i && t < e;
    if ("buffering" === this.status || "paused" === this.status || "disabled" === this.status || r) return this.flushBufferTimer = setTimeout(() => {
      this._flushBuffer();
    }, 2e3), this.buffer;
    this.buffer.data.length > 0 && ci(this.buffer).forEach(e => {
      this._captureSnapshot({
        $snapshot_bytes: e.size,
        $snapshot_data: e.data,
        $session_id: e.sessionId,
        $window_id: e.windowId,
        $lib: "web",
        $lib_version: p.LIB_VERSION
      });
    });
    return this.clearBuffer();
  }
  _captureSnapshotBuffered(e) {
    var t,
      i = 2 + ((null === (t = this.buffer) || void 0 === t ? void 0 : t.data.length) || 0);
    !this.isIdle && (this.buffer.size + e.$snapshot_bytes + i > 943718.4 || this.buffer.sessionId !== this.sessionId) && (this.buffer = this._flushBuffer()), this.buffer.size += e.$snapshot_bytes, this.buffer.data.push(e.$snapshot_data), this.flushBufferTimer || this.isIdle || (this.flushBufferTimer = setTimeout(() => {
      this._flushBuffer();
    }, 2e3));
  }
  _captureSnapshot(e) {
    this.instance.capture("$snapshot", e, {
      _url: this.instance.requestRouter.endpointFor("api", this._endpoint),
      _noTruncate: !0,
      _batchKey: "recordings",
      skip_client_rate_limiting: !0
    });
  }
  _checkUrlTriggerConditions() {
    if (void 0 !== t && t.location.href) {
      var e = t.location.href,
        i = this._urlBlocked,
        r = fr(e, this._urlBlocklist);
      r && !i ? this._pauseRecording() : !r && i && this._resumeRecording(), fr(e, this._urlTriggers) && this._activateTrigger("url");
    }
  }
  _activateTrigger(e) {
    var t, i;
    "trigger_pending" === this.triggerStatus && (null === (t = this.instance) || void 0 === t || null === (i = t.persistence) || void 0 === i || i.register({
      ["url" === e ? Pe : Ce]: this.sessionId
    }), this._flushBuffer(), this._reportStarted(e + "_trigger_matched"));
  }
  _pauseRecording() {
    this._urlBlocked || (this._urlBlocked = !0, clearInterval(this._fullSnapshotTimer), cr.info("recording paused due to URL blocker"), this._tryAddCustomEvent("recording paused", {
      reason: "url blocker"
    }));
  }
  _resumeRecording() {
    this._urlBlocked && (this._urlBlocked = !1, this._tryTakeFullSnapshot(), this._scheduleFullSnapshot(), this._tryAddCustomEvent("recording resumed", {
      reason: "left blocked url"
    }), cr.info("recording resumed"));
  }
  _addEventTriggerListener() {
    0 !== this._eventTriggers.length && O(this._removeEventTriggerCaptureHook) && (this._removeEventTriggerCaptureHook = this.instance.on("eventCaptured", e => {
      try {
        this._eventTriggers.includes(e.event) && this._activateTrigger("event");
      } catch (e) {
        cr.error("Could not activate event trigger", e);
      }
    }));
  }
  overrideLinkedFlag() {
    this._linkedFlagSeen = !0, this._tryTakeFullSnapshot(), this._reportStarted("linked_flag_overridden");
  }
  overrideSampling() {
    var e;
    null === (e = this.instance.persistence) || void 0 === e || e.register({
      [Ie]: !0
    }), this._tryTakeFullSnapshot(), this._reportStarted("sampling_overridden");
  }
  overrideTrigger(e) {
    this._activateTrigger(e);
  }
  _reportStarted(e, t) {
    this.instance.register_for_session({
      $session_recording_start_reason: e
    }), cr.info(e.replace("_", " "), t), m(["recording_initialized", "session_id_changed"], e) || this._tryAddCustomEvent(e, t);
  }
}
var yr = H("[SegmentIntegration]");
function br(e, t) {
  var i = e.config.segment;
  if (!i) return t();
  !function (e, t) {
    var i = e.config.segment;
    if (!i) return t();
    var r = i => {
        var r = () => i.anonymousId() || Bt();
        e.config.get_device_id = r, i.id() && (e.register({
          distinct_id: i.id(),
          $device_id: r()
        }), e.persistence.set_property(De, "identified")), t();
      },
      s = i.user();
    "then" in s && I(s.then) ? s.then(e => r(e)) : r(s);
  }(e, () => {
    i.register((e => {
      Promise && Promise.resolve || yr.warn("This browser does not have Promise support, and can not use the segment integration");
      var t = (t, i) => {
        var r;
        if (!i) return t;
        t.event.userId || t.event.anonymousId === e.get_distinct_id() || (yr.info("No userId set, resetting PostHog"), e.reset()), t.event.userId && t.event.userId !== e.get_distinct_id() && (yr.info("UserId set, identifying with PostHog"), e.identify(t.event.userId));
        var s = e._calculate_event_properties(i, null !== (r = t.event.properties) && void 0 !== r ? r : {}, new Date());
        return t.event.properties = Object.assign({}, s, t.event.properties), t;
      };
      return {
        name: "PostHog JS",
        type: "enrichment",
        version: "1.0.0",
        isLoaded: () => !0,
        load: () => Promise.resolve(),
        track: e => t(e, e.event.event),
        page: e => t(e, "$pageview"),
        identify: e => t(e, "$identify"),
        screen: e => t(e, "$screen")
      };
    })(e)).then(() => {
      t();
    });
  });
}
var wr = "posthog-js";
function Sr(e) {
  var {
    organization: t,
    projectId: i,
    prefix: r,
    severityAllowList: s = ["error"]
  } = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
  return n => {
    var o, a, l, u, c;
    if (!("*" === s || s.includes(n.level)) || !e.__loaded) return n;
    n.tags || (n.tags = {});
    var d = e.requestRouter.endpointFor("ui", "/project/".concat(e.config.token, "/person/").concat(e.get_distinct_id()));
    n.tags["PostHog Person URL"] = d, e.sessionRecordingStarted() && (n.tags["PostHog Recording URL"] = e.get_session_replay_url({
      withTimestamp: !0
    }));
    var h = (null === (o = n.exception) || void 0 === o ? void 0 : o.values) || [],
      _ = h.map(e => W(W({}, e), {}, {
        stacktrace: e.stacktrace ? W(W({}, e.stacktrace), {}, {
          type: "raw",
          frames: (e.stacktrace.frames || []).map(e => W(W({}, e), {}, {
            platform: "web:javascript"
          }))
        }) : void 0
      })),
      p = {
        $exception_message: (null === (a = h[0]) || void 0 === a ? void 0 : a.value) || n.message,
        $exception_type: null === (l = h[0]) || void 0 === l ? void 0 : l.type,
        $exception_personURL: d,
        $exception_level: n.level,
        $exception_list: _,
        $sentry_event_id: n.event_id,
        $sentry_exception: n.exception,
        $sentry_exception_message: (null === (u = h[0]) || void 0 === u ? void 0 : u.value) || n.message,
        $sentry_exception_type: null === (c = h[0]) || void 0 === c ? void 0 : c.type,
        $sentry_tags: n.tags
      };
    return t && i && (p.$sentry_url = (r || "https://sentry.io/organizations/") + t + "/issues/?project=" + i + "&query=" + n.event_id), e.exceptions.sendExceptionEvent(p), n;
  };
}
class kr {
  constructor(e, t, i, r, s) {
    this.name = wr, this.setupOnce = function (n) {
      n(Sr(e, {
        organization: t,
        projectId: i,
        prefix: r,
        severityAllowList: s
      }));
    };
  }
}
var Er,
  xr = null != t && t.location ? Pt(t.location.hash, "__posthog") || Pt(location.hash, "state") : null,
  Ir = "_postHogToolbarParams",
  Pr = H("[Toolbar]");
!function (e) {
  e[e.UNINITIALIZED = 0] = "UNINITIALIZED", e[e.LOADING = 1] = "LOADING", e[e.LOADED = 2] = "LOADED";
}(Er || (Er = {}));
class Cr {
  constructor(e) {
    this.instance = e;
  }
  setToolbarState(e) {
    _.ph_toolbar_state = e;
  }
  getToolbarState() {
    var e;
    return null !== (e = _.ph_toolbar_state) && void 0 !== e ? e : Er.UNINITIALIZED;
  }
  maybeLoadToolbar() {
    var e,
      i,
      r = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : void 0,
      s = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : void 0,
      n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : void 0;
    if (!t || !a) return !1;
    r = null !== (e = r) && void 0 !== e ? e : t.location, n = null !== (i = n) && void 0 !== i ? i : t.history;
    try {
      if (!s) {
        try {
          t.localStorage.setItem("test", "test"), t.localStorage.removeItem("test");
        } catch (e) {
          return !1;
        }
        s = null == t ? void 0 : t.localStorage;
      }
      var o,
        l = xr || Pt(r.hash, "__posthog") || Pt(r.hash, "state"),
        u = l ? ee(() => JSON.parse(atob(decodeURIComponent(l)))) || ee(() => JSON.parse(decodeURIComponent(l))) : null;
      return u && "ph_authorize" === u.action ? ((o = u).source = "url", o && Object.keys(o).length > 0 && (u.desiredHash ? r.hash = u.desiredHash : n ? n.replaceState(n.state, "", r.pathname + r.search) : r.hash = "")) : ((o = JSON.parse(s.getItem(Ir) || "{}")).source = "localstorage", delete o.userIntent), !(!o.token || this.instance.config.token !== o.token) && (this.loadToolbar(o), !0);
    } catch (e) {
      return !1;
    }
  }
  _callLoadToolbar(e) {
    var t = _.ph_load_toolbar || _.ph_load_editor;
    !O(t) && I(t) ? t(e, this.instance) : Pr.warn("No toolbar load function found");
  }
  loadToolbar(e) {
    var i = !(null == a || !a.getElementById(je));
    if (!t || i) return !1;
    var r = "custom" === this.instance.requestRouter.region && this.instance.config.advanced_disable_toolbar_metrics,
      s = W(W({
        token: this.instance.config.token
      }, e), {}, {
        apiURL: this.instance.requestRouter.endpointFor("ui")
      }, r ? {
        instrument: !1
      } : {});
    if (t.localStorage.setItem(Ir, JSON.stringify(W(W({}, s), {}, {
      source: void 0
    }))), this.getToolbarState() === Er.LOADED) this._callLoadToolbar(s);else if (this.getToolbarState() === Er.UNINITIALIZED) {
      var n, o;
      this.setToolbarState(Er.LOADING), null === (n = _.__PosthogExtensions__) || void 0 === n || null === (o = n.loadExternalDependency) || void 0 === o || o.call(n, this.instance, "toolbar", e => {
        if (e) return Pr.error("[Toolbar] Failed to load", e), void this.setToolbarState(Er.UNINITIALIZED);
        this.setToolbarState(Er.LOADED), this._callLoadToolbar(s);
      }), ae(t, "turbolinks:load", () => {
        this.setToolbarState(Er.UNINITIALIZED), this.loadToolbar(s);
      });
    }
    return !0;
  }
  _loadEditor(e) {
    return this.loadToolbar(e);
  }
  maybeLoadEditor() {
    var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : void 0,
      t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : void 0,
      i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : void 0;
    return this.maybeLoadToolbar(e, t, i);
  }
}
var Fr = H("[TracingHeaders]");
class Tr {
  constructor(e) {
    V(this, "_restoreXHRPatch", void 0), V(this, "_restoreFetchPatch", void 0), V(this, "_startCapturing", () => {
      var e, t, i, r;
      F(this._restoreXHRPatch) && (null === (e = _.__PosthogExtensions__) || void 0 === e || null === (t = e.tracingHeadersPatchFns) || void 0 === t || t._patchXHR(this.instance.sessionManager));
      F(this._restoreFetchPatch) && (null === (i = _.__PosthogExtensions__) || void 0 === i || null === (r = i.tracingHeadersPatchFns) || void 0 === r || r._patchFetch(this.instance.sessionManager));
    }), this.instance = e;
  }
  _loadScript(e) {
    var t, i, r;
    null !== (t = _.__PosthogExtensions__) && void 0 !== t && t.tracingHeadersPatchFns && e(), null === (i = _.__PosthogExtensions__) || void 0 === i || null === (r = i.loadExternalDependency) || void 0 === r || r.call(i, this.instance, "tracing-headers", t => {
      if (t) return Fr.error("failed to load script", t);
      e();
    });
  }
  startIfEnabledOrStop() {
    var e, t;
    this.instance.config.__add_tracing_headers ? this._loadScript(this._startCapturing) : (null === (e = this._restoreXHRPatch) || void 0 === e || e.call(this), null === (t = this._restoreFetchPatch) || void 0 === t || t.call(this), this._restoreXHRPatch = void 0, this._restoreFetchPatch = void 0);
  }
}
var Rr = H("[Web Vitals]"),
  $r = 9e5;
class Or {
  constructor(e) {
    var t;
    V(this, "_enabledServerSide", !1), V(this, "_initialized", !1), V(this, "buffer", {
      url: void 0,
      metrics: [],
      firstMetricTimestamp: void 0
    }), V(this, "_flushToCapture", () => {
      clearTimeout(this._delayedFlushTimer), 0 !== this.buffer.metrics.length && (this.instance.capture("$web_vitals", this.buffer.metrics.reduce((e, t) => W(W({}, e), {}, {
        ["$web_vitals_".concat(t.name, "_event")]: W({}, t),
        ["$web_vitals_".concat(t.name, "_value")]: t.value
      }), {})), this.buffer = {
        url: void 0,
        metrics: [],
        firstMetricTimestamp: void 0
      });
    }), V(this, "_addToBuffer", e => {
      var t,
        i = null === (t = this.instance.sessionManager) || void 0 === t ? void 0 : t.checkAndGetSessionAndWindowId(!0);
      if (F(i)) Rr.error("Could not read session ID. Dropping metrics!");else {
        this.buffer = this.buffer || {
          url: void 0,
          metrics: [],
          firstMetricTimestamp: void 0
        };
        var r = this._currentURL();
        if (!F(r)) if (O(null == e ? void 0 : e.name) || O(null == e ? void 0 : e.value)) Rr.error("Invalid metric received", e);else if (this._maxAllowedValue && e.value >= this._maxAllowedValue) Rr.error("Ignoring metric with value >= " + this._maxAllowedValue, e);else this.buffer.url !== r && (this._flushToCapture(), this._delayedFlushTimer = setTimeout(this._flushToCapture, this.flushToCaptureTimeoutMs)), F(this.buffer.url) && (this.buffer.url = r), this.buffer.firstMetricTimestamp = F(this.buffer.firstMetricTimestamp) ? Date.now() : this.buffer.firstMetricTimestamp, e.attribution && e.attribution.interactionTargetElement && (e.attribution.interactionTargetElement = void 0), this.buffer.metrics.push(W(W({}, e), {}, {
          $current_url: r,
          $session_id: i.sessionId,
          $window_id: i.windowId,
          timestamp: Date.now()
        })), this.buffer.metrics.length === this.allowedMetrics.length && this._flushToCapture();
      }
    }), V(this, "_startCapturing", () => {
      var e,
        t,
        i,
        r,
        s = _.__PosthogExtensions__;
      F(s) || F(s.postHogWebVitalsCallbacks) || ({
        onLCP: e,
        onCLS: t,
        onFCP: i,
        onINP: r
      } = s.postHogWebVitalsCallbacks), e && t && i && r ? (this.allowedMetrics.indexOf("LCP") > -1 && e(this._addToBuffer.bind(this)), this.allowedMetrics.indexOf("CLS") > -1 && t(this._addToBuffer.bind(this)), this.allowedMetrics.indexOf("FCP") > -1 && i(this._addToBuffer.bind(this)), this.allowedMetrics.indexOf("INP") > -1 && r(this._addToBuffer.bind(this)), this._initialized = !0) : Rr.error("web vitals callbacks not loaded - not starting");
    }), this.instance = e, this._enabledServerSide = !(null === (t = this.instance.persistence) || void 0 === t || !t.props[pe]), this.startIfEnabled();
  }
  get allowedMetrics() {
    var e,
      t,
      i = P(this.instance.config.capture_performance) ? null === (e = this.instance.config.capture_performance) || void 0 === e ? void 0 : e.web_vitals_allowed_metrics : void 0;
    return F(i) ? (null === (t = this.instance.persistence) || void 0 === t ? void 0 : t.props[ge]) || ["CLS", "FCP", "INP", "LCP"] : i;
  }
  get flushToCaptureTimeoutMs() {
    return (P(this.instance.config.capture_performance) ? this.instance.config.capture_performance.web_vitals_delayed_flush_ms : void 0) || 5e3;
  }
  get _maxAllowedValue() {
    var e = P(this.instance.config.capture_performance) && A(this.instance.config.capture_performance.__web_vitals_max_value) ? this.instance.config.capture_performance.__web_vitals_max_value : $r;
    return 0 < e && e <= 6e4 ? $r : e;
  }
  get isEnabled() {
    var e = P(this.instance.config.capture_performance) ? this.instance.config.capture_performance.web_vitals : void 0;
    return M(e) ? e : this._enabledServerSide;
  }
  startIfEnabled() {
    this.isEnabled && !this._initialized && (Rr.info("enabled, starting..."), this.loadScript(this._startCapturing));
  }
  onRemoteConfig(e) {
    var t = P(e.capturePerformance) && !!e.capturePerformance.web_vitals,
      i = P(e.capturePerformance) ? e.capturePerformance.web_vitals_allowed_metrics : void 0;
    this.instance.persistence && (this.instance.persistence.register({
      [pe]: t
    }), this.instance.persistence.register({
      [ge]: i
    })), this._enabledServerSide = t, this.startIfEnabled();
  }
  loadScript(e) {
    var t, i, r;
    null !== (t = _.__PosthogExtensions__) && void 0 !== t && t.postHogWebVitalsCallbacks && e(), null === (i = _.__PosthogExtensions__) || void 0 === i || null === (r = i.loadExternalDependency) || void 0 === r || r.call(i, this.instance, "web-vitals", t => {
      t ? Rr.error("failed to load script", t) : e();
    });
  }
  _currentURL() {
    var e = t ? t.location.href : void 0;
    return e || Rr.error("Could not determine current URL"), e;
  }
}
var Ar = H("[Heatmaps]");
function Mr(e) {
  return P(e) && "clientX" in e && "clientY" in e && A(e.clientX) && A(e.clientY);
}
class Lr {
  constructor(e) {
    var t;
    V(this, "rageclicks", new wt()), V(this, "_enabledServerSide", !1), V(this, "_initialized", !1), V(this, "_flushInterval", null), this.instance = e, this._enabledServerSide = !(null === (t = this.instance.persistence) || void 0 === t || !t.props[he]);
  }
  get flushIntervalMilliseconds() {
    var e = 5e3;
    return P(this.instance.config.capture_heatmaps) && this.instance.config.capture_heatmaps.flush_interval_milliseconds && (e = this.instance.config.capture_heatmaps.flush_interval_milliseconds), e;
  }
  get isEnabled() {
    return F(this.instance.config.capture_heatmaps) ? F(this.instance.config.enable_heatmaps) ? this._enabledServerSide : this.instance.config.enable_heatmaps : !1 !== this.instance.config.capture_heatmaps;
  }
  startIfEnabled() {
    if (this.isEnabled) {
      if (this._initialized) return;
      Ar.info("starting..."), this._setupListeners(), this._flushInterval = setInterval(this.flush.bind(this), this.flushIntervalMilliseconds);
    } else {
      var e, t;
      clearInterval(null !== (e = this._flushInterval) && void 0 !== e ? e : void 0), null === (t = this.deadClicksCapture) || void 0 === t || t.stop(), this.getAndClearBuffer();
    }
  }
  onRemoteConfig(e) {
    var t = !!e.heatmaps;
    this.instance.persistence && this.instance.persistence.register({
      [he]: t
    }), this._enabledServerSide = t, this.startIfEnabled();
  }
  getAndClearBuffer() {
    var e = this.buffer;
    return this.buffer = void 0, e;
  }
  _onDeadClick(e) {
    this._onClick(e.originalEvent, "deadclick");
  }
  _setupListeners() {
    t && a && (ae(t, "beforeunload", this.flush.bind(this)), ae(a, "click", e => this._onClick(e || (null == t ? void 0 : t.event)), {
      capture: !0
    }), ae(a, "mousemove", e => this._onMouseMove(e || (null == t ? void 0 : t.event)), {
      capture: !0
    }), this.deadClicksCapture = new oi(this.instance, si, this._onDeadClick.bind(this)), this.deadClicksCapture.startIfEnabled(), this._initialized = !0);
  }
  _getProperties(e, i) {
    var r = this.instance.scrollManager.scrollY(),
      s = this.instance.scrollManager.scrollX(),
      n = this.instance.scrollManager.scrollElement(),
      o = function (e, i, r) {
        for (var s = e; s && Je(s) && !Ye(s, "body");) {
          if (s === r) return !1;
          if (m(i, null == t ? void 0 : t.getComputedStyle(s).position)) return !0;
          s = nt(s);
        }
        return !1;
      }(rt(e), ["fixed", "sticky"], n);
    return {
      x: e.clientX + (o ? 0 : s),
      y: e.clientY + (o ? 0 : r),
      target_fixed: o,
      type: i
    };
  }
  _onClick(e) {
    var t,
      i = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "click";
    if (!Ge(e.target) && Mr(e)) {
      var r = this._getProperties(e, i);
      null !== (t = this.rageclicks) && void 0 !== t && t.isRageClick(e.clientX, e.clientY, new Date().getTime()) && this._capture(W(W({}, r), {}, {
        type: "rageclick"
      })), this._capture(r);
    }
  }
  _onMouseMove(e) {
    !Ge(e.target) && Mr(e) && (clearTimeout(this._mouseMoveTimeout), this._mouseMoveTimeout = setTimeout(() => {
      this._capture(this._getProperties(e, "mousemove"));
    }, 500));
  }
  _capture(e) {
    if (t) {
      var i = t.location.href;
      this.buffer = this.buffer || {}, this.buffer[i] || (this.buffer[i] = []), this.buffer[i].push(e);
    }
  }
  flush() {
    this.buffer && !C(this.buffer) && this.instance.capture("$$heatmap", {
      $heatmap_data: this.getAndClearBuffer()
    });
  }
}
class Dr {
  constructor(e) {
    this._instance = e;
  }
  doPageView(e, i) {
    var r,
      s = this._previousPageViewProperties(e, i);
    return this._currentPageview = {
      pathname: null !== (r = null == t ? void 0 : t.location.pathname) && void 0 !== r ? r : "",
      pageViewId: i,
      timestamp: e
    }, this._instance.scrollManager.resetContext(), s;
  }
  doPageLeave(e) {
    var t;
    return this._previousPageViewProperties(e, null === (t = this._currentPageview) || void 0 === t ? void 0 : t.pageViewId);
  }
  doEvent() {
    var e;
    return {
      $pageview_id: null === (e = this._currentPageview) || void 0 === e ? void 0 : e.pageViewId
    };
  }
  _previousPageViewProperties(e, t) {
    var i = this._currentPageview;
    if (!i) return {
      $pageview_id: t
    };
    var r = {
        $pageview_id: t,
        $prev_pageview_id: i.pageViewId
      },
      s = this._instance.scrollManager.getContext();
    if (s && !this._instance.config.disable_scroll_properties) {
      var {
        maxScrollHeight: n,
        lastScrollY: o,
        maxScrollY: a,
        maxContentHeight: l,
        lastContentY: u,
        maxContentY: c
      } = s;
      if (!(F(n) || F(o) || F(a) || F(l) || F(u) || F(c))) {
        n = Math.ceil(n), o = Math.ceil(o), a = Math.ceil(a), l = Math.ceil(l), u = Math.ceil(u), c = Math.ceil(c);
        var d = n <= 1 ? 1 : Si(o / n, 0, 1),
          h = n <= 1 ? 1 : Si(a / n, 0, 1),
          _ = l <= 1 ? 1 : Si(u / l, 0, 1),
          p = l <= 1 ? 1 : Si(c / l, 0, 1);
        r = X(r, {
          $prev_pageview_last_scroll: o,
          $prev_pageview_last_scroll_percentage: d,
          $prev_pageview_max_scroll: a,
          $prev_pageview_max_scroll_percentage: h,
          $prev_pageview_last_content: u,
          $prev_pageview_last_content_percentage: _,
          $prev_pageview_max_content: c,
          $prev_pageview_max_content_percentage: p
        });
      }
    }
    return i.pathname && (r.$prev_pageview_pathname = i.pathname), i.timestamp && (r.$prev_pageview_duration = (e.getTime() - i.timestamp.getTime()) / 1e3), r;
  }
}
class qr {
  constructor(e) {
    this.instance = e;
  }
  sendExceptionEvent(e) {
    this.instance.capture("$exception", e, {
      _noTruncate: !0,
      _batchKey: "exceptionEvent"
    });
  }
}
var Nr,
  Br = H("[FeatureFlags]"),
  Hr = "$active_feature_flags",
  Ur = "$override_feature_flags",
  zr = "$feature_flag_payloads",
  jr = "$override_feature_flag_payloads",
  Wr = "$feature_flag_request_id",
  Vr = e => {
    var t = {};
    for (var [i, r] of Z(e || {})) r && (t[i] = r);
    return t;
  },
  Gr = e => {
    var t = e.flags;
    return t ? (e.featureFlags = Object.fromEntries(Object.keys(t).map(e => {
      var i;
      return [e, null !== (i = t[e].variant) && void 0 !== i ? i : t[e].enabled];
    })), e.featureFlagPayloads = Object.fromEntries(Object.keys(t).filter(e => t[e].enabled).filter(e => {
      var i;
      return null === (i = t[e].metadata) || void 0 === i ? void 0 : i.payload;
    }).map(e => {
      var i;
      return [e, null === (i = t[e].metadata) || void 0 === i ? void 0 : i.payload];
    }))) : Br.warn("Using an older version of the feature flags endpoint. Please upgrade your PostHog server to the latest version"), e;
  };
!function (e) {
  e.FeatureFlags = "feature_flags", e.Recordings = "recordings";
}(Nr || (Nr = {}));
class Jr {
  constructor(e) {
    V(this, "_override_warning", !1), V(this, "_hasLoadedFlags", !1), V(this, "_requestInFlight", !1), V(this, "_reloadingDisabled", !1), V(this, "_additionalReloadRequested", !1), V(this, "_decideCalled", !1), V(this, "_flagsLoadedFromRemote", !1), this.instance = e, this.featureFlagEventHandlers = [];
  }
  decide() {
    if (this.instance.config.__preview_remote_config) this._decideCalled = !0;else {
      var e = !this._reloadDebouncer && (this.instance.config.advanced_disable_feature_flags || this.instance.config.advanced_disable_feature_flags_on_first_load);
      this._callDecideEndpoint({
        disableFlags: e
      });
    }
  }
  get hasLoadedFlags() {
    return this._hasLoadedFlags;
  }
  getFlags() {
    return Object.keys(this.getFlagVariants());
  }
  getFlagsWithDetails() {
    var e = this.instance.get_property(Re),
      t = this.instance.get_property(Ur),
      i = this.instance.get_property(jr);
    if (!i && !t) return e || {};
    var r = X({}, e || {}),
      s = [...new Set([...Object.keys(i || {}), ...Object.keys(t || {})])];
    for (var n of s) {
      var o,
        a,
        l = r[n],
        u = null == t ? void 0 : t[n],
        c = F(u) ? null !== (o = null == l ? void 0 : l.enabled) && void 0 !== o && o : !!u,
        d = F(u) ? l.variant : "string" == typeof u ? u : void 0,
        h = null == i ? void 0 : i[n],
        _ = W(W({}, l), {}, {
          enabled: c,
          variant: c ? null != d ? d : null == l ? void 0 : l.variant : void 0
        });
      if (c !== (null == l ? void 0 : l.enabled) && (_.original_enabled = null == l ? void 0 : l.enabled), d !== (null == l ? void 0 : l.variant) && (_.original_variant = null == l ? void 0 : l.variant), h) _.metadata = W(W({}, null == l ? void 0 : l.metadata), {}, {
        payload: h,
        original_payload: null == l || null === (a = l.metadata) || void 0 === a ? void 0 : a.payload
      });
      r[n] = _;
    }
    return this._override_warning || (Br.warn(" Overriding feature flag details!", {
      flagDetails: e,
      overriddenPayloads: i,
      finalDetails: r
    }), this._override_warning = !0), r;
  }
  getFlagVariants() {
    var e = this.instance.get_property(Fe),
      t = this.instance.get_property(Ur);
    if (!t) return e || {};
    for (var i = X({}, e), r = Object.keys(t), s = 0; s < r.length; s++) i[r[s]] = t[r[s]];
    return this._override_warning || (Br.warn(" Overriding feature flags!", {
      enabledFlags: e,
      overriddenFlags: t,
      finalFlags: i
    }), this._override_warning = !0), i;
  }
  getFlagPayloads() {
    var e = this.instance.get_property(zr),
      t = this.instance.get_property(jr);
    if (!t) return e || {};
    for (var i = X({}, e || {}), r = Object.keys(t), s = 0; s < r.length; s++) i[r[s]] = t[r[s]];
    return this._override_warning || (Br.warn(" Overriding feature flag payloads!", {
      flagPayloads: e,
      overriddenPayloads: t,
      finalPayloads: i
    }), this._override_warning = !0), i;
  }
  reloadFeatureFlags() {
    this._reloadingDisabled || this.instance.config.advanced_disable_feature_flags || this._reloadDebouncer || (this._reloadDebouncer = setTimeout(() => {
      this._callDecideEndpoint();
    }, 5));
  }
  clearDebouncer() {
    clearTimeout(this._reloadDebouncer), this._reloadDebouncer = void 0;
  }
  ensureFlagsLoaded() {
    this._hasLoadedFlags || this._requestInFlight || this._reloadDebouncer || this.reloadFeatureFlags();
  }
  setAnonymousDistinctId(e) {
    this.$anon_distinct_id = e;
  }
  setReloadingPaused(e) {
    this._reloadingDisabled = e;
  }
  _callDecideEndpoint(t) {
    var i;
    if (this.clearDebouncer(), !this.instance.config.advanced_disable_decide) if (this._requestInFlight) this._additionalReloadRequested = !0;else {
      var r = {
        token: this.instance.config.token,
        distinct_id: this.instance.get_distinct_id(),
        groups: this.instance.getGroups(),
        $anon_distinct_id: this.$anon_distinct_id,
        person_properties: W(W({}, (null === (i = this.instance.persistence) || void 0 === i ? void 0 : i.get_initial_props()) || {}), this.instance.get_property($e) || {}),
        group_properties: this.instance.get_property(Oe)
      };
      (null != t && t.disableFlags || this.instance.config.advanced_disable_feature_flags) && (r.disable_flags = !0), this._requestInFlight = !0, this.instance._send_request({
        method: "POST",
        url: this.instance.requestRouter.endpointFor("api", "/decide/?v=4"),
        data: r,
        compression: this.instance.config.disable_compression ? void 0 : e.Base64,
        timeout: this.instance.config.feature_flag_request_timeout_ms,
        callback: e => {
          var t,
            i,
            s,
            n = !0;
          (200 === e.statusCode && (this._additionalReloadRequested || (this.$anon_distinct_id = void 0), n = !1), this._requestInFlight = !1, this._decideCalled) || (this._decideCalled = !0, this.instance._onRemoteConfig(null !== (s = e.json) && void 0 !== s ? s : {}));
          r.disable_flags && !this._additionalReloadRequested || (this._flagsLoadedFromRemote = !n, e.json && null !== (t = e.json.quotaLimited) && void 0 !== t && t.includes(Nr.FeatureFlags) ? Br.warn("You have hit your feature flags quota limit, and will not be able to load feature flags until the quota is reset.  Please visit https://posthog.com/docs/billing/limits-alerts to learn more.") : (this.receivedFeatureFlags(null !== (i = e.json) && void 0 !== i ? i : {}, n), this._additionalReloadRequested && (this._additionalReloadRequested = !1, this._callDecideEndpoint())));
        }
      });
    }
  }
  getFeatureFlag(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    if (this._hasLoadedFlags || this.getFlags() && this.getFlags().length > 0) {
      var i = this.getFlagVariants()[e],
        r = "".concat(i),
        s = this.instance.get_property(Wr) || void 0,
        n = this.instance.get_property(Le) || {};
      if ((t.send_event || !("send_event" in t)) && (!(e in n) || !n[e].includes(r))) {
        var o, a, l, u, c, d, h, _, p, v, g;
        x(n[e]) ? n[e].push(r) : n[e] = [r], null === (o = this.instance.persistence) || void 0 === o || o.register({
          [Le]: n
        });
        var f = this.getFeatureFlagDetails(e),
          m = {
            $feature_flag: e,
            $feature_flag_response: i,
            $feature_flag_payload: this.getFeatureFlagPayload(e) || null,
            $feature_flag_request_id: s,
            $feature_flag_bootstrapped_response: (null === (a = this.instance.config.bootstrap) || void 0 === a || null === (l = a.featureFlags) || void 0 === l ? void 0 : l[e]) || null,
            $feature_flag_bootstrapped_payload: (null === (u = this.instance.config.bootstrap) || void 0 === u || null === (c = u.featureFlagPayloads) || void 0 === c ? void 0 : c[e]) || null,
            $used_bootstrap_value: !this._flagsLoadedFromRemote
          };
        F(null == f || null === (d = f.metadata) || void 0 === d ? void 0 : d.version) || (m.$feature_flag_version = f.metadata.version);
        var y,
          b = null !== (h = null == f || null === (_ = f.reason) || void 0 === _ ? void 0 : _.description) && void 0 !== h ? h : null == f || null === (p = f.reason) || void 0 === p ? void 0 : p.code;
        if (b && (m.$feature_flag_reason = b), null != f && null !== (v = f.metadata) && void 0 !== v && v.id && (m.$feature_flag_id = f.metadata.id), F(null == f ? void 0 : f.original_variant) && F(null == f ? void 0 : f.original_enabled) || (m.$feature_flag_original_response = F(f.original_variant) ? f.original_enabled : f.original_variant), null != f && null !== (g = f.metadata) && void 0 !== g && g.original_payload) m.$feature_flag_original_payload = null == f || null === (y = f.metadata) || void 0 === y ? void 0 : y.original_payload;
        this.instance.capture("$feature_flag_called", m);
      }
      return i;
    }
    Br.warn('getFeatureFlag for key "' + e + "\" failed. Feature flags didn't load in time.");
  }
  getFeatureFlagDetails(e) {
    return this.getFlagsWithDetails()[e];
  }
  getFeatureFlagPayload(e) {
    return this.getFlagPayloads()[e];
  }
  getRemoteConfigPayload(t, i) {
    var r = this.instance.config.token;
    this.instance._send_request({
      method: "POST",
      url: this.instance.requestRouter.endpointFor("api", "/decide/?v=4"),
      data: {
        distinct_id: this.instance.get_distinct_id(),
        token: r
      },
      compression: this.instance.config.disable_compression ? void 0 : e.Base64,
      timeout: this.instance.config.feature_flag_request_timeout_ms,
      callback: e => {
        var r,
          s = null === (r = e.json) || void 0 === r ? void 0 : r.featureFlagPayloads;
        i((null == s ? void 0 : s[t]) || void 0);
      }
    });
  }
  isFeatureEnabled(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    if (this._hasLoadedFlags || this.getFlags() && this.getFlags().length > 0) return !!this.getFeatureFlag(e, t);
    Br.warn('isFeatureEnabled for key "' + e + "\" failed. Feature flags didn't load in time.");
  }
  addFeatureFlagsHandler(e) {
    this.featureFlagEventHandlers.push(e);
  }
  removeFeatureFlagsHandler(e) {
    this.featureFlagEventHandlers = this.featureFlagEventHandlers.filter(t => t !== e);
  }
  receivedFeatureFlags(e, t) {
    if (this.instance.persistence) {
      this._hasLoadedFlags = !0;
      var i = this.getFlagVariants(),
        r = this.getFlagPayloads(),
        s = this.getFlagsWithDetails();
      !function (e, t) {
        var i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {},
          r = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : {},
          s = arguments.length > 4 && void 0 !== arguments[4] ? arguments[4] : {},
          n = Gr(e),
          o = n.flags,
          a = n.featureFlags,
          l = n.featureFlagPayloads;
        if (a) {
          var u = e.requestId;
          if (x(a)) {
            Br.warn("v1 of the feature flags endpoint is deprecated. Please use the latest version.");
            var c = {};
            if (a) for (var d = 0; d < a.length; d++) c[a[d]] = !0;
            t && t.register({
              [Hr]: a,
              [Fe]: c
            });
          } else {
            var h = a,
              _ = l,
              p = o;
            e.errorsWhileComputingFlags && (h = W(W({}, i), h), _ = W(W({}, r), _), p = W(W({}, s), p)), t && t.register(W({
              [Hr]: Object.keys(Vr(h)),
              [Fe]: h || {},
              [zr]: _ || {},
              [Re]: p || {}
            }, u ? {
              [Wr]: u
            } : {}));
          }
        }
      }(e, this.instance.persistence, i, r, s), this._fireFeatureFlagsCallbacks(t);
    }
  }
  override(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    Br.warn("override is deprecated. Please use overrideFeatureFlags instead."), this.overrideFeatureFlags({
      flags: e,
      suppressWarning: t
    });
  }
  overrideFeatureFlags(e) {
    if (!this.instance.__loaded || !this.instance.persistence) return Br.uninitializedWarning("posthog.featureFlags.overrideFeatureFlags");
    if (!1 === e) return this.instance.persistence.unregister(Ur), this.instance.persistence.unregister(jr), void this._fireFeatureFlagsCallbacks();
    if (e && "object" == typeof e && ("flags" in e || "payloads" in e)) {
      var t,
        i = e;
      if (this._override_warning = Boolean(null !== (t = i.suppressWarning) && void 0 !== t && t), "flags" in i) if (!1 === i.flags) this.instance.persistence.unregister(Ur);else if (i.flags) if (x(i.flags)) {
        for (var r = {}, s = 0; s < i.flags.length; s++) r[i.flags[s]] = !0;
        this.instance.persistence.register({
          [Ur]: r
        });
      } else this.instance.persistence.register({
        [Ur]: i.flags
      });
      return "payloads" in i && (!1 === i.payloads ? this.instance.persistence.unregister(jr) : i.payloads && this.instance.persistence.register({
        [jr]: i.payloads
      })), void this._fireFeatureFlagsCallbacks();
    }
    this._fireFeatureFlagsCallbacks();
  }
  onFeatureFlags(e) {
    if (this.addFeatureFlagsHandler(e), this._hasLoadedFlags) {
      var {
        flags: t,
        flagVariants: i
      } = this._prepareFeatureFlagsForCallbacks();
      e(t, i);
    }
    return () => this.removeFeatureFlagsHandler(e);
  }
  updateEarlyAccessFeatureEnrollment(e, t) {
    var i,
      r = (this.instance.get_property(Te) || []).find(t => t.flagKey === e),
      s = {
        ["$feature_enrollment/".concat(e)]: t
      },
      n = {
        $feature_flag: e,
        $feature_enrollment: t,
        $set: s
      };
    r && (n.$early_access_feature_name = r.name), this.instance.capture("$feature_enrollment_update", n), this.setPersonPropertiesForFlags(s, !1);
    var o = W(W({}, this.getFlagVariants()), {}, {
      [e]: t
    });
    null === (i = this.instance.persistence) || void 0 === i || i.register({
      [Hr]: Object.keys(Vr(o)),
      [Fe]: o
    }), this._fireFeatureFlagsCallbacks();
  }
  getEarlyAccessFeatures(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1],
      i = arguments.length > 2 ? arguments[2] : void 0,
      r = this.instance.get_property(Te),
      s = i ? "&".concat(i.map(e => "stage=".concat(e)).join("&")) : "";
    if (r && !t) return e(r);
    this.instance._send_request({
      url: this.instance.requestRouter.endpointFor("api", "/api/early_access_features/?token=".concat(this.instance.config.token).concat(s)),
      method: "GET",
      callback: t => {
        var i;
        if (t.json) {
          var r = t.json.earlyAccessFeatures;
          return null === (i = this.instance.persistence) || void 0 === i || i.register({
            [Te]: r
          }), e(r);
        }
      }
    });
  }
  _prepareFeatureFlagsForCallbacks() {
    var e = this.getFlags(),
      t = this.getFlagVariants();
    return {
      flags: e.filter(e => t[e]),
      flagVariants: Object.keys(t).filter(e => t[e]).reduce((e, i) => (e[i] = t[i], e), {})
    };
  }
  _fireFeatureFlagsCallbacks(e) {
    var {
      flags: t,
      flagVariants: i
    } = this._prepareFeatureFlagsForCallbacks();
    this.featureFlagEventHandlers.forEach(r => r(t, i, {
      errorsLoading: e
    }));
  }
  setPersonPropertiesForFlags(e) {
    var t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1],
      i = this.instance.get_property($e) || {};
    this.instance.register({
      [$e]: W(W({}, i), e)
    }), t && this.instance.reloadFeatureFlags();
  }
  resetPersonPropertiesForFlags() {
    this.instance.unregister($e);
  }
  setGroupPropertiesForFlags(e) {
    var t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1],
      i = this.instance.get_property(Oe) || {};
    0 !== Object.keys(i).length && Object.keys(i).forEach(t => {
      i[t] = W(W({}, i[t]), e[t]), delete e[t];
    }), this.instance.register({
      [Oe]: W(W({}, i), e)
    }), t && this.instance.reloadFeatureFlags();
  }
  resetGroupPropertiesForFlags(e) {
    if (e) {
      var t = this.instance.get_property(Oe) || {};
      this.instance.register({
        [Oe]: W(W({}, t), {}, {
          [e]: {}
        })
      });
    } else this.instance.unregister(Oe);
  }
}
var Yr = "Mobile",
  Kr = "iOS",
  Xr = "Android",
  Qr = "Tablet",
  Zr = Xr + " " + Qr,
  es = "iPad",
  ts = "Apple",
  is = ts + " Watch",
  rs = "Safari",
  ss = "BlackBerry",
  ns = "Samsung",
  os = ns + "Browser",
  as = ns + " Internet",
  ls = "Chrome",
  us = ls + " OS",
  cs = ls + " " + Kr,
  ds = "Internet Explorer",
  hs = ds + " " + Yr,
  _s = "Opera",
  ps = _s + " Mini",
  vs = "Edge",
  gs = "Microsoft " + vs,
  fs = "Firefox",
  ms = fs + " " + Kr,
  ys = "Nintendo",
  bs = "PlayStation",
  ws = "Xbox",
  Ss = Xr + " " + Yr,
  ks = Yr + " " + rs,
  Es = "Windows",
  xs = Es + " Phone",
  Is = "Nokia",
  Ps = "Ouya",
  Cs = "Generic",
  Fs = Cs + " " + Yr.toLowerCase(),
  Ts = Cs + " " + Qr.toLowerCase(),
  Rs = "Konqueror",
  $s = "(\\d+(\\.\\d+)?)",
  Os = new RegExp("Version/" + $s),
  As = new RegExp(ws, "i"),
  Ms = new RegExp(bs + " \\w+", "i"),
  Ls = new RegExp(ys + " \\w+", "i"),
  Ds = new RegExp(ss + "|PlayBook|BB10", "i"),
  qs = {
    "NT3.51": "NT 3.11",
    "NT4.0": "NT 4.0",
    "5.0": "2000",
    5.1: "XP",
    5.2: "XP",
    "6.0": "Vista",
    6.1: "7",
    6.2: "8",
    6.3: "8.1",
    6.4: "10",
    "10.0": "10"
  };
var Ns = (e, t) => t && m(t, ts) || function (e) {
    return m(e, rs) && !m(e, ls) && !m(e, Xr);
  }(e),
  Bs = function (e, t) {
    return t = t || "", m(e, " OPR/") && m(e, "Mini") ? ps : m(e, " OPR/") ? _s : Ds.test(e) ? ss : m(e, "IE" + Yr) || m(e, "WPDesktop") ? hs : m(e, os) ? as : m(e, vs) || m(e, "Edg/") ? gs : m(e, "FBIOS") ? "Facebook " + Yr : m(e, "UCWEB") || m(e, "UCBrowser") ? "UC Browser" : m(e, "CriOS") ? cs : m(e, "CrMo") || m(e, ls) ? ls : m(e, Xr) && m(e, rs) ? Ss : m(e, "FxiOS") ? ms : m(e.toLowerCase(), Rs.toLowerCase()) ? Rs : Ns(e, t) ? m(e, Yr) ? ks : rs : m(e, fs) ? fs : m(e, "MSIE") || m(e, "Trident/") ? ds : m(e, "Gecko") ? fs : "";
  },
  Hs = {
    [hs]: [new RegExp("rv:" + $s)],
    [gs]: [new RegExp(vs + "?\\/" + $s)],
    [ls]: [new RegExp("(" + ls + "|CrMo)\\/" + $s)],
    [cs]: [new RegExp("CriOS\\/" + $s)],
    "UC Browser": [new RegExp("(UCBrowser|UCWEB)\\/" + $s)],
    [rs]: [Os],
    [ks]: [Os],
    [_s]: [new RegExp("(Opera|OPR)\\/" + $s)],
    [fs]: [new RegExp(fs + "\\/" + $s)],
    [ms]: [new RegExp("FxiOS\\/" + $s)],
    [Rs]: [new RegExp("Konqueror[:/]?" + $s, "i")],
    [ss]: [new RegExp(ss + " " + $s), Os],
    [Ss]: [new RegExp("android\\s" + $s, "i")],
    [as]: [new RegExp(os + "\\/" + $s)],
    [ds]: [new RegExp("(rv:|MSIE )" + $s)],
    Mozilla: [new RegExp("rv:" + $s)]
  },
  Us = [[new RegExp(ws + "; " + ws + " (.*?)[);]", "i"), e => [ws, e && e[1] || ""]], [new RegExp(ys, "i"), [ys, ""]], [new RegExp(bs, "i"), [bs, ""]], [Ds, [ss, ""]], [new RegExp(Es, "i"), (e, t) => {
    if (/Phone/.test(t) || /WPDesktop/.test(t)) return [xs, ""];
    if (new RegExp(Yr).test(t) && !/IEMobile\b/.test(t)) return [Es + " " + Yr, ""];
    var i = /Windows NT ([0-9.]+)/i.exec(t);
    if (i && i[1]) {
      var r = i[1],
        s = qs[r] || "";
      return /arm/i.test(t) && (s = "RT"), [Es, s];
    }
    return [Es, ""];
  }], [/((iPhone|iPad|iPod).*?OS (\d+)_(\d+)_?(\d+)?|iPhone)/, e => {
    if (e && e[3]) {
      var t = [e[3], e[4], e[5] || "0"];
      return [Kr, t.join(".")];
    }
    return [Kr, ""];
  }], [/(watch.*\/(\d+\.\d+\.\d+)|watch os,(\d+\.\d+),)/i, e => {
    var t = "";
    return e && e.length >= 3 && (t = F(e[2]) ? e[3] : e[2]), ["watchOS", t];
  }], [new RegExp("(" + Xr + " (\\d+)\\.(\\d+)\\.?(\\d+)?|" + Xr + ")", "i"), e => {
    if (e && e[2]) {
      var t = [e[2], e[3], e[4] || "0"];
      return [Xr, t.join(".")];
    }
    return [Xr, ""];
  }], [/Mac OS X (\d+)[_.](\d+)[_.]?(\d+)?/i, e => {
    var t = ["Mac OS X", ""];
    if (e && e[1]) {
      var i = [e[1], e[2], e[3] || "0"];
      t[1] = i.join(".");
    }
    return t;
  }], [/Mac/i, ["Mac OS X", ""]], [/CrOS/, [us, ""]], [/Linux|debian/i, ["Linux", ""]]],
  zs = function (e) {
    return Ls.test(e) ? ys : Ms.test(e) ? bs : As.test(e) ? ws : new RegExp(Ps, "i").test(e) ? Ps : new RegExp("(" + xs + "|WPDesktop)", "i").test(e) ? xs : /iPad/.test(e) ? es : /iPod/.test(e) ? "iPod Touch" : /iPhone/.test(e) ? "iPhone" : /(watch)(?: ?os[,/]|\d,\d\/)[\d.]+/i.test(e) ? is : Ds.test(e) ? ss : /(kobo)\s(ereader|touch)/i.test(e) ? "Kobo" : new RegExp(Is, "i").test(e) ? Is : /(kf[a-z]{2}wi|aeo[c-r]{2})( bui|\))/i.test(e) || /(kf[a-z]+)( bui|\)).+silk\//i.test(e) ? "Kindle Fire" : /(Android|ZTE)/i.test(e) ? !new RegExp(Yr).test(e) || /(9138B|TB782B|Nexus [97]|pixel c|HUAWEISHT|BTV|noble nook|smart ultra 6)/i.test(e) ? /pixel[\daxl ]{1,6}/i.test(e) && !/pixel c/i.test(e) || /(huaweimed-al00|tah-|APA|SM-G92|i980|zte|U304AA)/i.test(e) || /lmy47v/i.test(e) && !/QTAQZ3/i.test(e) ? Xr : Zr : Xr : new RegExp("(pda|" + Yr + ")", "i").test(e) ? Fs : new RegExp(Qr, "i").test(e) && !new RegExp(Qr + " pc", "i").test(e) ? Ts : "";
  },
  js = "https?://(.*)",
  Ws = ["gclid", "gclsrc", "dclid", "gbraid", "wbraid", "fbclid", "msclkid", "twclid", "li_fat_id", "igshid", "ttclid", "rdt_cid", "irclid", "_kx"],
  Vs = Q(["utm_source", "utm_medium", "utm_campaign", "utm_content", "utm_term", "gad_source", "mc_cid"], Ws),
  Gs = "<masked>",
  Js = {
    campaignParams: function () {
      var {
        customTrackedParams: e,
        maskPersonalDataProperties: t,
        customPersonalDataProperties: i
      } = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
      if (!a) return {};
      var r = t ? Q([], Ws, i || []) : [];
      return this._campaignParamsFromUrl(It(a.URL, r, Gs), e);
    },
    _campaignParamsFromUrl: function (e, t) {
      var i = Vs.concat(t || []),
        r = {};
      return K(i, function (t) {
        var i = xt(e, t);
        r[t] = i || null;
      }), r;
    },
    _searchEngine: function (e) {
      return e ? 0 === e.search(js + "google.([^/?]*)") ? "google" : 0 === e.search(js + "bing.com") ? "bing" : 0 === e.search(js + "yahoo.com") ? "yahoo" : 0 === e.search(js + "duckduckgo.com") ? "duckduckgo" : null : null;
    },
    _searchInfoFromReferrer: function (e) {
      var t = Js._searchEngine(e),
        i = "yahoo" != t ? "q" : "p",
        r = {};
      if (!$(t)) {
        r.$search_engine = t;
        var s = a ? xt(a.referrer, i) : "";
        s.length && (r.ph_keyword = s);
      }
      return r;
    },
    searchInfo: function () {
      var e = null == a ? void 0 : a.referrer;
      return e ? this._searchInfoFromReferrer(e) : {};
    },
    browser: Bs,
    browserVersion: function (e, t) {
      var i = Bs(e, t),
        r = Hs[i];
      if (F(r)) return null;
      for (var s = 0; s < r.length; s++) {
        var n = r[s],
          o = e.match(n);
        if (o) return parseFloat(o[o.length - 2]);
      }
      return null;
    },
    browserLanguage: function () {
      return navigator.language || navigator.userLanguage;
    },
    browserLanguagePrefix: function () {
      var e = this.browserLanguage();
      return "string" == typeof e ? e.split("-")[0] : void 0;
    },
    os: function (e) {
      for (var t = 0; t < Us.length; t++) {
        var [i, r] = Us[t],
          s = i.exec(e),
          n = s && (I(r) ? r(s, e) : r);
        if (n) return n;
      }
      return ["", ""];
    },
    device: zs,
    deviceType: function (e) {
      var t = zs(e);
      return t === es || t === Zr || "Kobo" === t || "Kindle Fire" === t || t === Ts ? Qr : t === ys || t === ws || t === bs || t === Ps ? "Console" : t === is ? "Wearable" : t ? Yr : "Desktop";
    },
    referrer: function () {
      return (null == a ? void 0 : a.referrer) || "$direct";
    },
    referringDomain: function () {
      var e;
      return null != a && a.referrer && (null === (e = kt(a.referrer)) || void 0 === e ? void 0 : e.host) || "$direct";
    },
    referrerInfo: function () {
      return {
        $referrer: this.referrer(),
        $referring_domain: this.referringDomain()
      };
    },
    personInfo: function () {
      var {
          maskPersonalDataProperties: e,
          customPersonalDataProperties: t
        } = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {},
        i = e ? Q([], Ws, t || []) : [],
        r = null == l ? void 0 : l.href.substring(0, 1e3);
      return {
        r: this.referrer().substring(0, 1e3),
        u: r ? It(r, i, Gs) : void 0
      };
    },
    personPropsFromInfo: function (e) {
      var t,
        {
          r: i,
          u: r
        } = e,
        s = {
          $referrer: i,
          $referring_domain: null == i ? void 0 : "$direct" == i ? "$direct" : null === (t = kt(i)) || void 0 === t ? void 0 : t.host
        };
      if (r) {
        s.$current_url = r;
        var n = kt(r);
        s.$host = null == n ? void 0 : n.host, s.$pathname = null == n ? void 0 : n.pathname;
        var o = this._campaignParamsFromUrl(r);
        X(s, o);
      }
      if (i) {
        var a = this._searchInfoFromReferrer(i);
        X(s, a);
      }
      return s;
    },
    initialPersonPropsFromInfo: function (e) {
      var t = this.personPropsFromInfo(e),
        i = {};
      return K(t, function (e, t) {
        i["$initial_".concat(b(t))] = e;
      }), i;
    },
    timezone: function () {
      try {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
      } catch (e) {
        return;
      }
    },
    timezoneOffset: function () {
      try {
        return new Date().getTimezoneOffset();
      } catch (e) {
        return;
      }
    },
    properties: function () {
      var {
        maskPersonalDataProperties: e,
        customPersonalDataProperties: i
      } = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
      if (!h) return {};
      var r = e ? Q([], Ws, i || []) : [],
        [s, n] = Js.os(h);
      return X(ie({
        $os: s,
        $os_version: n,
        $browser: Js.browser(h, navigator.vendor),
        $device: Js.device(h),
        $device_type: Js.deviceType(h),
        $timezone: Js.timezone(),
        $timezone_offset: Js.timezoneOffset()
      }), {
        $current_url: It(null == l ? void 0 : l.href, r, Gs),
        $host: null == l ? void 0 : l.host,
        $pathname: null == l ? void 0 : l.pathname,
        $raw_user_agent: h.length > 1e3 ? h.substring(0, 997) + "..." : h,
        $browser_version: Js.browserVersion(h, navigator.vendor),
        $browser_language: Js.browserLanguage(),
        $browser_language_prefix: Js.browserLanguagePrefix(),
        $screen_height: null == t ? void 0 : t.screen.height,
        $screen_width: null == t ? void 0 : t.screen.width,
        $viewport_height: null == t ? void 0 : t.innerHeight,
        $viewport_width: null == t ? void 0 : t.innerWidth,
        $lib: "web",
        $lib_version: p.LIB_VERSION,
        $insert_id: Math.random().toString(36).substring(2, 10) + Math.random().toString(36).substring(2, 10),
        $time: Date.now() / 1e3
      });
    },
    people_properties: function () {
      if (!h) return {};
      var [e, t] = Js.os(h);
      return X(ie({
        $os: e,
        $os_version: t,
        $browser: Js.browser(h, navigator.vendor)
      }), {
        $browser_version: Js.browserVersion(h, navigator.vendor)
      });
    }
  },
  Ys = ["cookie", "localstorage", "localstorage+cookie", "sessionstorage", "memory"];
class Ks {
  constructor(e) {
    this.config = e, this.props = {}, this.campaign_params_saved = !1, this.name = (e => {
      var t = "";
      return e.token && (t = e.token.replace(/\+/g, "PL").replace(/\//g, "SL").replace(/=/g, "EQ")), e.persistence_name ? "ph_" + e.persistence_name : "ph_" + t + "_posthog";
    })(e), this.storage = this.buildStorage(e), this.load(), e.debug && B.info("Persistence loaded", e.persistence, W({}, this.props)), this.update_config(e, e), this.save();
  }
  buildStorage(e) {
    -1 === Ys.indexOf(e.persistence.toLowerCase()) && (B.critical("Unknown persistence type " + e.persistence + "; falling back to localStorage+cookie"), e.persistence = "localStorage+cookie");
    var t = e.persistence.toLowerCase();
    return "localstorage" === t && Yt.is_supported() ? Yt : "localstorage+cookie" === t && Xt.is_supported() ? Xt : "sessionstorage" === t && ti.is_supported() ? ti : "memory" === t ? Zt : "cookie" === t ? Gt : Xt.is_supported() ? Xt : Gt;
  }
  properties() {
    var e = {};
    return K(this.props, function (t, i) {
      if (i === Fe && P(t)) for (var r = Object.keys(t), s = 0; s < r.length; s++) e["$feature/".concat(r[s])] = t[r[s]];else a = i, l = !1, ($(o = Ve) ? l : n && o.indexOf === n ? -1 != o.indexOf(a) : (K(o, function (e) {
        if (l || (l = e === a)) return J;
      }), l)) || (e[i] = t);
      var o, a, l;
    }), e;
  }
  load() {
    if (!this.disabled) {
      var e = this.storage.parse(this.name);
      e && (this.props = X({}, e));
    }
  }
  save() {
    this.disabled || this.storage.set(this.name, this.props, this.expire_days, this.cross_subdomain, this.secure, this.config.debug);
  }
  remove() {
    this.storage.remove(this.name, !1), this.storage.remove(this.name, !0);
  }
  clear() {
    this.remove(), this.props = {};
  }
  register_once(e, t, i) {
    if (P(e)) {
      F(t) && (t = "None"), this.expire_days = F(i) ? this.default_expiry : i;
      var r = !1;
      if (K(e, (e, i) => {
        this.props.hasOwnProperty(i) && this.props[i] !== t || (this.props[i] = e, r = !0);
      }), r) return this.save(), !0;
    }
    return !1;
  }
  register(e, t) {
    if (P(e)) {
      this.expire_days = F(t) ? this.default_expiry : t;
      var i = !1;
      if (K(e, (t, r) => {
        e.hasOwnProperty(r) && this.props[r] !== t && (this.props[r] = t, i = !0);
      }), i) return this.save(), !0;
    }
    return !1;
  }
  unregister(e) {
    e in this.props && (delete this.props[e], this.save());
  }
  update_campaign_params() {
    if (!this.campaign_params_saved) {
      var e = Js.campaignParams({
        customTrackedParams: this.config.custom_campaign_params,
        maskPersonalDataProperties: this.config.mask_personal_data_properties,
        customPersonalDataProperties: this.config.custom_personal_data_properties
      });
      C(ie(e)) || this.register(e), this.campaign_params_saved = !0;
    }
  }
  update_search_keyword() {
    this.register(Js.searchInfo());
  }
  update_referrer_info() {
    this.register_once(Js.referrerInfo(), void 0);
  }
  set_initial_person_info() {
    this.props[Be] || this.props[He] || this.register_once({
      [Ue]: Js.personInfo({
        maskPersonalDataProperties: this.config.mask_personal_data_properties,
        customPersonalDataProperties: this.config.custom_personal_data_properties
      })
    }, void 0);
  }
  get_referrer_info() {
    return ie({
      $referrer: this.props.$referrer,
      $referring_domain: this.props.$referring_domain
    });
  }
  get_initial_props() {
    var e = {};
    K([He, Be], t => {
      var i = this.props[t];
      i && K(i, function (t, i) {
        e["$initial_" + b(i)] = t;
      });
    });
    var t = this.props[Ue];
    if (t) {
      var i = Js.initialPersonPropsFromInfo(t);
      X(e, i);
    }
    return e;
  }
  safe_merge(e) {
    return K(this.props, function (t, i) {
      i in e || (e[i] = t);
    }), e;
  }
  update_config(e, t) {
    if (this.default_expiry = this.expire_days = e.cookie_expiration, this.set_disabled(e.disable_persistence), this.set_cross_subdomain(e.cross_subdomain_cookie), this.set_secure(e.secure_cookie), e.persistence !== t.persistence) {
      var i = this.buildStorage(e),
        r = this.props;
      this.clear(), this.storage = i, this.props = r, this.save();
    }
  }
  set_disabled(e) {
    this.disabled = e, this.disabled ? this.remove() : this.save();
  }
  set_cross_subdomain(e) {
    e !== this.cross_subdomain && (this.cross_subdomain = e, this.remove(), this.save());
  }
  get_cross_subdomain() {
    return !!this.cross_subdomain;
  }
  set_secure(e) {
    e !== this.secure && (this.secure = e, this.remove(), this.save());
  }
  set_event_timer(e, t) {
    var i = this.props[ce] || {};
    i[e] = t, this.props[ce] = i, this.save();
  }
  remove_event_timer(e) {
    var t = (this.props[ce] || {})[e];
    return F(t) || (delete this.props[ce][e], this.save()), t;
  }
  get_property(e) {
    return this.props[e];
  }
  set_property(e, t) {
    this.props[e] = t, this.save();
  }
}
var Xs,
  Qs,
  Zs,
  en,
  tn,
  rn,
  sn,
  nn,
  on,
  an,
  ln,
  un,
  cn = {},
  dn = [],
  hn = /acit|ex(?:s|g|n|p|$)|rph|grid|ows|mnc|ntw|ine[ch]|zoo|^ord|itera/i,
  _n = Array.isArray;
function pn(e, t) {
  for (var i in t) e[i] = t[i];
  return e;
}
function vn(e) {
  var t = e.parentNode;
  t && t.removeChild(e);
}
function gn(e, t, i, r, s) {
  var n = {
    type: e,
    props: t,
    key: i,
    ref: r,
    __k: null,
    __: null,
    __b: 0,
    __e: null,
    __d: void 0,
    __c: null,
    constructor: void 0,
    __v: null == s ? ++Zs : s,
    __i: -1,
    __u: 0
  };
  return null == s && null != Qs.vnode && Qs.vnode(n), n;
}
function fn(e) {
  return e.children;
}
function mn(e, t) {
  this.props = e, this.context = t;
}
function yn(e, t) {
  if (null == t) return e.__ ? yn(e.__, e.__i + 1) : null;
  for (var i; t < e.__k.length; t++) if (null != (i = e.__k[t]) && null != i.__e) return i.__e;
  return "function" == typeof e.type ? yn(e) : null;
}
function bn(e) {
  var t, i;
  if (null != (e = e.__) && null != e.__c) {
    for (e.__e = e.__c.base = null, t = 0; t < e.__k.length; t++) if (null != (i = e.__k[t]) && null != i.__e) {
      e.__e = e.__c.base = i.__e;
      break;
    }
    return bn(e);
  }
}
function wn(e) {
  (!e.__d && (e.__d = !0) && en.push(e) && !Sn.__r++ || tn !== Qs.debounceRendering) && ((tn = Qs.debounceRendering) || rn)(Sn);
}
function Sn() {
  var e, t, i, r, s, n, o, a, l;
  for (en.sort(sn); e = en.shift();) e.__d && (t = en.length, r = void 0, n = (s = (i = e).__v).__e, a = [], l = [], (o = i.__P) && ((r = pn({}, s)).__v = s.__v + 1, Qs.vnode && Qs.vnode(r), Rn(o, r, s, i.__n, void 0 !== o.ownerSVGElement, 32 & s.__u ? [n] : null, a, null == n ? yn(s) : n, !!(32 & s.__u), l), r.__.__k[r.__i] = r, $n(a, r, l), r.__e != n && bn(r)), en.length > t && en.sort(sn));
  Sn.__r = 0;
}
function kn(e, t, i, r, s, n, o, a, l, u, c) {
  var d,
    h,
    _,
    p,
    v,
    g = r && r.__k || dn,
    f = t.length;
  for (i.__d = l, En(i, t, g), l = i.__d, d = 0; d < f; d++) null != (_ = i.__k[d]) && "boolean" != typeof _ && "function" != typeof _ && (h = -1 === _.__i ? cn : g[_.__i] || cn, _.__i = d, Rn(e, _, h, s, n, o, a, l, u, c), p = _.__e, _.ref && h.ref != _.ref && (h.ref && An(h.ref, null, _), c.push(_.ref, _.__c || p, _)), null == v && null != p && (v = p), 65536 & _.__u || h.__k === _.__k ? l = xn(_, l, e) : "function" == typeof _.type && void 0 !== _.__d ? l = _.__d : p && (l = p.nextSibling), _.__d = void 0, _.__u &= -196609);
  i.__d = l, i.__e = v;
}
function En(e, t, i) {
  var r,
    s,
    n,
    o,
    a,
    l = t.length,
    u = i.length,
    c = u,
    d = 0;
  for (e.__k = [], r = 0; r < l; r++) null != (s = e.__k[r] = null == (s = t[r]) || "boolean" == typeof s || "function" == typeof s ? null : "string" == typeof s || "number" == typeof s || "bigint" == typeof s || s.constructor == String ? gn(null, s, null, null, s) : _n(s) ? gn(fn, {
    children: s
  }, null, null, null) : void 0 === s.constructor && s.__b > 0 ? gn(s.type, s.props, s.key, s.ref ? s.ref : null, s.__v) : s) ? (s.__ = e, s.__b = e.__b + 1, a = In(s, i, o = r + d, c), s.__i = a, n = null, -1 !== a && (c--, (n = i[a]) && (n.__u |= 131072)), null == n || null === n.__v ? (-1 == a && d--, "function" != typeof s.type && (s.__u |= 65536)) : a !== o && (a === o + 1 ? d++ : a > o ? c > l - o ? d += a - o : d-- : d = a < o && a == o - 1 ? a - o : 0, a !== r + d && (s.__u |= 65536))) : (n = i[r]) && null == n.key && n.__e && (n.__e == e.__d && (e.__d = yn(n)), Mn(n, n, !1), i[r] = null, c--);
  if (c) for (r = 0; r < u; r++) null != (n = i[r]) && 0 == (131072 & n.__u) && (n.__e == e.__d && (e.__d = yn(n)), Mn(n, n));
}
function xn(e, t, i) {
  var r, s;
  if ("function" == typeof e.type) {
    for (r = e.__k, s = 0; r && s < r.length; s++) r[s] && (r[s].__ = e, t = xn(r[s], t, i));
    return t;
  }
  return e.__e != t && (i.insertBefore(e.__e, t || null), t = e.__e), t && t.nextSibling;
}
function In(e, t, i, r) {
  var s = e.key,
    n = e.type,
    o = i - 1,
    a = i + 1,
    l = t[i];
  if (null === l || l && s == l.key && n === l.type) return i;
  if (r > (null != l && 0 == (131072 & l.__u) ? 1 : 0)) for (; o >= 0 || a < t.length;) {
    if (o >= 0) {
      if ((l = t[o]) && 0 == (131072 & l.__u) && s == l.key && n === l.type) return o;
      o--;
    }
    if (a < t.length) {
      if ((l = t[a]) && 0 == (131072 & l.__u) && s == l.key && n === l.type) return a;
      a++;
    }
  }
  return -1;
}
function Pn(e, t, i) {
  "-" === t[0] ? e.setProperty(t, null == i ? "" : i) : e[t] = null == i ? "" : "number" != typeof i || hn.test(t) ? i : i + "px";
}
function Cn(e, t, i, r, s) {
  var n;
  e: if ("style" === t) {
    if ("string" == typeof i) e.style.cssText = i;else {
      if ("string" == typeof r && (e.style.cssText = r = ""), r) for (t in r) i && t in i || Pn(e.style, t, "");
      if (i) for (t in i) r && i[t] === r[t] || Pn(e.style, t, i[t]);
    }
  } else if ("o" === t[0] && "n" === t[1]) n = t !== (t = t.replace(/(PointerCapture)$|Capture$/, "$1")), t = t.toLowerCase() in e ? t.toLowerCase().slice(2) : t.slice(2), e.l || (e.l = {}), e.l[t + n] = i, i ? r ? i.u = r.u : (i.u = Date.now(), e.addEventListener(t, n ? Tn : Fn, n)) : e.removeEventListener(t, n ? Tn : Fn, n);else {
    if (s) t = t.replace(/xlink(H|:h)/, "h").replace(/sName$/, "s");else if ("width" !== t && "height" !== t && "href" !== t && "list" !== t && "form" !== t && "tabIndex" !== t && "download" !== t && "rowSpan" !== t && "colSpan" !== t && "role" !== t && t in e) try {
      e[t] = null == i ? "" : i;
      break e;
    } catch (e) {}
    "function" == typeof i || (null == i || !1 === i && "-" !== t[4] ? e.removeAttribute(t) : e.setAttribute(t, i));
  }
}
function Fn(e) {
  var t = this.l[e.type + !1];
  if (e.t) {
    if (e.t <= t.u) return;
  } else e.t = Date.now();
  return t(Qs.event ? Qs.event(e) : e);
}
function Tn(e) {
  return this.l[e.type + !0](Qs.event ? Qs.event(e) : e);
}
function Rn(e, t, i, r, s, n, o, a, l, u) {
  var c,
    d,
    h,
    _,
    p,
    v,
    g,
    f,
    m,
    y,
    b,
    w,
    S,
    k,
    E,
    x = t.type;
  if (void 0 !== t.constructor) return null;
  128 & i.__u && (l = !!(32 & i.__u), n = [a = t.__e = i.__e]), (c = Qs.__b) && c(t);
  e: if ("function" == typeof x) try {
    if (f = t.props, m = (c = x.contextType) && r[c.__c], y = c ? m ? m.props.value : c.__ : r, i.__c ? g = (d = t.__c = i.__c).__ = d.__E : ("prototype" in x && x.prototype.render ? t.__c = d = new x(f, y) : (t.__c = d = new mn(f, y), d.constructor = x, d.render = Ln), m && m.sub(d), d.props = f, d.state || (d.state = {}), d.context = y, d.__n = r, h = d.__d = !0, d.__h = [], d._sb = []), null == d.__s && (d.__s = d.state), null != x.getDerivedStateFromProps && (d.__s == d.state && (d.__s = pn({}, d.__s)), pn(d.__s, x.getDerivedStateFromProps(f, d.__s))), _ = d.props, p = d.state, d.__v = t, h) null == x.getDerivedStateFromProps && null != d.componentWillMount && d.componentWillMount(), null != d.componentDidMount && d.__h.push(d.componentDidMount);else {
      if (null == x.getDerivedStateFromProps && f !== _ && null != d.componentWillReceiveProps && d.componentWillReceiveProps(f, y), !d.__e && (null != d.shouldComponentUpdate && !1 === d.shouldComponentUpdate(f, d.__s, y) || t.__v === i.__v)) {
        for (t.__v !== i.__v && (d.props = f, d.state = d.__s, d.__d = !1), t.__e = i.__e, t.__k = i.__k, t.__k.forEach(function (e) {
          e && (e.__ = t);
        }), b = 0; b < d._sb.length; b++) d.__h.push(d._sb[b]);
        d._sb = [], d.__h.length && o.push(d);
        break e;
      }
      null != d.componentWillUpdate && d.componentWillUpdate(f, d.__s, y), null != d.componentDidUpdate && d.__h.push(function () {
        d.componentDidUpdate(_, p, v);
      });
    }
    if (d.context = y, d.props = f, d.__P = e, d.__e = !1, w = Qs.__r, S = 0, "prototype" in x && x.prototype.render) {
      for (d.state = d.__s, d.__d = !1, w && w(t), c = d.render(d.props, d.state, d.context), k = 0; k < d._sb.length; k++) d.__h.push(d._sb[k]);
      d._sb = [];
    } else do {
      d.__d = !1, w && w(t), c = d.render(d.props, d.state, d.context), d.state = d.__s;
    } while (d.__d && ++S < 25);
    d.state = d.__s, null != d.getChildContext && (r = pn(pn({}, r), d.getChildContext())), h || null == d.getSnapshotBeforeUpdate || (v = d.getSnapshotBeforeUpdate(_, p)), kn(e, _n(E = null != c && c.type === fn && null == c.key ? c.props.children : c) ? E : [E], t, i, r, s, n, o, a, l, u), d.base = t.__e, t.__u &= -161, d.__h.length && o.push(d), g && (d.__E = d.__ = null);
  } catch (e) {
    t.__v = null, l || null != n ? (t.__e = a, t.__u |= l ? 160 : 32, n[n.indexOf(a)] = null) : (t.__e = i.__e, t.__k = i.__k), Qs.__e(e, t, i);
  } else null == n && t.__v === i.__v ? (t.__k = i.__k, t.__e = i.__e) : t.__e = On(i.__e, t, i, r, s, n, o, l, u);
  (c = Qs.diffed) && c(t);
}
function $n(e, t, i) {
  t.__d = void 0;
  for (var r = 0; r < i.length; r++) An(i[r], i[++r], i[++r]);
  Qs.__c && Qs.__c(t, e), e.some(function (t) {
    try {
      e = t.__h, t.__h = [], e.some(function (e) {
        e.call(t);
      });
    } catch (e) {
      Qs.__e(e, t.__v);
    }
  });
}
function On(e, t, i, r, s, n, o, a, l) {
  var u,
    c,
    d,
    h,
    _,
    p,
    v,
    g = i.props,
    f = t.props,
    m = t.type;
  if ("svg" === m && (s = !0), null != n) for (u = 0; u < n.length; u++) if ((_ = n[u]) && "setAttribute" in _ == !!m && (m ? _.localName === m : 3 === _.nodeType)) {
    e = _, n[u] = null;
    break;
  }
  if (null == e) {
    if (null === m) return document.createTextNode(f);
    e = s ? document.createElementNS("http://www.w3.org/2000/svg", m) : document.createElement(m, f.is && f), n = null, a = !1;
  }
  if (null === m) g === f || a && e.data === f || (e.data = f);else {
    if (n = n && Xs.call(e.childNodes), g = i.props || cn, !a && null != n) for (g = {}, u = 0; u < e.attributes.length; u++) g[(_ = e.attributes[u]).name] = _.value;
    for (u in g) _ = g[u], "children" == u || ("dangerouslySetInnerHTML" == u ? d = _ : "key" === u || u in f || Cn(e, u, null, _, s));
    for (u in f) _ = f[u], "children" == u ? h = _ : "dangerouslySetInnerHTML" == u ? c = _ : "value" == u ? p = _ : "checked" == u ? v = _ : "key" === u || a && "function" != typeof _ || g[u] === _ || Cn(e, u, _, g[u], s);
    if (c) a || d && (c.__html === d.__html || c.__html === e.innerHTML) || (e.innerHTML = c.__html), t.__k = [];else if (d && (e.innerHTML = ""), kn(e, _n(h) ? h : [h], t, i, r, s && "foreignObject" !== m, n, o, n ? n[0] : i.__k && yn(i, 0), a, l), null != n) for (u = n.length; u--;) null != n[u] && vn(n[u]);
    a || (u = "value", void 0 !== p && (p !== e[u] || "progress" === m && !p || "option" === m && p !== g[u]) && Cn(e, u, p, g[u], !1), u = "checked", void 0 !== v && v !== e[u] && Cn(e, u, v, g[u], !1));
  }
  return e;
}
function An(e, t, i) {
  try {
    "function" == typeof e ? e(t) : e.current = t;
  } catch (e) {
    Qs.__e(e, i);
  }
}
function Mn(e, t, i) {
  var r, s;
  if (Qs.unmount && Qs.unmount(e), (r = e.ref) && (r.current && r.current !== e.__e || An(r, null, t)), null != (r = e.__c)) {
    if (r.componentWillUnmount) try {
      r.componentWillUnmount();
    } catch (e) {
      Qs.__e(e, t);
    }
    r.base = r.__P = null, e.__c = void 0;
  }
  if (r = e.__k) for (s = 0; s < r.length; s++) r[s] && Mn(r[s], t, i || "function" != typeof e.type);
  i || null == e.__e || vn(e.__e), e.__ = e.__e = e.__d = void 0;
}
function Ln(e, t, i) {
  return this.constructor(e, i);
}
Xs = dn.slice, Qs = {
  __e: function (e, t, i, r) {
    for (var s, n, o; t = t.__;) if ((s = t.__c) && !s.__) try {
      if ((n = s.constructor) && null != n.getDerivedStateFromError && (s.setState(n.getDerivedStateFromError(e)), o = s.__d), null != s.componentDidCatch && (s.componentDidCatch(e, r || {}), o = s.__d), o) return s.__E = s;
    } catch (t) {
      e = t;
    }
    throw e;
  }
}, Zs = 0, mn.prototype.setState = function (e, t) {
  var i;
  i = null != this.__s && this.__s !== this.state ? this.__s : this.__s = pn({}, this.state), "function" == typeof e && (e = e(pn({}, i), this.props)), e && pn(i, e), null != e && this.__v && (t && this._sb.push(t), wn(this));
}, mn.prototype.forceUpdate = function (e) {
  this.__v && (this.__e = !0, e && this.__h.push(e), wn(this));
}, mn.prototype.render = fn, en = [], rn = "function" == typeof Promise ? Promise.prototype.then.bind(Promise.resolve()) : setTimeout, sn = function (e, t) {
  return e.__v.__b - t.__v.__b;
}, Sn.__r = 0, nn = 0, function (e) {
  e.Popover = "popover", e.API = "api", e.Widget = "widget";
}(on || (on = {})), function (e) {
  e.Open = "open", e.MultipleChoice = "multiple_choice", e.SingleChoice = "single_choice", e.Rating = "rating", e.Link = "link";
}(an || (an = {})), function (e) {
  e.NextQuestion = "next_question", e.End = "end", e.ResponseBased = "response_based", e.SpecificQuestion = "specific_question";
}(ln || (ln = {})), function (e) {
  e.Once = "once", e.Recurring = "recurring", e.Always = "always";
}(un || (un = {}));
!function (e, t) {
  var i = {
    __c: t = "__cC" + nn++,
    __: e,
    Consumer: function (e, t) {
      return e.children(t);
    },
    Provider: function (e) {
      var i, r;
      return this.getChildContext || (i = [], (r = {})[t] = this, this.getChildContext = function () {
        return r;
      }, this.shouldComponentUpdate = function (e) {
        this.props.value !== e.value && i.some(function (e) {
          e.__e = !0, wn(e);
        });
      }, this.sub = function (e) {
        i.push(e);
        var t = e.componentWillUnmount;
        e.componentWillUnmount = function () {
          i.splice(i.indexOf(e), 1), t && t.call(e);
        };
      }), e.children;
    }
  };
  i.Provider.__ = i.Consumer.contextType = i;
}({
  isPreviewMode: !1,
  previewPageIndex: 0,
  onPopupSurveyDismissed: () => {},
  isPopup: !0,
  onPreviewSubmit: () => {}
});
var Dn = function (e, t) {
  if (!function (e) {
    try {
      new RegExp(e);
    } catch (e) {
      return !1;
    }
    return !0;
  }(t)) return !1;
  try {
    return new RegExp(t).test(e);
  } catch (e) {
    return !1;
  }
};
class qn {
  constructor() {
    V(this, "events", {}), this.events = {};
  }
  on(e, t) {
    return this.events[e] || (this.events[e] = []), this.events[e].push(t), () => {
      this.events[e] = this.events[e].filter(e => e !== t);
    };
  }
  emit(e, t) {
    for (var i of this.events[e] || []) i(t);
    for (var r of this.events["*"] || []) r(e, t);
  }
}
class Nn {
  constructor(e) {
    V(this, "_debugEventEmitter", new qn()), V(this, "checkStep", (e, t) => this.checkStepEvent(e, t) && this.checkStepUrl(e, t) && this.checkStepElement(e, t)), V(this, "checkStepEvent", (e, t) => null == t || !t.event || (null == e ? void 0 : e.event) === (null == t ? void 0 : t.event)), this.instance = e, this.actionEvents = new Set(), this.actionRegistry = new Set();
  }
  init() {
    var e;
    if (!F(null === (e = this.instance) || void 0 === e ? void 0 : e._addCaptureHook)) {
      var t;
      null === (t = this.instance) || void 0 === t || t._addCaptureHook((e, t) => {
        this.on(e, t);
      });
    }
  }
  register(e) {
    var t, i;
    if (!F(null === (t = this.instance) || void 0 === t ? void 0 : t._addCaptureHook) && (e.forEach(e => {
      var t, i;
      null === (t = this.actionRegistry) || void 0 === t || t.add(e), null === (i = e.steps) || void 0 === i || i.forEach(e => {
        var t;
        null === (t = this.actionEvents) || void 0 === t || t.add((null == e ? void 0 : e.event) || "");
      });
    }), null !== (i = this.instance) && void 0 !== i && i.autocapture)) {
      var r,
        s = new Set();
      e.forEach(e => {
        var t;
        null === (t = e.steps) || void 0 === t || t.forEach(e => {
          null != e && e.selector && s.add(null == e ? void 0 : e.selector);
        });
      }), null === (r = this.instance) || void 0 === r || r.autocapture.setElementSelectors(s);
    }
  }
  on(e, t) {
    var i;
    null != t && 0 != e.length && (this.actionEvents.has(e) || this.actionEvents.has(null == t ? void 0 : t.event)) && this.actionRegistry && (null === (i = this.actionRegistry) || void 0 === i ? void 0 : i.size) > 0 && this.actionRegistry.forEach(e => {
      this.checkAction(t, e) && this._debugEventEmitter.emit("actionCaptured", e.name);
    });
  }
  _addActionHook(e) {
    this.onAction("actionCaptured", t => e(t));
  }
  checkAction(e, t) {
    if (null == (null == t ? void 0 : t.steps)) return !1;
    for (var i of t.steps) if (this.checkStep(e, i)) return !0;
    return !1;
  }
  onAction(e, t) {
    return this._debugEventEmitter.on(e, t);
  }
  checkStepUrl(e, t) {
    if (null != t && t.url) {
      var i,
        r = null == e || null === (i = e.properties) || void 0 === i ? void 0 : i.$current_url;
      if (!r || "string" != typeof r) return !1;
      if (!Nn.matchString(r, null == t ? void 0 : t.url, (null == t ? void 0 : t.url_matching) || "contains")) return !1;
    }
    return !0;
  }
  static matchString(e, i, r) {
    switch (r) {
      case "regex":
        return !!t && Dn(e, i);
      case "exact":
        return i === e;
      case "contains":
        var s = Nn.escapeStringRegexp(i).replace(/_/g, ".").replace(/%/g, ".*");
        return Dn(e, s);
      default:
        return !1;
    }
  }
  static escapeStringRegexp(e) {
    return e.replace(/[|\\{}()[\]^$+*?.]/g, "\\$&").replace(/-/g, "\\x2d");
  }
  checkStepElement(e, t) {
    if ((null != t && t.href || null != t && t.tag_name || null != t && t.text) && !this.getElementsList(e).some(e => !(null != t && t.href && !Nn.matchString(e.href || "", null == t ? void 0 : t.href, (null == t ? void 0 : t.href_matching) || "exact")) && (null == t || !t.tag_name || e.tag_name === (null == t ? void 0 : t.tag_name)) && !(null != t && t.text && !Nn.matchString(e.text || "", null == t ? void 0 : t.text, (null == t ? void 0 : t.text_matching) || "exact") && !Nn.matchString(e.$el_text || "", null == t ? void 0 : t.text, (null == t ? void 0 : t.text_matching) || "exact")))) return !1;
    if (null != t && t.selector) {
      var i,
        r = null == e || null === (i = e.properties) || void 0 === i ? void 0 : i.$element_selectors;
      if (!r) return !1;
      if (!r.includes(null == t ? void 0 : t.selector)) return !1;
    }
    return !0;
  }
  getElementsList(e) {
    return null == (null == e ? void 0 : e.properties.$elements) ? [] : null == e ? void 0 : e.properties.$elements;
  }
}
class Bn {
  constructor(e) {
    this.instance = e, this.eventToSurveys = new Map(), this.actionToSurveys = new Map();
  }
  register(e) {
    var t;
    F(null === (t = this.instance) || void 0 === t ? void 0 : t._addCaptureHook) || (this.setupEventBasedSurveys(e), this.setupActionBasedSurveys(e));
  }
  setupActionBasedSurveys(e) {
    var t = e.filter(e => {
      var t, i, r, s;
      return (null === (t = e.conditions) || void 0 === t ? void 0 : t.actions) && (null === (i = e.conditions) || void 0 === i || null === (r = i.actions) || void 0 === r || null === (s = r.values) || void 0 === s ? void 0 : s.length) > 0;
    });
    if (0 !== t.length) {
      if (null == this.actionMatcher) {
        this.actionMatcher = new Nn(this.instance), this.actionMatcher.init();
        this.actionMatcher._addActionHook(e => {
          this.onAction(e);
        });
      }
      t.forEach(e => {
        var t, i, r, s, n, o, a, l, u, c;
        e.conditions && null !== (t = e.conditions) && void 0 !== t && t.actions && null !== (i = e.conditions) && void 0 !== i && null !== (r = i.actions) && void 0 !== r && r.values && (null === (s = e.conditions) || void 0 === s || null === (n = s.actions) || void 0 === n || null === (o = n.values) || void 0 === o ? void 0 : o.length) > 0 && (null === (a = this.actionMatcher) || void 0 === a || a.register(e.conditions.actions.values), null === (l = e.conditions) || void 0 === l || null === (u = l.actions) || void 0 === u || null === (c = u.values) || void 0 === c || c.forEach(t => {
          if (t && t.name) {
            var i = this.actionToSurveys.get(t.name);
            i && i.push(e.id), this.actionToSurveys.set(t.name, i || [e.id]);
          }
        }));
      });
    }
  }
  setupEventBasedSurveys(e) {
    var t;
    if (0 !== e.filter(e => {
      var t, i, r, s;
      return (null === (t = e.conditions) || void 0 === t ? void 0 : t.events) && (null === (i = e.conditions) || void 0 === i || null === (r = i.events) || void 0 === r || null === (s = r.values) || void 0 === s ? void 0 : s.length) > 0;
    }).length) {
      null === (t = this.instance) || void 0 === t || t._addCaptureHook((e, t) => {
        this.onEvent(e, t);
      }), e.forEach(e => {
        var t, i, r;
        null === (t = e.conditions) || void 0 === t || null === (i = t.events) || void 0 === i || null === (r = i.values) || void 0 === r || r.forEach(t => {
          if (t && t.name) {
            var i = this.eventToSurveys.get(t.name);
            i && i.push(e.id), this.eventToSurveys.set(t.name, i || [e.id]);
          }
        });
      });
    }
  }
  onEvent(e, t) {
    var i,
      r,
      s = (null === (i = this.instance) || void 0 === i || null === (r = i.persistence) || void 0 === r ? void 0 : r.props[Me]) || [];
    if (Bn.SURVEY_SHOWN_EVENT_NAME == e && t && s.length > 0) {
      var n,
        o = null == t || null === (n = t.properties) || void 0 === n ? void 0 : n.$survey_id;
      if (o) {
        var a = s.indexOf(o);
        a >= 0 && (s.splice(a, 1), this._updateActivatedSurveys(s));
      }
    } else this.eventToSurveys.has(e) && this._updateActivatedSurveys(s.concat(this.eventToSurveys.get(e) || []));
  }
  onAction(e) {
    var t,
      i,
      r = (null === (t = this.instance) || void 0 === t || null === (i = t.persistence) || void 0 === i ? void 0 : i.props[Me]) || [];
    this.actionToSurveys.has(e) && this._updateActivatedSurveys(r.concat(this.actionToSurveys.get(e) || []));
  }
  _updateActivatedSurveys(e) {
    var t, i;
    null === (t = this.instance) || void 0 === t || null === (i = t.persistence) || void 0 === i || i.register({
      [Me]: [...new Set(e)]
    });
  }
  getSurveys() {
    var e,
      t,
      i = null === (e = this.instance) || void 0 === e || null === (t = e.persistence) || void 0 === t ? void 0 : t.props[Me];
    return i || [];
  }
  getEventToSurveys() {
    return this.eventToSurveys;
  }
  _getActionMatcher() {
    return this.actionMatcher;
  }
}
V(Bn, "SURVEY_SHOWN_EVENT_NAME", "survey shown");
var Hn = H("[Surveys]"),
  Un = {
    icontains: (e, t) => e.some(e => t.toLowerCase().includes(e.toLowerCase())),
    not_icontains: (e, t) => e.every(e => !t.toLowerCase().includes(e.toLowerCase())),
    regex: (e, t) => e.some(e => Dn(t, e)),
    not_regex: (e, t) => e.every(e => !Dn(t, e)),
    exact: (e, t) => e.some(e => t === e),
    is_not: (e, t) => e.every(e => t !== e)
  };
function zn(e) {
  return null != e ? e : "icontains";
}
class jn {
  constructor(e) {
    V(this, "_isFetchingSurveys", !1), V(this, "_isInitializingSurveys", !1), this.instance = e, this._surveyEventReceiver = null;
  }
  onRemoteConfig(e) {
    this._decideServerResponse = !!e.surveys, Hn.info("decideServerResponse set to ".concat(this._decideServerResponse)), this.loadIfEnabled();
  }
  reset() {
    localStorage.removeItem("lastSeenSurveyDate");
    var e = (() => {
      for (var e = [], t = 0; t < localStorage.length; t++) {
        var i = localStorage.key(t);
        null != i && i.startsWith("seenSurvey_") && e.push(i);
      }
      return e;
    })();
    e.forEach(e => localStorage.removeItem(e));
  }
  loadIfEnabled() {
    if (!this._surveyManager) if (this._isInitializingSurveys) Hn.info("Already initializing surveys, skipping...");else if (this.instance.config.disable_surveys) Hn.info("Disabled. Not loading surveys.");else {
      var e = null == _ ? void 0 : _.__PosthogExtensions__;
      if (e) {
        if (this._decideServerResponse) {
          this._isInitializingSurveys = !0;
          try {
            var t = e.generateSurveys;
            if (t) this._surveyManager = t(this.instance), this._isInitializingSurveys = !1, this._surveyEventReceiver = new Bn(this.instance), Hn.info("Surveys loaded successfully");else {
              var i = e.loadExternalDependency;
              i ? i(this.instance, "surveys", t => {
                if (t || !e.generateSurveys) return Hn.error("Could not load surveys script", t), void (this._isInitializingSurveys = !1);
                this._surveyManager = e.generateSurveys(this.instance), this._isInitializingSurveys = !1, this._surveyEventReceiver = new Bn(this.instance), Hn.info("Surveys loaded successfully");
              }) : (Hn.error("PostHog loadExternalDependency extension not found. Cannot load remote config."), this._isInitializingSurveys = !1);
            }
          } catch (e) {
            throw Hn.error("Error initializing surveys", e), this._isInitializingSurveys = !1, e;
          }
        } else Hn.warn("Decide not loaded yet. Not loading surveys.");
      } else Hn.error("PostHog Extensions not found.");
    }
  }
  getSurveys(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    if (this.instance.config.disable_surveys) return Hn.info("Disabled. Not loading surveys."), e([]);
    var i = this.instance.get_property(Ae);
    if (i && !t) return e(i);
    if (this._isFetchingSurveys) return e([]);
    try {
      this._isFetchingSurveys = !0, this.instance._send_request({
        url: this.instance.requestRouter.endpointFor("api", "/api/surveys/?token=".concat(this.instance.config.token)),
        method: "GET",
        timeout: this.instance.config.surveys_request_timeout_ms,
        callback: t => {
          var i;
          this._isFetchingSurveys = !1;
          var r = t.statusCode;
          if (200 !== r || !t.json) return Hn.error("Surveys API could not be loaded, status: ".concat(r)), e([]);
          var s,
            n = t.json.surveys || [],
            o = n.filter(e => {
              var t, i, r, s, n, o, a, l, u, c, d, h;
              return (null === (t = e.conditions) || void 0 === t ? void 0 : t.events) && (null === (i = e.conditions) || void 0 === i || null === (r = i.events) || void 0 === r ? void 0 : r.values) && (null === (s = e.conditions) || void 0 === s || null === (n = s.events) || void 0 === n || null === (o = n.values) || void 0 === o ? void 0 : o.length) > 0 || (null === (a = e.conditions) || void 0 === a ? void 0 : a.actions) && (null === (l = e.conditions) || void 0 === l || null === (u = l.actions) || void 0 === u ? void 0 : u.values) && (null === (c = e.conditions) || void 0 === c || null === (d = c.actions) || void 0 === d || null === (h = d.values) || void 0 === h ? void 0 : h.length) > 0;
            });
          o.length > 0 && (null === (s = this._surveyEventReceiver) || void 0 === s || s.register(o));
          return null === (i = this.instance.persistence) || void 0 === i || i.register({
            [Ae]: n
          }), e(n);
        }
      });
    } catch (e) {
      throw this._isFetchingSurveys = !1, e;
    }
  }
  isSurveyFeatureFlagEnabled(e) {
    return !e || this.instance.featureFlags.isFeatureEnabled(e);
  }
  getActiveMatchingSurveys(e) {
    var i = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    this.getSurveys(i => {
      var r,
        s = i.filter(e => !(!e.start_date || e.end_date)).filter(e => {
          var i;
          if (!e.conditions) return !0;
          var r = function (e) {
              var i, r, s;
              if (null === (i = e.conditions) || void 0 === i || !i.url) return !0;
              var n = null == t || null === (r = t.location) || void 0 === r ? void 0 : r.href;
              if (!n) return !1;
              var o = [e.conditions.url];
              return Un[zn(null === (s = e.conditions) || void 0 === s ? void 0 : s.urlMatchType)](o, n);
            }(e),
            s = null === (i = e.conditions) || void 0 === i || !i.selector || (null == a ? void 0 : a.querySelector(e.conditions.selector)),
            n = function (e) {
              var t, i, r;
              if (null === (t = e.conditions) || void 0 === t || !t.deviceTypes || 0 === (null === (i = e.conditions) || void 0 === i ? void 0 : i.deviceTypes.length)) return !0;
              if (!h) return !1;
              var s = Js.deviceType(h);
              return Un[zn(null === (r = e.conditions) || void 0 === r ? void 0 : r.deviceTypesMatchType)](e.conditions.deviceTypes, s);
            }(e);
          return r && s && n;
        }),
        n = null === (r = this._surveyEventReceiver) || void 0 === r ? void 0 : r.getSurveys(),
        o = s.filter(e => {
          var t, i, r, s, o, a, l, u, c;
          if (!(e.linked_flag_key || e.targeting_flag_key || e.internal_targeting_flag_key || null !== (t = e.feature_flag_keys) && void 0 !== t && t.length)) return !0;
          var d = this.isSurveyFeatureFlagEnabled(e.linked_flag_key),
            h = this.isSurveyFeatureFlagEnabled(e.targeting_flag_key),
            _ = (null !== (i = null === (r = e.conditions) || void 0 === r || null === (s = r.events) || void 0 === s || null === (o = s.values) || void 0 === o ? void 0 : o.length) && void 0 !== i ? i : 0) > 0,
            p = (null !== (a = null === (l = e.conditions) || void 0 === l || null === (u = l.actions) || void 0 === u || null === (c = u.values) || void 0 === c ? void 0 : c.length) && void 0 !== a ? a : 0) > 0,
            v = !_ && !p || (null == n ? void 0 : n.includes(e.id)),
            g = this._canActivateRepeatedly(e) || this.isSurveyFeatureFlagEnabled(e.internal_targeting_flag_key),
            f = this.checkFlags(e);
          return d && h && g && v && f;
        });
      return e(o);
    }, i);
  }
  checkFlags(e) {
    var t;
    return null === (t = e.feature_flag_keys) || void 0 === t || !t.length || e.feature_flag_keys.every(e => {
      var {
        key: t,
        value: i
      } = e;
      return !t || !i || this.instance.featureFlags.isFeatureEnabled(i);
    });
  }
  _canActivateRepeatedly(e) {
    var t;
    return O(null === (t = _.__PosthogExtensions__) || void 0 === t ? void 0 : t.canActivateRepeatedly) ? (Hn.warn("init was not called"), !1) : _.__PosthogExtensions__.canActivateRepeatedly(e);
  }
  canRenderSurvey(e) {
    O(this._surveyManager) ? Hn.warn("init was not called") : this.getSurveys(t => {
      var i = t.filter(t => t.id === e)[0];
      this._surveyManager.canRenderSurvey(i);
    });
  }
  renderSurvey(e, t) {
    O(this._surveyManager) ? Hn.warn("init was not called") : this.getSurveys(i => {
      var r = i.filter(t => t.id === e)[0];
      this._surveyManager.renderSurvey(r, null == a ? void 0 : a.querySelector(t));
    });
  }
}
var Wn = H("[RateLimiter]");
class Vn {
  constructor(e) {
    var t, i;
    V(this, "serverLimits", {}), V(this, "lastEventRateLimited", !1), V(this, "checkForLimiting", e => {
      var t = e.text;
      if (t && t.length) try {
        (JSON.parse(t).quota_limited || []).forEach(e => {
          Wn.info("".concat(e || "events", " is quota limited.")), this.serverLimits[e] = new Date().getTime() + 6e4;
        });
      } catch (e) {
        return void Wn.warn('could not rate limit - continuing. Error: "'.concat(null == e ? void 0 : e.message, '"'), {
          text: t
        });
      }
    }), this.instance = e, this.captureEventsPerSecond = (null === (t = e.config.rate_limiting) || void 0 === t ? void 0 : t.events_per_second) || 10, this.captureEventsBurstLimit = Math.max((null === (i = e.config.rate_limiting) || void 0 === i ? void 0 : i.events_burst_limit) || 10 * this.captureEventsPerSecond, this.captureEventsPerSecond), this.lastEventRateLimited = this.clientRateLimitContext(!0).isRateLimited;
  }
  clientRateLimitContext() {
    var e,
      t,
      i,
      r = arguments.length > 0 && void 0 !== arguments[0] && arguments[0],
      s = new Date().getTime(),
      n = null !== (e = null === (t = this.instance.persistence) || void 0 === t ? void 0 : t.get_property(Ne)) && void 0 !== e ? e : {
        tokens: this.captureEventsBurstLimit,
        last: s
      };
    n.tokens += (s - n.last) / 1e3 * this.captureEventsPerSecond, n.last = s, n.tokens > this.captureEventsBurstLimit && (n.tokens = this.captureEventsBurstLimit);
    var o = n.tokens < 1;
    return o || r || (n.tokens = Math.max(0, n.tokens - 1)), !o || this.lastEventRateLimited || r || this.instance.capture("$$client_ingestion_warning", {
      $$client_ingestion_warning_message: "posthog-js client rate limited. Config is set to ".concat(this.captureEventsPerSecond, " events per second and ").concat(this.captureEventsBurstLimit, " events burst limit.")
    }, {
      skip_client_rate_limiting: !0
    }), this.lastEventRateLimited = o, null === (i = this.instance.persistence) || void 0 === i || i.set_property(Ne, n), {
      isRateLimited: o,
      remainingTokens: n.tokens
    };
  }
  isServerRateLimited(e) {
    var t = this.serverLimits[e || "events"] || !1;
    return !1 !== t && new Date().getTime() < t;
  }
}
var Gn = H("[RemoteConfig]");
class Jn {
  constructor(e) {
    this.instance = e;
  }
  get remoteConfig() {
    var e, t;
    return null === (e = _._POSTHOG_REMOTE_CONFIG) || void 0 === e || null === (t = e[this.instance.config.token]) || void 0 === t ? void 0 : t.config;
  }
  _loadRemoteConfigJs(e) {
    var t, i, r;
    null !== (t = _.__PosthogExtensions__) && void 0 !== t && t.loadExternalDependency ? null === (i = _.__PosthogExtensions__) || void 0 === i || null === (r = i.loadExternalDependency) || void 0 === r || r.call(i, this.instance, "remote-config", () => e(this.remoteConfig)) : (Gn.error("PostHog Extensions not found. Cannot load remote config."), e());
  }
  _loadRemoteConfigJSON(e) {
    this.instance._send_request({
      method: "GET",
      url: this.instance.requestRouter.endpointFor("assets", "/array/".concat(this.instance.config.token, "/config")),
      callback: t => {
        e(t.json);
      }
    });
  }
  load() {
    try {
      if (this.remoteConfig) return Gn.info("Using preloaded remote config", this.remoteConfig), void this.onRemoteConfig(this.remoteConfig);
      if (this.instance.config.advanced_disable_decide) return void Gn.warn("Remote config is disabled. Falling back to local config.");
      this._loadRemoteConfigJs(e => {
        if (!e) return Gn.info("No config found after loading remote JS config. Falling back to JSON."), void this._loadRemoteConfigJSON(e => {
          this.onRemoteConfig(e);
        });
        this.onRemoteConfig(e);
      });
    } catch (e) {
      Gn.error("Error loading remote config", e);
    }
  }
  onRemoteConfig(e) {
    e ? this.instance.config.__preview_remote_config ? (this.instance._onRemoteConfig(e), !1 !== e.hasFeatureFlags && this.instance.featureFlags.ensureFlagsLoaded()) : Gn.info("__preview_remote_config is disabled. Logging config instead", e) : Gn.error("Failed to fetch remote config from PostHog.");
  }
}
var Yn = function (e) {
    var t,
      i,
      r,
      s,
      n = "";
    for (t = i = 0, r = (e = (e + "").replace(/\r\n/g, "\n").replace(/\r/g, "\n")).length, s = 0; s < r; s++) {
      var o = e.charCodeAt(s),
        a = null;
      o < 128 ? i++ : a = o > 127 && o < 2048 ? String.fromCharCode(o >> 6 | 192, 63 & o | 128) : String.fromCharCode(o >> 12 | 224, o >> 6 & 63 | 128, 63 & o | 128), $(a) || (i > t && (n += e.substring(t, i)), n += a, t = i = s + 1);
    }
    return i > t && (n += e.substring(t, e.length)), n;
  },
  Kn = !!c || !!u,
  Xn = "text/plain",
  Qn = (e, t) => {
    var [i, r] = e.split("?"),
      s = W({}, t);
    null == r || r.split("&").forEach(e => {
      var [t] = e.split("=");
      delete s[t];
    });
    var n = Et(s);
    return n = n ? (r ? r + "&" : "") + n : r, "".concat(i, "?").concat(n);
  },
  Zn = (e, t) => JSON.stringify(e, (e, t) => "bigint" == typeof t ? t.toString() : t, t),
  eo = t => {
    var {
      data: i,
      compression: r
    } = t;
    if (i) {
      if (r === e.GZipJS) {
        var s = or(ar(Zn(i)), {
            mtime: 0
          }),
          n = new Blob([s], {
            type: Xn
          });
        return {
          contentType: Xn,
          body: n,
          estimatedSize: n.size
        };
      }
      if (r === e.Base64) {
        var o = function (e) {
            var t,
              i,
              r,
              s,
              n,
              o = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
              a = 0,
              l = 0,
              u = "",
              c = [];
            if (!e) return e;
            e = Yn(e);
            do {
              t = (n = e.charCodeAt(a++) << 16 | e.charCodeAt(a++) << 8 | e.charCodeAt(a++)) >> 18 & 63, i = n >> 12 & 63, r = n >> 6 & 63, s = 63 & n, c[l++] = o.charAt(t) + o.charAt(i) + o.charAt(r) + o.charAt(s);
            } while (a < e.length);
            switch (u = c.join(""), e.length % 3) {
              case 1:
                u = u.slice(0, -2) + "==";
                break;
              case 2:
                u = u.slice(0, -1) + "=";
            }
            return u;
          }(Zn(i)),
          a = (e => "data=" + encodeURIComponent("string" == typeof e ? e : Zn(e)))(o);
        return {
          contentType: "application/x-www-form-urlencoded",
          body: a,
          estimatedSize: new Blob([a]).size
        };
      }
      var l = Zn(i);
      return {
        contentType: "application/json",
        body: l,
        estimatedSize: new Blob([l]).size
      };
    }
  },
  to = [];
u && to.push({
  transport: "fetch",
  method: e => {
    var t,
      i,
      {
        contentType: r,
        body: s,
        estimatedSize: n
      } = null !== (t = eo(e)) && void 0 !== t ? t : {},
      o = new Headers();
    K(e.headers, function (e, t) {
      o.append(t, e);
    }), r && o.append("Content-Type", r);
    var a = e.url,
      l = null;
    if (d) {
      var c = new d();
      l = {
        signal: c.signal,
        timeout: setTimeout(() => c.abort(), e.timeout)
      };
    }
    u(a, W({
      method: (null == e ? void 0 : e.method) || "GET",
      headers: o,
      keepalive: "POST" === e.method && (n || 0) < 52428.8,
      body: s,
      signal: null === (i = l) || void 0 === i ? void 0 : i.signal
    }, e.fetchOptions)).then(t => t.text().then(i => {
      var r,
        s = {
          statusCode: t.status,
          text: i
        };
      if (200 === t.status) try {
        s.json = JSON.parse(i);
      } catch (e) {
        B.error(e);
      }
      null === (r = e.callback) || void 0 === r || r.call(e, s);
    })).catch(t => {
      var i;
      B.error(t), null === (i = e.callback) || void 0 === i || i.call(e, {
        statusCode: 0,
        text: t
      });
    }).finally(() => l ? clearTimeout(l.timeout) : null);
  }
}), c && to.push({
  transport: "XHR",
  method: e => {
    var t,
      i = new c();
    i.open(e.method || "GET", e.url, !0);
    var {
      contentType: r,
      body: s
    } = null !== (t = eo(e)) && void 0 !== t ? t : {};
    K(e.headers, function (e, t) {
      i.setRequestHeader(t, e);
    }), r && i.setRequestHeader("Content-Type", r), e.timeout && (i.timeout = e.timeout), i.withCredentials = !0, i.onreadystatechange = () => {
      if (4 === i.readyState) {
        var t,
          r = {
            statusCode: i.status,
            text: i.responseText
          };
        if (200 === i.status) try {
          r.json = JSON.parse(i.responseText);
        } catch (e) {}
        null === (t = e.callback) || void 0 === t || t.call(e, r);
      }
    }, i.send(s);
  }
}), null != o && o.sendBeacon && to.push({
  transport: "sendBeacon",
  method: e => {
    var t = Qn(e.url, {
      beacon: "1"
    });
    try {
      var i,
        {
          contentType: r,
          body: s
        } = null !== (i = eo(e)) && void 0 !== i ? i : {},
        n = "string" == typeof s ? new Blob([s], {
          type: r
        }) : s;
      o.sendBeacon(t, n);
    } catch (e) {}
  }
});
var io = 3e3;
class ro {
  constructor(e, t) {
    V(this, "isPaused", !0), V(this, "queue", []), this.flushTimeoutMs = Si((null == t ? void 0 : t.flush_interval_ms) || io, 250, 5e3, "flush interval", io), this.sendRequest = e;
  }
  enqueue(e) {
    this.queue.push(e), this.flushTimeout || this.setFlushTimeout();
  }
  unload() {
    this.clearFlushTimeout();
    var e = this.queue.length > 0 ? this.formatQueue() : {},
      t = Object.values(e),
      i = [...t.filter(e => 0 === e.url.indexOf("/e")), ...t.filter(e => 0 !== e.url.indexOf("/e"))];
    i.map(e => {
      this.sendRequest(W(W({}, e), {}, {
        transport: "sendBeacon"
      }));
    });
  }
  enable() {
    this.isPaused = !1, this.setFlushTimeout();
  }
  setFlushTimeout() {
    var e = this;
    this.isPaused || (this.flushTimeout = setTimeout(() => {
      if (this.clearFlushTimeout(), this.queue.length > 0) {
        var t = this.formatQueue(),
          i = function (i) {
            var r = t[i],
              s = new Date().getTime();
            r.data && x(r.data) && K(r.data, e => {
              e.offset = Math.abs(e.timestamp - s), delete e.timestamp;
            }), e.sendRequest(r);
          };
        for (var r in t) i(r);
      }
    }, this.flushTimeoutMs));
  }
  clearFlushTimeout() {
    clearTimeout(this.flushTimeout), this.flushTimeout = void 0;
  }
  formatQueue() {
    var e = {};
    return K(this.queue, t => {
      var i,
        r = t,
        s = (r ? r.batchKey : null) || r.url;
      F(e[s]) && (e[s] = W(W({}, r), {}, {
        data: []
      })), null === (i = e[s].data) || void 0 === i || i.push(r.data);
    }), this.queue = [], e;
  }
}
var so = ["retriesPerformedSoFar"];
class no {
  constructor(e) {
    V(this, "isPolling", !1), V(this, "pollIntervalMs", 3e3), V(this, "queue", []), this.instance = e, this.queue = [], this.areWeOnline = !0, !F(t) && "onLine" in t.navigator && (this.areWeOnline = t.navigator.onLine, ae(t, "online", () => {
      this.areWeOnline = !0, this.flush();
    }), ae(t, "offline", () => {
      this.areWeOnline = !1;
    }));
  }
  retriableRequest(e) {
    var {
        retriesPerformedSoFar: t
      } = e,
      i = G(e, so);
    A(t) && t > 0 && (i.url = Qn(i.url, {
      retry_count: t
    })), this.instance._send_request(W(W({}, i), {}, {
      callback: e => {
        var r;
        200 !== e.statusCode && (e.statusCode < 400 || e.statusCode >= 500) && (null != t ? t : 0) < 10 ? this.enqueue(W({
          retriesPerformedSoFar: t
        }, i)) : null === (r = i.callback) || void 0 === r || r.call(i, e);
      }
    }));
  }
  enqueue(e) {
    var t = e.retriesPerformedSoFar || 0;
    e.retriesPerformedSoFar = t + 1;
    var i = function (e) {
        var t = 3e3 * Math.pow(2, e),
          i = t / 2,
          r = Math.min(18e5, t),
          s = (Math.random() - .5) * (r - i);
        return Math.ceil(r + s);
      }(t),
      r = Date.now() + i;
    this.queue.push({
      retryAt: r,
      requestOptions: e
    });
    var s = "Enqueued failed request for retry in ".concat(i);
    navigator.onLine || (s += " (Browser is offline)"), B.warn(s), this.isPolling || (this.isPolling = !0, this.poll());
  }
  poll() {
    this.poller && clearTimeout(this.poller), this.poller = setTimeout(() => {
      this.areWeOnline && this.queue.length > 0 && this.flush(), this.poll();
    }, this.pollIntervalMs);
  }
  flush() {
    var e = Date.now(),
      t = [],
      i = this.queue.filter(i => i.retryAt < e || (t.push(i), !1));
    if (this.queue = t, i.length > 0) for (var {
      requestOptions: r
    } of i) this.retriableRequest(r);
  }
  unload() {
    for (var {
      requestOptions: e
    } of (this.poller && (clearTimeout(this.poller), this.poller = void 0), this.queue)) try {
      this.instance._send_request(W(W({}, e), {}, {
        transport: "sendBeacon"
      }));
    } catch (e) {
      B.error(e);
    }
    this.queue = [];
  }
}
class oo {
  constructor(e) {
    V(this, "_updateScrollData", () => {
      var e, t, i, r;
      this.context || (this.context = {});
      var s = this.scrollElement(),
        n = this.scrollY(),
        o = s ? Math.max(0, s.scrollHeight - s.clientHeight) : 0,
        a = n + ((null == s ? void 0 : s.clientHeight) || 0),
        l = (null == s ? void 0 : s.scrollHeight) || 0;
      this.context.lastScrollY = Math.ceil(n), this.context.maxScrollY = Math.max(n, null !== (e = this.context.maxScrollY) && void 0 !== e ? e : 0), this.context.maxScrollHeight = Math.max(o, null !== (t = this.context.maxScrollHeight) && void 0 !== t ? t : 0), this.context.lastContentY = a, this.context.maxContentY = Math.max(a, null !== (i = this.context.maxContentY) && void 0 !== i ? i : 0), this.context.maxContentHeight = Math.max(l, null !== (r = this.context.maxContentHeight) && void 0 !== r ? r : 0);
    }), this.instance = e;
  }
  getContext() {
    return this.context;
  }
  resetContext() {
    var e = this.context;
    return setTimeout(this._updateScrollData, 0), e;
  }
  startMeasuringScrollPosition() {
    ae(t, "scroll", this._updateScrollData, {
      capture: !0
    }), ae(t, "scrollend", this._updateScrollData, {
      capture: !0
    }), ae(t, "resize", this._updateScrollData);
  }
  scrollElement() {
    if (!this.instance.config.scroll_root_selector) return null == t ? void 0 : t.document.documentElement;
    var e = x(this.instance.config.scroll_root_selector) ? this.instance.config.scroll_root_selector : [this.instance.config.scroll_root_selector];
    for (var i of e) {
      var r = null == t ? void 0 : t.document.querySelector(i);
      if (r) return r;
    }
  }
  scrollY() {
    if (this.instance.config.scroll_root_selector) {
      var e = this.scrollElement();
      return e && e.scrollTop || 0;
    }
    return t && (t.scrollY || t.pageYOffset || t.document.documentElement.scrollTop) || 0;
  }
  scrollX() {
    if (this.instance.config.scroll_root_selector) {
      var e = this.scrollElement();
      return e && e.scrollLeft || 0;
    }
    return t && (t.scrollX || t.pageXOffset || t.document.documentElement.scrollLeft) || 0;
  }
}
var ao = e => Js.personInfo({
  maskPersonalDataProperties: null == e ? void 0 : e.config.mask_personal_data_properties,
  customPersonalDataProperties: null == e ? void 0 : e.config.custom_personal_data_properties
});
class lo {
  constructor(e, t, i, r) {
    V(this, "_onSessionIdCallback", e => {
      var t = this._getStored();
      if (!t || t.sessionId !== e) {
        var i = {
          sessionId: e,
          props: this._sessionSourceParamGenerator(this.instance)
        };
        this._persistence.register({
          [qe]: i
        });
      }
    }), this.instance = e, this._sessionIdManager = t, this._persistence = i, this._sessionSourceParamGenerator = r || ao, this._sessionIdManager.onSessionId(this._onSessionIdCallback);
  }
  _getStored() {
    return this._persistence.props[qe];
  }
  getSetOnceProps() {
    var e,
      t = null === (e = this._getStored()) || void 0 === e ? void 0 : e.props;
    return t ? "r" in t ? Js.personPropsFromInfo(t) : {
      $referring_domain: t.referringDomain,
      $pathname: t.initialPathName,
      utm_source: t.utm_source,
      utm_campaign: t.utm_campaign,
      utm_medium: t.utm_medium,
      utm_content: t.utm_content,
      utm_term: t.utm_term
    } : {};
  }
  getSessionProps() {
    var e = {};
    return K(ie(this.getSetOnceProps()), (t, i) => {
      "$current_url" === i && (i = "url"), e["$session_entry_".concat(b(i))] = t;
    }), e;
  }
}
var uo = H("[SessionId]");
class co {
  constructor(e, t, i) {
    var r;
    if (V(this, "_sessionIdChangedHandlers", []), !e.persistence) throw new Error("SessionIdManager requires a PostHogPersistence instance");
    if (e.config.__preview_experimental_cookieless_mode) throw new Error("SessionIdManager cannot be used with __preview_experimental_cookieless_mode");
    this.config = e.config, this.persistence = e.persistence, this._windowId = void 0, this._sessionId = void 0, this._sessionStartTimestamp = null, this._sessionActivityTimestamp = null, this._sessionIdGenerator = t || Bt, this._windowIdGenerator = i || Bt;
    var s = this.config.persistence_name || this.config.token,
      n = this.config.session_idle_timeout_seconds || 1800;
    if (this._sessionTimeoutMs = 1e3 * Si(n, 60, 36e3, "session_idle_timeout_seconds", 1800), e.register({
      $configured_session_timeout_ms: this._sessionTimeoutMs
    }), this.resetIdleTimer(), this._window_id_storage_key = "ph_" + s + "_window_id", this._primary_window_exists_storage_key = "ph_" + s + "_primary_window_exists", this._canUseSessionStorage()) {
      var o = ti.parse(this._window_id_storage_key),
        a = ti.parse(this._primary_window_exists_storage_key);
      o && !a ? this._windowId = o : ti.remove(this._window_id_storage_key), ti.set(this._primary_window_exists_storage_key, !0);
    }
    if (null !== (r = this.config.bootstrap) && void 0 !== r && r.sessionID) try {
      var l = (e => {
        var t = e.replace(/-/g, "");
        if (32 !== t.length) throw new Error("Not a valid UUID");
        if ("7" !== t[12]) throw new Error("Not a UUIDv7");
        return parseInt(t.substring(0, 12), 16);
      })(this.config.bootstrap.sessionID);
      this._setSessionId(this.config.bootstrap.sessionID, new Date().getTime(), l);
    } catch (e) {
      uo.error("Invalid sessionID in bootstrap", e);
    }
    this._listenToReloadWindow();
  }
  get sessionTimeoutMs() {
    return this._sessionTimeoutMs;
  }
  onSessionId(e) {
    return F(this._sessionIdChangedHandlers) && (this._sessionIdChangedHandlers = []), this._sessionIdChangedHandlers.push(e), this._sessionId && e(this._sessionId, this._windowId), () => {
      this._sessionIdChangedHandlers = this._sessionIdChangedHandlers.filter(t => t !== e);
    };
  }
  _canUseSessionStorage() {
    return "memory" !== this.config.persistence && !this.persistence.disabled && ti.is_supported();
  }
  _setWindowId(e) {
    e !== this._windowId && (this._windowId = e, this._canUseSessionStorage() && ti.set(this._window_id_storage_key, e));
  }
  _getWindowId() {
    return this._windowId ? this._windowId : this._canUseSessionStorage() ? ti.parse(this._window_id_storage_key) : null;
  }
  _setSessionId(e, t, i) {
    e === this._sessionId && t === this._sessionActivityTimestamp && i === this._sessionStartTimestamp || (this._sessionStartTimestamp = i, this._sessionActivityTimestamp = t, this._sessionId = e, this.persistence.register({
      [xe]: [t, e, i]
    }));
  }
  _getSessionId() {
    if (this._sessionId && this._sessionActivityTimestamp && this._sessionStartTimestamp) return [this._sessionActivityTimestamp, this._sessionId, this._sessionStartTimestamp];
    var e = this.persistence.props[xe];
    return x(e) && 2 === e.length && e.push(e[0]), e || [0, null, 0];
  }
  resetSessionId() {
    this._setSessionId(null, null, null);
  }
  _listenToReloadWindow() {
    ae(t, "beforeunload", () => {
      this._canUseSessionStorage() && ti.remove(this._primary_window_exists_storage_key);
    }, {
      capture: !1
    });
  }
  checkAndGetSessionAndWindowId() {
    var e = arguments.length > 0 && void 0 !== arguments[0] && arguments[0],
      t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : null;
    if (this.config.__preview_experimental_cookieless_mode) throw new Error("checkAndGetSessionAndWindowId should not be called in __preview_experimental_cookieless_mode");
    var i = t || new Date().getTime(),
      [r, s, n] = this._getSessionId(),
      o = this._getWindowId(),
      a = A(n) && n > 0 && Math.abs(i - n) > 864e5,
      l = !1,
      u = !s,
      c = !e && Math.abs(i - r) > this.sessionTimeoutMs;
    u || c || a ? (s = this._sessionIdGenerator(), o = this._windowIdGenerator(), uo.info("new session ID generated", {
      sessionId: s,
      windowId: o,
      changeReason: {
        noSessionId: u,
        activityTimeout: c,
        sessionPastMaximumLength: a
      }
    }), n = i, l = !0) : o || (o = this._windowIdGenerator(), l = !0);
    var d = 0 === r || !e || a ? i : r,
      h = 0 === n ? new Date().getTime() : n;
    return this._setWindowId(o), this._setSessionId(s, d, h), e || this.resetIdleTimer(), l && this._sessionIdChangedHandlers.forEach(e => e(s, o, l ? {
      noSessionId: u,
      activityTimeout: c,
      sessionPastMaximumLength: a
    } : void 0)), {
      sessionId: s,
      windowId: o,
      sessionStartTimestamp: h,
      changeReason: l ? {
        noSessionId: u,
        activityTimeout: c,
        sessionPastMaximumLength: a
      } : void 0,
      lastActivityTimestamp: r
    };
  }
  resetIdleTimer() {
    clearTimeout(this._enforceIdleTimeout), this._enforceIdleTimeout = setTimeout(() => {
      this.resetSessionId();
    }, 1.1 * this.sessionTimeoutMs);
  }
}
var ho = ["$set_once", "$set"],
  _o = H("[SiteApps]");
class po {
  constructor(e) {
    this.instance = e, this.bufferedInvocations = [], this.apps = {};
  }
  get isEnabled() {
    return !!this.instance.config.opt_in_site_apps;
  }
  eventCollector(e, t) {
    if (t) {
      var i = this.globalsForEvent(t);
      this.bufferedInvocations.push(i), this.bufferedInvocations.length > 1e3 && (this.bufferedInvocations = this.bufferedInvocations.slice(10));
    }
  }
  get siteAppLoaders() {
    var e, t;
    return null === (e = _._POSTHOG_REMOTE_CONFIG) || void 0 === e || null === (t = e[this.instance.config.token]) || void 0 === t ? void 0 : t.siteApps;
  }
  init() {
    if (this.isEnabled) {
      var e = this.instance._addCaptureHook(this.eventCollector.bind(this));
      this.stopBuffering = () => {
        e(), this.bufferedInvocations = [], this.stopBuffering = void 0;
      };
    }
  }
  globalsForEvent(e) {
    var t, i, r, s, n, o, a;
    if (!e) throw new Error("Event payload is required");
    var l = {},
      u = this.instance.get_property("$groups") || [],
      c = this.instance.get_property("$stored_group_properties") || {};
    for (var [d, h] of Object.entries(c)) l[d] = {
      id: u[d],
      type: d,
      properties: h
    };
    var {
      $set_once: _,
      $set: p
    } = e;
    return {
      event: W(W({}, G(e, ho)), {}, {
        properties: W(W(W({}, e.properties), p ? {
          $set: W(W({}, null !== (t = null === (i = e.properties) || void 0 === i ? void 0 : i.$set) && void 0 !== t ? t : {}), p)
        } : {}), _ ? {
          $set_once: W(W({}, null !== (r = null === (s = e.properties) || void 0 === s ? void 0 : s.$set_once) && void 0 !== r ? r : {}), _)
        } : {}),
        elements_chain: null !== (n = null === (o = e.properties) || void 0 === o ? void 0 : o.$elements_chain) && void 0 !== n ? n : "",
        distinct_id: null === (a = e.properties) || void 0 === a ? void 0 : a.distinct_id
      }),
      person: {
        properties: this.instance.get_property("$stored_person_properties")
      },
      groups: l
    };
  }
  setupSiteApp(e) {
    var t = this.apps[e.id],
      i = () => {
        var i;
        (!t.errored && this.bufferedInvocations.length && (_o.info("Processing ".concat(this.bufferedInvocations.length, " events for site app with id ").concat(e.id)), this.bufferedInvocations.forEach(e => {
          var i;
          return null === (i = t.processEvent) || void 0 === i ? void 0 : i.call(t, e);
        }), t.processedBuffer = !0), Object.values(this.apps).every(e => e.processedBuffer || e.errored)) && (null === (i = this.stopBuffering) || void 0 === i || i.call(this));
      },
      r = !1,
      s = s => {
        t.errored = !s, t.loaded = !0, _o.info("Site app with id ".concat(e.id, " ").concat(s ? "loaded" : "errored")), r && i();
      };
    try {
      var {
        processEvent: n
      } = e.init({
        posthog: this.instance,
        callback: e => {
          s(e);
        }
      });
      n && (t.processEvent = n), r = !0;
    } catch (t) {
      _o.error("Error while initializing PostHog app with config id ".concat(e.id), t), s(!1);
    }
    if (r && t.loaded) try {
      i();
    } catch (i) {
      _o.error("Error while processing buffered events PostHog app with config id ".concat(e.id), i), t.errored = !0;
    }
  }
  setupSiteApps() {
    var e = this.siteAppLoaders || [];
    for (var t of e) this.apps[t.id] = {
      id: t.id,
      loaded: !1,
      errored: !1,
      processedBuffer: !1
    };
    for (var i of e) this.setupSiteApp(i);
  }
  onCapturedEvent(e) {
    if (0 !== Object.keys(this.apps).length) {
      var t = this.globalsForEvent(e);
      for (var i of Object.values(this.apps)) try {
        var r;
        null === (r = i.processEvent) || void 0 === r || r.call(i, t);
      } catch (t) {
        _o.error("Error while processing event ".concat(e.event, " for site app ").concat(i.id), t);
      }
    }
  }
  onRemoteConfig(e) {
    var t,
      i,
      r,
      s = this;
    if (null !== (t = this.siteAppLoaders) && void 0 !== t && t.length) return this.isEnabled ? (this.setupSiteApps(), void this.instance.on("eventCaptured", e => this.onCapturedEvent(e))) : void _o.error('PostHog site apps are disabled. Enable the "opt_in_site_apps" config to proceed.');
    if (null === (i = this.stopBuffering) || void 0 === i || i.call(this), null !== (r = e.siteApps) && void 0 !== r && r.length) if (this.isEnabled) {
      var n = function (e, t) {
        var i, r;
        _["__$$ph_site_app_".concat(e)] = s.instance, null === (i = _.__PosthogExtensions__) || void 0 === i || null === (r = i.loadSiteApp) || void 0 === r || r.call(i, s.instance, t, t => {
          if (t) return _o.error("Error while initializing PostHog app with config id ".concat(e), t);
        });
      };
      for (var {
        id: o,
        url: a
      } of e.siteApps) n(o, a);
    } else _o.error('PostHog site apps are disabled. Enable the "opt_in_site_apps" config to proceed.');
  }
}
var vo,
  go = ["amazonbot", "amazonproductbot", "app.hypefactors.com", "applebot", "archive.org_bot", "awariobot", "backlinksextendedbot", "baiduspider", "bingbot", "bingpreview", "chrome-lighthouse", "dataforseobot", "deepscan", "duckduckbot", "facebookexternal", "facebookcatalog", "http://yandex.com/bots", "hubspot", "ia_archiver", "linkedinbot", "meta-externalagent", "mj12bot", "msnbot", "nessus", "petalbot", "pinterest", "prerender", "rogerbot", "screaming frog", "sebot-wa", "sitebulb", "slackbot", "slurp", "trendictionbot", "turnitin", "twitterbot", "vercelbot", "yahoo! slurp", "yandexbot", "zoombot", "bot.htm", "bot.php", "(bot;", "bot/", "crawler", "ahrefsbot", "ahrefssiteaudit", "semrushbot", "siteauditbot", "splitsignalbot", "gptbot", "oai-searchbot", "chatgpt-user", "perplexitybot", "better uptime bot", "sentryuptimebot", "uptimerobot", "headlesschrome", "cypress", "google-hoteladsverifier", "adsbot-google", "apis-google", "duplexweb-google", "feedfetcher-google", "google favicon", "google web preview", "google-read-aloud", "googlebot", "googleweblight", "mediapartners-google", "storebot-google", "bytespider"],
  fo = function (e, t) {
    if (!e) return !1;
    var i = e.toLowerCase();
    return go.concat(t || []).some(e => {
      var t = e.toLowerCase();
      return -1 !== i.indexOf(t);
    });
  },
  mo = function (e, t) {
    if (!e) return !1;
    var i = e.userAgent;
    if (i && fo(i, t)) return !0;
    try {
      var r = null == e ? void 0 : e.userAgentData;
      if (null != r && r.brands && r.brands.some(e => fo(null == e ? void 0 : e.brand, t))) return !0;
    } catch (e) {}
    return !!e.webdriver;
  };
function yo(e, t, i) {
  return Zn({
    distinct_id: e,
    userPropertiesToSet: t,
    userPropertiesToSetOnce: i
  });
}
!function (e) {
  e.US = "us", e.EU = "eu", e.CUSTOM = "custom";
}(vo || (vo = {}));
var bo = "i.posthog.com";
class wo {
  constructor(e) {
    V(this, "_regionCache", {}), this.instance = e;
  }
  get apiHost() {
    var e = this.instance.config.api_host.trim().replace(/\/$/, "");
    return "https://app.posthog.com" === e ? "https://us.i.posthog.com" : e;
  }
  get uiHost() {
    var e,
      t = null === (e = this.instance.config.ui_host) || void 0 === e ? void 0 : e.replace(/\/$/, "");
    return t || (t = this.apiHost.replace(".".concat(bo), ".posthog.com")), "https://app.posthog.com" === t ? "https://us.posthog.com" : t;
  }
  get region() {
    return this._regionCache[this.apiHost] || (/https:\/\/(app|us|us-assets)(\.i)?\.posthog\.com/i.test(this.apiHost) ? this._regionCache[this.apiHost] = vo.US : /https:\/\/(eu|eu-assets)(\.i)?\.posthog\.com/i.test(this.apiHost) ? this._regionCache[this.apiHost] = vo.EU : this._regionCache[this.apiHost] = vo.CUSTOM), this._regionCache[this.apiHost];
  }
  endpointFor(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "";
    if (t && (t = "/" === t[0] ? t : "/".concat(t)), "ui" === e) return this.uiHost + t;
    if (this.region === vo.CUSTOM) return this.apiHost + t;
    var i = bo + t;
    switch (e) {
      case "assets":
        return "https://".concat(this.region, "-assets.").concat(i);
      case "api":
        return "https://".concat(this.region, ".").concat(i);
    }
  }
}
var So = {
  icontains: (e, i) => !!t && i.href.toLowerCase().indexOf(e.toLowerCase()) > -1,
  not_icontains: (e, i) => !!t && -1 === i.href.toLowerCase().indexOf(e.toLowerCase()),
  regex: (e, i) => !!t && Dn(i.href, e),
  not_regex: (e, i) => !!t && !Dn(i.href, e),
  exact: (e, t) => t.href === e,
  is_not: (e, t) => t.href !== e
};
class ko {
  constructor(e) {
    var t = this;
    V(this, "getWebExperimentsAndEvaluateDisplayLogic", function () {
      var e = arguments.length > 0 && void 0 !== arguments[0] && arguments[0];
      t.getWebExperiments(e => {
        ko.logInfo("retrieved web experiments from the server"), t._flagToExperiments = new Map(), e.forEach(e => {
          if (e.feature_flag_key) {
            var i;
            if (t._flagToExperiments) ko.logInfo("setting flag key ", e.feature_flag_key, " to web experiment ", e), null === (i = t._flagToExperiments) || void 0 === i || i.set(e.feature_flag_key, e);
            var r = t.instance.getFeatureFlag(e.feature_flag_key);
            T(r) && e.variants[r] && t.applyTransforms(e.name, r, e.variants[r].transforms);
          } else if (e.variants) for (var s in e.variants) {
            var n = e.variants[s];
            ko.matchesTestVariant(n) && t.applyTransforms(e.name, s, n.transforms);
          }
        });
      }, e);
    }), this.instance = e, this.instance.onFeatureFlags(e => {
      this.onFeatureFlags(e);
    });
  }
  onFeatureFlags(e) {
    if (this._is_bot()) ko.logInfo("Refusing to render web experiment since the viewer is a likely bot");else if (!this.instance.config.disable_web_experiments) {
      if (O(this._flagToExperiments)) return this._flagToExperiments = new Map(), this.loadIfEnabled(), void this.previewWebExperiment();
      ko.logInfo("applying feature flags", e), e.forEach(e => {
        var t;
        if (this._flagToExperiments && null !== (t = this._flagToExperiments) && void 0 !== t && t.has(e)) {
          var i,
            r = this.instance.getFeatureFlag(e),
            s = null === (i = this._flagToExperiments) || void 0 === i ? void 0 : i.get(e);
          r && null != s && s.variants[r] && this.applyTransforms(s.name, r, s.variants[r].transforms);
        }
      });
    }
  }
  previewWebExperiment() {
    var e = ko.getWindowLocation();
    if (null != e && e.search) {
      var t = xt(null == e ? void 0 : e.search, "__experiment_id"),
        i = xt(null == e ? void 0 : e.search, "__experiment_variant");
      t && i && (ko.logInfo("previewing web experiments ".concat(t, " && ").concat(i)), this.getWebExperiments(e => {
        this.showPreviewWebExperiment(parseInt(t), i, e);
      }, !1, !0));
    }
  }
  loadIfEnabled() {
    this.instance.config.disable_web_experiments || this.getWebExperimentsAndEvaluateDisplayLogic();
  }
  getWebExperiments(e, t, i) {
    if (this.instance.config.disable_web_experiments && !i) return e([]);
    var r = this.instance.get_property("$web_experiments");
    if (r && !t) return e(r);
    this.instance._send_request({
      url: this.instance.requestRouter.endpointFor("api", "/api/web_experiments/?token=".concat(this.instance.config.token)),
      method: "GET",
      callback: t => {
        if (200 !== t.statusCode || !t.json) return e([]);
        var i = t.json.experiments || [];
        return e(i);
      }
    });
  }
  showPreviewWebExperiment(e, t, i) {
    var r = i.filter(t => t.id === e);
    r && r.length > 0 && (ko.logInfo("Previewing web experiment [".concat(r[0].name, "] with variant [").concat(t, "]")), this.applyTransforms(r[0].name, t, r[0].variants[t].transforms));
  }
  static matchesTestVariant(e) {
    return !O(e.conditions) && ko.matchUrlConditions(e) && ko.matchUTMConditions(e);
  }
  static matchUrlConditions(e) {
    var t;
    if (O(e.conditions) || O(null === (t = e.conditions) || void 0 === t ? void 0 : t.url)) return !0;
    var i,
      r,
      s,
      n = ko.getWindowLocation();
    return !!n && (null === (i = e.conditions) || void 0 === i || !i.url || So[null !== (r = null === (s = e.conditions) || void 0 === s ? void 0 : s.urlMatchType) && void 0 !== r ? r : "icontains"](e.conditions.url, n));
  }
  static getWindowLocation() {
    return null == t ? void 0 : t.location;
  }
  static matchUTMConditions(e) {
    var t;
    if (O(e.conditions) || O(null === (t = e.conditions) || void 0 === t ? void 0 : t.utm)) return !0;
    var i = Js.campaignParams();
    if (i.utm_source) {
      var r,
        s,
        n,
        o,
        a,
        l,
        u,
        c,
        d,
        h,
        _,
        p,
        v,
        g,
        f,
        m,
        y = null === (r = e.conditions) || void 0 === r || null === (s = r.utm) || void 0 === s || !s.utm_campaign || (null === (n = e.conditions) || void 0 === n || null === (o = n.utm) || void 0 === o ? void 0 : o.utm_campaign) == i.utm_campaign,
        b = null === (a = e.conditions) || void 0 === a || null === (l = a.utm) || void 0 === l || !l.utm_source || (null === (u = e.conditions) || void 0 === u || null === (c = u.utm) || void 0 === c ? void 0 : c.utm_source) == i.utm_source,
        w = null === (d = e.conditions) || void 0 === d || null === (h = d.utm) || void 0 === h || !h.utm_medium || (null === (_ = e.conditions) || void 0 === _ || null === (p = _.utm) || void 0 === p ? void 0 : p.utm_medium) == i.utm_medium,
        S = null === (v = e.conditions) || void 0 === v || null === (g = v.utm) || void 0 === g || !g.utm_term || (null === (f = e.conditions) || void 0 === f || null === (m = f.utm) || void 0 === m ? void 0 : m.utm_term) == i.utm_term;
      return y && w && S && b;
    }
    return !1;
  }
  static logInfo(e) {
    for (var t = arguments.length, i = new Array(t > 1 ? t - 1 : 0), r = 1; r < t; r++) i[r - 1] = arguments[r];
    B.info("[WebExperiments] ".concat(e), i);
  }
  applyTransforms(e, t, i) {
    this._is_bot() ? ko.logInfo("Refusing to render web experiment since the viewer is a likely bot") : "control" !== t ? i.forEach(i => {
      if (i.selector) {
        var r;
        ko.logInfo("applying transform of variant ".concat(t, " for experiment ").concat(e, " "), i);
        var s = null === (r = document) || void 0 === r ? void 0 : r.querySelectorAll(i.selector);
        null == s || s.forEach(e => {
          var t = e;
          i.html && (t.innerHTML = i.html), i.css && t.setAttribute("style", i.css);
        });
      }
    }) : ko.logInfo("Control variants leave the page unmodified.");
  }
  _is_bot() {
    return o && this.instance ? mo(o, this.instance.config.custom_blocked_useragents) : void 0;
  }
}
var Eo = {},
  xo = () => {},
  Io = "posthog",
  Po = !Kn && -1 === (null == h ? void 0 : h.indexOf("MSIE")) && -1 === (null == h ? void 0 : h.indexOf("Mozilla")),
  Co = () => {
    var e;
    return {
      api_host: "https://us.i.posthog.com",
      ui_host: null,
      token: "",
      autocapture: !0,
      rageclick: !0,
      cross_subdomain_cookie: ne(null == a ? void 0 : a.location),
      persistence: "localStorage+cookie",
      persistence_name: "",
      loaded: xo,
      save_campaign_params: !0,
      custom_campaign_params: [],
      custom_blocked_useragents: [],
      save_referrer: !0,
      capture_pageview: !0,
      capture_pageleave: "if_capture_pageview",
      debug: l && T(null == l ? void 0 : l.search) && -1 !== l.search.indexOf("__posthog_debug=true") || !1,
      cookie_expiration: 365,
      upgrade: !1,
      disable_session_recording: !1,
      disable_persistence: !1,
      disable_web_experiments: !0,
      disable_surveys: !1,
      disable_external_dependency_loading: !1,
      enable_recording_console_log: void 0,
      secure_cookie: "https:" === (null == t || null === (e = t.location) || void 0 === e ? void 0 : e.protocol),
      ip: !0,
      opt_out_capturing_by_default: !1,
      opt_out_persistence_by_default: !1,
      opt_out_useragent_filter: !1,
      opt_out_capturing_persistence_type: "localStorage",
      opt_out_capturing_cookie_prefix: null,
      opt_in_site_apps: !1,
      property_denylist: [],
      respect_dnt: !1,
      sanitize_properties: null,
      request_headers: {},
      request_batching: !0,
      properties_string_max_length: 65535,
      session_recording: {},
      mask_all_element_attributes: !1,
      mask_all_text: !1,
      mask_personal_data_properties: !1,
      custom_personal_data_properties: [],
      advanced_disable_decide: !1,
      advanced_disable_feature_flags: !1,
      advanced_disable_feature_flags_on_first_load: !1,
      advanced_disable_toolbar_metrics: !1,
      feature_flag_request_timeout_ms: 3e3,
      surveys_request_timeout_ms: 1e4,
      on_request_error: e => {
        var t = "Bad HTTP status: " + e.statusCode + " " + e.text;
        B.error(t);
      },
      get_device_id: e => e,
      capture_performance: void 0,
      name: "posthog",
      bootstrap: {},
      disable_compression: !1,
      session_idle_timeout_seconds: 1800,
      person_profiles: "identified_only",
      before_send: void 0,
      request_queue_config: {
        flush_interval_ms: io
      },
      _onCapture: xo
    };
  },
  Fo = e => {
    var t = {};
    F(e.process_person) || (t.person_profiles = e.process_person), F(e.xhr_headers) || (t.request_headers = e.xhr_headers), F(e.cookie_name) || (t.persistence_name = e.cookie_name), F(e.disable_cookie) || (t.disable_persistence = e.disable_cookie), F(e.store_google) || (t.save_campaign_params = e.store_google), F(e.verbose) || (t.debug = e.verbose);
    var i = X({}, t, e);
    return x(e.property_blacklist) && (F(e.property_denylist) ? i.property_denylist = e.property_blacklist : x(e.property_denylist) ? i.property_denylist = [...e.property_blacklist, ...e.property_denylist] : B.error("Invalid value for property_denylist config: " + e.property_denylist)), i;
  };
class To {
  constructor() {
    V(this, "__forceAllowLocalhost", !1);
  }
  get _forceAllowLocalhost() {
    return this.__forceAllowLocalhost;
  }
  set _forceAllowLocalhost(e) {
    B.error("WebPerformanceObserver is deprecated and has no impact on network capture. Use `_forceAllowLocalhostNetworkCapture` on `posthog.sessionRecording`"), this.__forceAllowLocalhost = e;
  }
}
class Ro {
  get decideEndpointWasHit() {
    var e, t;
    return null !== (e = null === (t = this.featureFlags) || void 0 === t ? void 0 : t.hasLoadedFlags) && void 0 !== e && e;
  }
  constructor() {
    V(this, "webPerformance", new To()), V(this, "_personProcessingSetOncePropertiesSent", !1), V(this, "version", p.LIB_VERSION), V(this, "_internalEventEmitter", new qn()), this.config = Co(), this.SentryIntegration = kr, this.sentryIntegration = e => function (e, t) {
      var i = Sr(e, t);
      return {
        name: wr,
        processEvent: e => i(e)
      };
    }(this, e), this.__request_queue = [], this.__loaded = !1, this.analyticsDefaultEndpoint = "/e/", this._initialPageviewCaptured = !1, this._initialPersonProfilesConfig = null, this._cachedIdentify = null, this.featureFlags = new Jr(this), this.toolbar = new Cr(this), this.scrollManager = new oo(this), this.pageViewManager = new Dr(this), this.surveys = new jn(this), this.experiments = new ko(this), this.exceptions = new qr(this), this.rateLimiter = new Vn(this), this.requestRouter = new wo(this), this.consent = new ii(this), this.people = {
      set: (e, t, i) => {
        var r = T(e) ? {
          [e]: t
        } : e;
        this.setPersonProperties(r), null == i || i({});
      },
      set_once: (e, t, i) => {
        var r = T(e) ? {
          [e]: t
        } : e;
        this.setPersonProperties(void 0, r), null == i || i({});
      }
    }, this.on("eventCaptured", e => B.info('send "'.concat(null == e ? void 0 : e.event, '"'), e));
  }
  init(e, t, i) {
    if (i && i !== Io) {
      var r,
        s = null !== (r = Eo[i]) && void 0 !== r ? r : new Ro();
      return s._init(e, t, i), Eo[i] = s, Eo[Io][i] = s, s;
    }
    return this._init(e, t, i);
  }
  _init(i) {
    var r,
      s,
      n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
      o = arguments.length > 2 ? arguments[2] : void 0;
    if (F(i) || R(i)) return B.critical("PostHog was initialized without a token. This likely indicates a misconfiguration. Please check the first argument passed to posthog.init()"), this;
    if (this.__loaded) return B.warn("You have already initialized PostHog! Re-initializing is a no-op"), this;
    this.__loaded = !0, this.config = {}, this._triggered_notifs = [], n.person_profiles && (this._initialPersonProfilesConfig = n.person_profiles), this.set_config(X({}, Co(), Fo(n), {
      name: o,
      token: i
    })), this.config.on_xhr_error && B.error("on_xhr_error is deprecated. Use on_request_error instead"), this.compression = n.disable_compression ? void 0 : e.GZipJS, this.persistence = new Ks(this.config), this.sessionPersistence = "sessionStorage" === this.config.persistence || "memory" === this.config.persistence ? this.persistence : new Ks(W(W({}, this.config), {}, {
      persistence: "sessionStorage"
    }));
    var a = W({}, this.persistence.props),
      l = W({}, this.sessionPersistence.props);
    if (this._requestQueue = new ro(e => this._send_retriable_request(e), this.config.request_queue_config), this._retryQueue = new no(this), this.__request_queue = [], this.config.__preview_experimental_cookieless_mode || (this.sessionManager = new co(this), this.sessionPropsManager = new lo(this, this.sessionManager, this.persistence)), new Tr(this).startIfEnabledOrStop(), this.siteApps = new po(this), null === (r = this.siteApps) || void 0 === r || r.init(), this.config.__preview_experimental_cookieless_mode || (this.sessionRecording = new mr(this), this.sessionRecording.startIfEnabledOrStop()), this.config.disable_scroll_properties || this.scrollManager.startMeasuringScrollPosition(), this.autocapture = new Ot(this), this.autocapture.startIfEnabled(), this.surveys.loadIfEnabled(), this.heatmaps = new Lr(this), this.heatmaps.startIfEnabled(), this.webVitalsAutocapture = new Or(this), this.exceptionObserver = new li(this), this.exceptionObserver.startIfEnabled(), this.deadClicksAutocapture = new oi(this, ni), this.deadClicksAutocapture.startIfEnabled(), p.DEBUG = p.DEBUG || this.config.debug, p.DEBUG && B.info("Starting in debug mode", {
      this: this,
      config: n,
      thisC: W({}, this.config),
      p: a,
      s: l
    }), this._sync_opt_out_with_persistence(), void 0 !== (null === (s = n.bootstrap) || void 0 === s ? void 0 : s.distinctID)) {
      var u,
        c,
        d = this.config.get_device_id(Bt()),
        h = null !== (u = n.bootstrap) && void 0 !== u && u.isIdentifiedID ? d : n.bootstrap.distinctID;
      this.persistence.set_property(De, null !== (c = n.bootstrap) && void 0 !== c && c.isIdentifiedID ? "identified" : "anonymous"), this.register({
        distinct_id: n.bootstrap.distinctID,
        $device_id: h
      });
    }
    if (this._hasBootstrappedFeatureFlags()) {
      var _,
        v,
        g = Object.keys((null === (_ = n.bootstrap) || void 0 === _ ? void 0 : _.featureFlags) || {}).filter(e => {
          var t, i;
          return !(null === (t = n.bootstrap) || void 0 === t || null === (i = t.featureFlags) || void 0 === i || !i[e]);
        }).reduce((e, t) => {
          var i, r;
          return e[t] = (null === (i = n.bootstrap) || void 0 === i || null === (r = i.featureFlags) || void 0 === r ? void 0 : r[t]) || !1, e;
        }, {}),
        f = Object.keys((null === (v = n.bootstrap) || void 0 === v ? void 0 : v.featureFlagPayloads) || {}).filter(e => g[e]).reduce((e, t) => {
          var i, r, s, o;
          null !== (i = n.bootstrap) && void 0 !== i && null !== (r = i.featureFlagPayloads) && void 0 !== r && r[t] && (e[t] = null === (s = n.bootstrap) || void 0 === s || null === (o = s.featureFlagPayloads) || void 0 === o ? void 0 : o[t]);
          return e;
        }, {});
      this.featureFlags.receivedFeatureFlags({
        featureFlags: g,
        featureFlagPayloads: f
      });
    }
    if (this.config.__preview_experimental_cookieless_mode) this.register_once({
      distinct_id: We,
      $device_id: null
    }, "");else if (!this.get_distinct_id()) {
      var m = this.config.get_device_id(Bt());
      this.register_once({
        distinct_id: m,
        $device_id: m
      }, ""), this.persistence.set_property(De, "anonymous");
    }
    return ae(t, "onpagehide" in self ? "pagehide" : "unload", this._handle_unload.bind(this), {
      passive: !1
    }), this.toolbar.maybeLoadToolbar(), n.segment ? br(this, () => this._loaded()) : this._loaded(), I(this.config._onCapture) && this.config._onCapture !== xo && (B.warn("onCapture is deprecated. Please use `before_send` instead"), this.on("eventCaptured", e => this.config._onCapture(e.event, e))), this;
  }
  _onRemoteConfig(t) {
    var i, r, s, n, o, l, u, c;
    if (!a || !a.body) return B.info("document not ready yet, trying again in 500 milliseconds..."), void setTimeout(() => {
      this._onRemoteConfig(t);
    }, 500);
    this.compression = void 0, t.supportedCompression && !this.config.disable_compression && (this.compression = m(t.supportedCompression, e.GZipJS) ? e.GZipJS : m(t.supportedCompression, e.Base64) ? e.Base64 : void 0), null !== (i = t.analytics) && void 0 !== i && i.endpoint && (this.analyticsDefaultEndpoint = t.analytics.endpoint), this.set_config({
      person_profiles: this._initialPersonProfilesConfig ? this._initialPersonProfilesConfig : "identified_only"
    }), null === (r = this.siteApps) || void 0 === r || r.onRemoteConfig(t), null === (s = this.sessionRecording) || void 0 === s || s.onRemoteConfig(t), null === (n = this.autocapture) || void 0 === n || n.onRemoteConfig(t), null === (o = this.heatmaps) || void 0 === o || o.onRemoteConfig(t), this.surveys.onRemoteConfig(t), null === (l = this.webVitalsAutocapture) || void 0 === l || l.onRemoteConfig(t), null === (u = this.exceptionObserver) || void 0 === u || u.onRemoteConfig(t), null === (c = this.deadClicksAutocapture) || void 0 === c || c.onRemoteConfig(t);
  }
  _loaded() {
    try {
      this.config.loaded(this);
    } catch (e) {
      B.critical("`loaded` function failed", e);
    }
    this._start_queue_if_opted_in(), this.config.capture_pageview && setTimeout(() => {
      this.consent.isOptedIn() && this._captureInitialPageview();
    }, 1), new Jn(this).load(), this.featureFlags.decide();
  }
  _start_queue_if_opted_in() {
    var e;
    this.has_opted_out_capturing() || this.config.request_batching && (null === (e = this._requestQueue) || void 0 === e || e.enable());
  }
  _dom_loaded() {
    this.has_opted_out_capturing() || Y(this.__request_queue, e => this._send_retriable_request(e)), this.__request_queue = [], this._start_queue_if_opted_in();
  }
  _handle_unload() {
    var e, t;
    this.config.request_batching ? (this._shouldCapturePageleave() && this.capture("$pageleave"), null === (e = this._requestQueue) || void 0 === e || e.unload(), null === (t = this._retryQueue) || void 0 === t || t.unload()) : this._shouldCapturePageleave() && this.capture("$pageleave", null, {
      transport: "sendBeacon"
    });
  }
  _send_request(e) {
    this.__loaded && (Po ? this.__request_queue.push(e) : this.rateLimiter.isServerRateLimited(e.batchKey) || (e.transport = e.transport || this.config.api_transport, e.url = Qn(e.url, {
      ip: this.config.ip ? 1 : 0
    }), e.headers = W({}, this.config.request_headers), e.compression = "best-available" === e.compression ? this.compression : e.compression, e.fetchOptions = e.fetchOptions || this.config.fetch_options, (e => {
      var t,
        i,
        r,
        s = W({}, e);
      s.timeout = s.timeout || 6e4, s.url = Qn(s.url, {
        _: new Date().getTime().toString(),
        ver: p.LIB_VERSION,
        compression: s.compression
      });
      var n = null !== (t = s.transport) && void 0 !== t ? t : "fetch",
        o = null !== (i = null === (r = oe(to, e => e.transport === n)) || void 0 === r ? void 0 : r.method) && void 0 !== i ? i : to[0].method;
      if (!o) throw new Error("No available transport method");
      o(s);
    })(W(W({}, e), {}, {
      callback: t => {
        var i, r, s;
        (this.rateLimiter.checkForLimiting(t), t.statusCode >= 400) && (null === (r = (s = this.config).on_request_error) || void 0 === r || r.call(s, t));
        null === (i = e.callback) || void 0 === i || i.call(e, t);
      }
    }))));
  }
  _send_retriable_request(e) {
    this._retryQueue ? this._retryQueue.retriableRequest(e) : this._send_request(e);
  }
  _execute_array(e) {
    var t,
      i = [],
      r = [],
      s = [];
    Y(e, e => {
      e && (t = e[0], x(t) ? s.push(e) : I(e) ? e.call(this) : x(e) && "alias" === t ? i.push(e) : x(e) && -1 !== t.indexOf("capture") && I(this[t]) ? s.push(e) : r.push(e));
    });
    var n = function (e, t) {
      Y(e, function (e) {
        if (x(e[0])) {
          var i = t;
          K(e, function (e) {
            i = i[e[0]].apply(i, e.slice(1));
          });
        } else this[e[0]].apply(this, e.slice(1));
      }, t);
    };
    n(i, this), n(r, this), n(s, this);
  }
  _hasBootstrappedFeatureFlags() {
    var e, t;
    return (null === (e = this.config.bootstrap) || void 0 === e ? void 0 : e.featureFlags) && Object.keys(null === (t = this.config.bootstrap) || void 0 === t ? void 0 : t.featureFlags).length > 0 || !1;
  }
  push(e) {
    this._execute_array([e]);
  }
  capture(e, t, i) {
    var r;
    if (this.__loaded && this.persistence && this.sessionPersistence && this._requestQueue) {
      if (!this.consent.isOptedOut()) if (!F(e) && T(e)) {
        if (this.config.opt_out_useragent_filter || !this._is_bot()) {
          var s = null != i && i.skip_client_rate_limiting ? void 0 : this.rateLimiter.clientRateLimitContext();
          if (null == s || !s.isRateLimited) {
            null != t && t.$current_url && !T(null == t ? void 0 : t.$current_url) && (B.error("Invalid `$current_url` property provided to `posthog.capture`. Input must be a string. Ignoring provided value."), null == t || delete t.$current_url), this.sessionPersistence.update_search_keyword(), this.config.save_campaign_params && this.sessionPersistence.update_campaign_params(), this.config.save_referrer && this.sessionPersistence.update_referrer_info(), (this.config.save_campaign_params || this.config.save_referrer) && this.persistence.set_initial_person_info();
            var n = new Date(),
              o = (null == i ? void 0 : i.timestamp) || n,
              a = Bt(),
              l = {
                uuid: a,
                event: e,
                properties: this._calculate_event_properties(e, t || {}, o, a)
              };
            s && (l.properties.$lib_rate_limit_remaining_tokens = s.remainingTokens), (null == i ? void 0 : i.$set) && (l.$set = null == i ? void 0 : i.$set);
            var u = this._calculate_set_once_properties(null == i ? void 0 : i.$set_once);
            u && (l.$set_once = u), (l = re(l, null != i && i._noTruncate ? null : this.config.properties_string_max_length)).timestamp = o, F(null == i ? void 0 : i.timestamp) || (l.properties.$event_time_override_provided = !0, l.properties.$event_time_override_system_time = n);
            var c = W(W({}, l.properties.$set), l.$set);
            if (C(c) || this.setPersonPropertiesForFlags(c), !O(this.config.before_send)) {
              var d = this._runBeforeSend(l);
              if (!d) return;
              l = d;
            }
            this._internalEventEmitter.emit("eventCaptured", l);
            var h = {
              method: "POST",
              url: null !== (r = null == i ? void 0 : i._url) && void 0 !== r ? r : this.requestRouter.endpointFor("api", this.analyticsDefaultEndpoint),
              data: l,
              compression: "best-available",
              batchKey: null == i ? void 0 : i._batchKey
            };
            return !this.config.request_batching || i && (null == i || !i._batchKey) || null != i && i.send_instantly ? this._send_retriable_request(h) : this._requestQueue.enqueue(h), l;
          }
          B.critical("This capture call is ignored due to client rate limiting.");
        }
      } else B.error("No event name provided to posthog.capture");
    } else B.uninitializedWarning("posthog.capture");
  }
  _addCaptureHook(e) {
    return this.on("eventCaptured", t => e(t.event, t));
  }
  _calculate_event_properties(e, t, i, r) {
    if (i = i || new Date(), !this.persistence || !this.sessionPersistence) return t;
    var s = this.persistence.remove_event_timer(e),
      n = W({}, t);
    if (n.token = this.config.token, this.config.__preview_experimental_cookieless_mode && (n.$cookieless_mode = !0), "$snapshot" === e) {
      var o = W(W({}, this.persistence.properties()), this.sessionPersistence.properties());
      return n.distinct_id = o.distinct_id, (!T(n.distinct_id) && !A(n.distinct_id) || R(n.distinct_id)) && B.error("Invalid distinct_id for replay event. This indicates a bug in your implementation"), n;
    }
    var l,
      u = Js.properties({
        maskPersonalDataProperties: this.config.mask_personal_data_properties,
        customPersonalDataProperties: this.config.custom_personal_data_properties
      });
    if (this.sessionManager) {
      var {
        sessionId: c,
        windowId: d
      } = this.sessionManager.checkAndGetSessionAndWindowId();
      n.$session_id = c, n.$window_id = d;
    }
    this.sessionPropsManager && X(n, this.sessionPropsManager.getSessionProps());
    try {
      var _, p;
      this.sessionRecording && (n.$recording_status = this.sessionRecording.status, n.$sdk_debug_replay_internal_buffer_length = this.sessionRecording.buffer.data.length, n.$sdk_debug_replay_internal_buffer_size = this.sessionRecording.buffer.size), n.$sdk_debug_retry_queue_size = null === (_ = this._retryQueue) || void 0 === _ || null === (p = _.queue) || void 0 === p ? void 0 : p.length;
    } catch (e) {
      n.$sdk_debug_error_capturing_properties = String(e);
    }
    if (this.requestRouter.region === vo.CUSTOM && (n.$lib_custom_api_host = this.config.api_host), l = "$pageview" === e ? this.pageViewManager.doPageView(i, r) : "$pageleave" === e ? this.pageViewManager.doPageLeave(i) : this.pageViewManager.doEvent(), n = X(n, l), "$pageview" === e && a && (n.title = a.title), !F(s)) {
      var v = i.getTime() - s;
      n.$duration = parseFloat((v / 1e3).toFixed(3));
    }
    h && this.config.opt_out_useragent_filter && (n.$browser_type = this._is_bot() ? "bot" : "browser"), (n = X({}, u, this.persistence.properties(), this.sessionPersistence.properties(), n)).$is_identified = this._isIdentified(), x(this.config.property_denylist) ? K(this.config.property_denylist, function (e) {
      delete n[e];
    }) : B.error("Invalid value for property_denylist config: " + this.config.property_denylist + " or property_blacklist config: " + this.config.property_blacklist);
    var g = this.config.sanitize_properties;
    g && (B.error("sanitize_properties is deprecated. Use before_send instead"), n = g(n, e));
    var f = this._hasPersonProcessing();
    return n.$process_person_profile = f, f && this._requirePersonProcessing("_calculate_event_properties"), n;
  }
  _calculate_set_once_properties(e) {
    var t;
    if (!this.persistence || !this._hasPersonProcessing()) return e;
    if (this._personProcessingSetOncePropertiesSent) return e;
    var i = this.persistence.get_initial_props(),
      r = null === (t = this.sessionPropsManager) || void 0 === t ? void 0 : t.getSetOnceProps(),
      s = X({}, i, r || {}, e || {}),
      n = this.config.sanitize_properties;
    return n && (B.error("sanitize_properties is deprecated. Use before_send instead"), s = n(s, "$set_once")), this._personProcessingSetOncePropertiesSent = !0, C(s) ? void 0 : s;
  }
  register(e, t) {
    var i;
    null === (i = this.persistence) || void 0 === i || i.register(e, t);
  }
  register_once(e, t, i) {
    var r;
    null === (r = this.persistence) || void 0 === r || r.register_once(e, t, i);
  }
  register_for_session(e) {
    var t;
    null === (t = this.sessionPersistence) || void 0 === t || t.register(e);
  }
  unregister(e) {
    var t;
    null === (t = this.persistence) || void 0 === t || t.unregister(e);
  }
  unregister_for_session(e) {
    var t;
    null === (t = this.sessionPersistence) || void 0 === t || t.unregister(e);
  }
  _register_single(e, t) {
    this.register({
      [e]: t
    });
  }
  getFeatureFlag(e, t) {
    return this.featureFlags.getFeatureFlag(e, t);
  }
  getFeatureFlagPayload(e) {
    var t = this.featureFlags.getFeatureFlagPayload(e);
    try {
      return JSON.parse(t);
    } catch (e) {
      return t;
    }
  }
  isFeatureEnabled(e, t) {
    return this.featureFlags.isFeatureEnabled(e, t);
  }
  reloadFeatureFlags() {
    this.featureFlags.reloadFeatureFlags();
  }
  updateEarlyAccessFeatureEnrollment(e, t) {
    this.featureFlags.updateEarlyAccessFeatureEnrollment(e, t);
  }
  getEarlyAccessFeatures(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1],
      i = arguments.length > 2 ? arguments[2] : void 0;
    return this.featureFlags.getEarlyAccessFeatures(e, t, i);
  }
  on(e, t) {
    return this._internalEventEmitter.on(e, t);
  }
  onFeatureFlags(e) {
    return this.featureFlags.onFeatureFlags(e);
  }
  onSessionId(e) {
    var t, i;
    return null !== (t = null === (i = this.sessionManager) || void 0 === i ? void 0 : i.onSessionId(e)) && void 0 !== t ? t : () => {};
  }
  getSurveys(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    this.surveys.getSurveys(e, t);
  }
  getActiveMatchingSurveys(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    this.surveys.getActiveMatchingSurveys(e, t);
  }
  renderSurvey(e, t) {
    this.surveys.renderSurvey(e, t);
  }
  canRenderSurvey(e) {
    this.surveys.canRenderSurvey(e);
  }
  identify(e, t, i) {
    if (!this.__loaded || !this.persistence) return B.uninitializedWarning("posthog.identify");
    if (A(e) && (e = e.toString(), B.warn("The first argument to posthog.identify was a number, but it should be a string. It has been converted to a string.")), e) {
      if (["distinct_id", "distinctid"].includes(e.toLowerCase())) B.critical('The string "'.concat(e, '" was set in posthog.identify which indicates an error. This ID should be unique to the user and not a hardcoded string.'));else if (this._requirePersonProcessing("posthog.identify")) {
        var r = this.get_distinct_id();
        if (this.register({
          $user_id: e
        }), !this.get_property("$device_id")) {
          var s = r;
          this.register_once({
            $had_persisted_distinct_id: !0,
            $device_id: s
          }, "");
        }
        e !== r && e !== this.get_property(ue) && (this.unregister(ue), this.register({
          distinct_id: e
        }));
        var n = "anonymous" === (this.persistence.get_property(De) || "anonymous");
        e !== r && n ? (this.persistence.set_property(De, "identified"), this.setPersonPropertiesForFlags(W(W({}, i || {}), t || {}), !1), this.capture("$identify", {
          distinct_id: e,
          $anon_distinct_id: r
        }, {
          $set: t || {},
          $set_once: i || {}
        }), this.featureFlags.setAnonymousDistinctId(r), this._cachedIdentify = yo(e, t, i)) : (t || i) && (this._cachedIdentify !== yo(e, t, i) ? (this.setPersonProperties(t, i), this._cachedIdentify = yo(e, t, i)) : B.info("A duplicate posthog.identify call was made with the same properties. It has been ignored.")), e !== r && (this.reloadFeatureFlags(), this.unregister(Le));
      }
    } else B.error("Unique user id has not been set in posthog.identify");
  }
  setPersonProperties(e, t) {
    (e || t) && this._requirePersonProcessing("posthog.setPersonProperties") && (this.setPersonPropertiesForFlags(W(W({}, t || {}), e || {})), this.capture("$set", {
      $set: e || {},
      $set_once: t || {}
    }));
  }
  group(e, t, i) {
    if (e && t) {
      if (this._requirePersonProcessing("posthog.group")) {
        var r = this.getGroups();
        r[e] !== t && this.resetGroupPropertiesForFlags(e), this.register({
          $groups: W(W({}, r), {}, {
            [e]: t
          })
        }), i && (this.capture("$groupidentify", {
          $group_type: e,
          $group_key: t,
          $group_set: i
        }), this.setGroupPropertiesForFlags({
          [e]: i
        })), r[e] === t || i || this.reloadFeatureFlags();
      }
    } else B.error("posthog.group requires a group type and group key");
  }
  resetGroups() {
    this.register({
      $groups: {}
    }), this.resetGroupPropertiesForFlags(), this.reloadFeatureFlags();
  }
  setPersonPropertiesForFlags(e) {
    var t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1];
    this.featureFlags.setPersonPropertiesForFlags(e, t);
  }
  resetPersonPropertiesForFlags() {
    this.featureFlags.resetPersonPropertiesForFlags();
  }
  setGroupPropertiesForFlags(e) {
    var t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1];
    this._requirePersonProcessing("posthog.setGroupPropertiesForFlags") && this.featureFlags.setGroupPropertiesForFlags(e, t);
  }
  resetGroupPropertiesForFlags(e) {
    this.featureFlags.resetGroupPropertiesForFlags(e);
  }
  reset(e) {
    var t, i, r, s;
    if (B.info("reset"), !this.__loaded) return B.uninitializedWarning("posthog.reset");
    var n = this.get_property("$device_id");
    if (this.consent.reset(), null === (t = this.persistence) || void 0 === t || t.clear(), null === (i = this.sessionPersistence) || void 0 === i || i.clear(), this.surveys.reset(), null === (r = this.persistence) || void 0 === r || r.set_property(De, "anonymous"), null === (s = this.sessionManager) || void 0 === s || s.resetSessionId(), this._cachedIdentify = null, this.config.__preview_experimental_cookieless_mode) this.register_once({
      distinct_id: We,
      $device_id: null
    }, "");else {
      var o = this.config.get_device_id(Bt());
      this.register_once({
        distinct_id: o,
        $device_id: e ? o : n
      }, "");
    }
    this.register({
      $last_posthog_reset: new Date().toISOString()
    }, 1);
  }
  get_distinct_id() {
    return this.get_property("distinct_id");
  }
  getGroups() {
    return this.get_property("$groups") || {};
  }
  get_session_id() {
    var e, t;
    return null !== (e = null === (t = this.sessionManager) || void 0 === t ? void 0 : t.checkAndGetSessionAndWindowId(!0).sessionId) && void 0 !== e ? e : "";
  }
  get_session_replay_url(e) {
    if (!this.sessionManager) return "";
    var {
        sessionId: t,
        sessionStartTimestamp: i
      } = this.sessionManager.checkAndGetSessionAndWindowId(!0),
      r = this.requestRouter.endpointFor("ui", "/project/".concat(this.config.token, "/replay/").concat(t));
    if (null != e && e.withTimestamp && i) {
      var s,
        n = null !== (s = e.timestampLookBack) && void 0 !== s ? s : 10;
      if (!i) return r;
      var o = Math.max(Math.floor((new Date().getTime() - i) / 1e3) - n, 0);
      r += "?t=".concat(o);
    }
    return r;
  }
  alias(e, t) {
    return e === this.get_property(le) ? (B.critical("Attempting to create alias for existing People user - aborting."), -2) : this._requirePersonProcessing("posthog.alias") ? (F(t) && (t = this.get_distinct_id()), e !== t ? (this._register_single(ue, e), this.capture("$create_alias", {
      alias: e,
      distinct_id: t
    })) : (B.warn("alias matches current distinct_id - skipping api call."), this.identify(e), -1)) : void 0;
  }
  set_config(e) {
    var t,
      i,
      r,
      s,
      n = W({}, this.config);
    P(e) && (X(this.config, Fo(e)), null === (t = this.persistence) || void 0 === t || t.update_config(this.config, n), this.sessionPersistence = "sessionStorage" === this.config.persistence || "memory" === this.config.persistence ? this.persistence : new Ks(W(W({}, this.config), {}, {
      persistence: "sessionStorage"
    })), Yt.is_supported() && "true" === Yt.get("ph_debug") && (this.config.debug = !0), this.config.debug && (p.DEBUG = !0, B.info("set_config", {
      config: e,
      oldConfig: n,
      newConfig: W({}, this.config)
    })), null === (i = this.sessionRecording) || void 0 === i || i.startIfEnabledOrStop(), null === (r = this.autocapture) || void 0 === r || r.startIfEnabled(), null === (s = this.heatmaps) || void 0 === s || s.startIfEnabled(), this.surveys.loadIfEnabled(), this._sync_opt_out_with_persistence());
  }
  startSessionRecording(e) {
    var t = !0 === e,
      i = {
        sampling: t || !(null == e || !e.sampling),
        linked_flag: t || !(null == e || !e.linked_flag),
        url_trigger: t || !(null == e || !e.url_trigger),
        event_trigger: t || !(null == e || !e.event_trigger)
      };
    if (Object.values(i).some(Boolean)) {
      var r, s, n, o, a;
      if (null === (r = this.sessionManager) || void 0 === r || r.checkAndGetSessionAndWindowId(), i.sampling) null === (s = this.sessionRecording) || void 0 === s || s.overrideSampling();
      if (i.linked_flag) null === (n = this.sessionRecording) || void 0 === n || n.overrideLinkedFlag();
      if (i.url_trigger) null === (o = this.sessionRecording) || void 0 === o || o.overrideTrigger("url");
      if (i.event_trigger) null === (a = this.sessionRecording) || void 0 === a || a.overrideTrigger("event");
    }
    this.set_config({
      disable_session_recording: !1
    });
  }
  stopSessionRecording() {
    this.set_config({
      disable_session_recording: !0
    });
  }
  sessionRecordingStarted() {
    var e;
    return !(null === (e = this.sessionRecording) || void 0 === e || !e.started);
  }
  captureException(e, t) {
    var i,
      r = new Error("PostHog syntheticException"),
      s = I(null === (i = _.__PosthogExtensions__) || void 0 === i ? void 0 : i.parseErrorAsProperties) ? W(W({}, _.__PosthogExtensions__.parseErrorAsProperties(D(e) ? {
        error: e,
        event: e.message
      } : {
        event: e
      }, {
        syntheticException: r
      })), t) : W({
        $exception_level: "error",
        $exception_list: [{
          type: D(e) ? e.name : "Error",
          value: D(e) ? e.message : P(e) && "message" in e ? String(e.message) : String(e),
          mechanism: {
            handled: !0,
            synthetic: !1
          }
        }]
      }, t);
    this.exceptions.sendExceptionEvent(s);
  }
  loadToolbar(e) {
    return this.toolbar.loadToolbar(e);
  }
  get_property(e) {
    var t;
    return null === (t = this.persistence) || void 0 === t ? void 0 : t.props[e];
  }
  getSessionProperty(e) {
    var t;
    return null === (t = this.sessionPersistence) || void 0 === t ? void 0 : t.props[e];
  }
  toString() {
    var e,
      t = null !== (e = this.config.name) && void 0 !== e ? e : Io;
    return t !== Io && (t = Io + "." + t), t;
  }
  _isIdentified() {
    var e, t;
    return "identified" === (null === (e = this.persistence) || void 0 === e ? void 0 : e.get_property(De)) || "identified" === (null === (t = this.sessionPersistence) || void 0 === t ? void 0 : t.get_property(De));
  }
  _hasPersonProcessing() {
    var e, t, i, r;
    return !("never" === this.config.person_profiles || "identified_only" === this.config.person_profiles && !this._isIdentified() && C(this.getGroups()) && (null === (e = this.persistence) || void 0 === e || null === (t = e.props) || void 0 === t || !t[ue]) && (null === (i = this.persistence) || void 0 === i || null === (r = i.props) || void 0 === r || !r[ze]));
  }
  _shouldCapturePageleave() {
    return !0 === this.config.capture_pageleave || "if_capture_pageview" === this.config.capture_pageleave && this.config.capture_pageview;
  }
  createPersonProfile() {
    this._hasPersonProcessing() || this._requirePersonProcessing("posthog.createPersonProfile") && this.setPersonProperties({}, {});
  }
  _requirePersonProcessing(e) {
    return "never" === this.config.person_profiles ? (B.error(e + ' was called, but process_person is set to "never". This call will be ignored.'), !1) : (this._register_single(ze, !0), !0);
  }
  _sync_opt_out_with_persistence() {
    var e,
      t,
      i,
      r,
      s = this.consent.isOptedOut(),
      n = this.config.opt_out_persistence_by_default,
      o = this.config.disable_persistence || s && !!n;
    (null === (e = this.persistence) || void 0 === e ? void 0 : e.disabled) !== o && (null === (i = this.persistence) || void 0 === i || i.set_disabled(o));
    (null === (t = this.sessionPersistence) || void 0 === t ? void 0 : t.disabled) !== o && (null === (r = this.sessionPersistence) || void 0 === r || r.set_disabled(o));
  }
  opt_in_capturing(e) {
    var t;
    (this.consent.optInOut(!0), this._sync_opt_out_with_persistence(), F(null == e ? void 0 : e.captureEventName) || null != e && e.captureEventName) && this.capture(null !== (t = null == e ? void 0 : e.captureEventName) && void 0 !== t ? t : "$opt_in", null == e ? void 0 : e.captureProperties, {
      send_instantly: !0
    });
    this.config.capture_pageview && this._captureInitialPageview();
  }
  opt_out_capturing() {
    this.consent.optInOut(!1), this._sync_opt_out_with_persistence();
  }
  has_opted_in_capturing() {
    return this.consent.isOptedIn();
  }
  has_opted_out_capturing() {
    return this.consent.isOptedOut();
  }
  clear_opt_in_out_capturing() {
    this.consent.reset(), this._sync_opt_out_with_persistence();
  }
  _is_bot() {
    return o ? mo(o, this.config.custom_blocked_useragents) : void 0;
  }
  _captureInitialPageview() {
    a && !this._initialPageviewCaptured && (this._initialPageviewCaptured = !0, this.capture("$pageview", {
      title: a.title
    }, {
      send_instantly: !0
    }));
  }
  debug(e) {
    !1 === e ? (null == t || t.console.log("You've disabled debug mode."), localStorage && localStorage.removeItem("ph_debug"), this.set_config({
      debug: !1
    })) : (null == t || t.console.log("You're now in debug mode. All calls to PostHog will be logged in your console.\nYou can disable this with `posthog.debug(false)`."), localStorage && localStorage.setItem("ph_debug", "true"), this.set_config({
      debug: !0
    }));
  }
  _runBeforeSend(e) {
    if (O(this.config.before_send)) return e;
    var t = x(this.config.before_send) ? this.config.before_send : [this.config.before_send],
      i = e;
    for (var r of t) {
      if (i = r(i), O(i)) {
        var s = "Event '".concat(e.event, "' was rejected in beforeSend function");
        return q(e.event) ? B.warn("".concat(s, ". This can cause unexpected behavior.")) : B.info(s), null;
      }
      i.properties && !C(i.properties) || B.warn("Event '".concat(e.event, "' has no properties after beforeSend function, this is likely an error."));
    }
    return i;
  }
  getPageViewId() {
    var e;
    return null === (e = this.pageViewManager._currentPageview) || void 0 === e ? void 0 : e.pageViewId;
  }
  captureTraceFeedback(e, t) {
    this.capture("$ai_feedback", {
      $ai_trace_id: String(e),
      $ai_feedback_text: t
    });
  }
  captureTraceMetric(e, t, i) {
    this.capture("$ai_metric", {
      $ai_trace_id: String(e),
      $ai_metric_name: t,
      $ai_metric_value: String(i)
    });
  }
}
!function (e, t) {
  for (var i = 0; i < t.length; i++) e.prototype[t[i]] = te(e.prototype[t[i]]);
}(Ro, ["identify"]);
var $o,
  Oo = ($o = Eo[Io] = new Ro(), function () {
    function e() {
      e.done || (e.done = !0, Po = !1, K(Eo, function (e) {
        e._dom_loaded();
      }));
    }
    null != a && a.addEventListener ? "complete" === a.readyState ? e() : ae(a, "DOMContentLoaded", e, {
      capture: !1
    }) : t && B.error("Browser doesn't support `document.addEventListener` so PostHog couldn't be initialized");
  }(), $o);
export { v as COPY_AUTOCAPTURE_EVENT, e as Compression, Ro as PostHog, ln as SurveyQuestionBranchingType, an as SurveyQuestionType, un as SurveySchedule, on as SurveyType, Oo as default, g as knownUnsafeEditableEvent, Oo as posthog, f as severityLevels };
